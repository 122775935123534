import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import LostPass from '../../components/LostPass';

function LostInPasswordPage() {

  return (
    <>
      <WrapperPage>
        <div className='blueTransparentWrapper'>
          <WrapperContainer>
            <LostPass />
          </WrapperContainer>
        </div>
      </WrapperPage>
    </>
  );
}

export default LostInPasswordPage;
