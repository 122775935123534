import React from 'react';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import YoutubeEmbed from '../YoutubeEmbed';
import '../YoutubeEmbed/styles.css';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { youtubeParser } from '../../common/commonFunctions';

function ShowPictureAndVideo({ data }) {

  let picture, video = '';
  let NFTLogo = require('../../nftlogo.png');

  picture = (data?.picture && data?.picture[0]?.cid) ? data.picture[0].cid : '';

  video = data.video;
  if (picture) {
    picture = (
      <Card>
        <CardActionArea href={`https://ipfs.io/ipfs/${picture}`}>
          <CardMedia component="img" height="auto" image={`https://ipfs.io/ipfs/${picture}`} alt="Picture" />
        </CardActionArea>
      </Card>
    );
  }
  if (video) {
    let embedId = youtubeParser(video);
    video = (
      <Card>
        <CardActionArea>
          <CardMedia>
            <YoutubeEmbed embedId={`${embedId}`} />
          </CardMedia>
        </CardActionArea>
      </Card>
    );
  }
  return (
    <Container maxWidth="lg" style={{ mb: 1, mt: 1, paddingLeft: 0, paddingRight: 0 }}>
      <Box className="darkBlueBox" noValidate sx={{ my: 2, py: 1, width: '100%', justifyContent: 'center', display: 'flex', color: '#000', fontWeight: 600 }}>
        NFT Files
        <img src={NFTLogo} alt="NFT Logo" style={{ marginLeft: '10px', marginTop: '-3px', width: '30px', height: '30px' }} />
      </Box>

      <Grid container spacing={4} direction="row" justifyContent="center" alignItems="flex-start">
        {(picture && video) ? <>
          <Grid item xs={12} md={6}>
            {picture}
          </Grid>
          <Grid item xs={12} md={6}>
            {video}
          </Grid> </> : <> <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {picture}
          </Grid>
          <Grid item xs={12} md={12} sx={{ width: '100%' }}>
            {video}
          </Grid></>}
      </Grid>
    </Container>
  );
}

export default ShowPictureAndVideo;
