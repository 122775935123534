import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

function UseCaseGeneral() {
    const { t } = useTranslation();
    return (
        <> <Container component="main" maxWidth="lg" sx={{ mt: 0, minHeight: '70vh', borderRadius: '15px', background: '#094164ab' }}>
            <Typography variant="h4" display="flex" align="left" justifyContent="center" sx={{ mt: 0, pt: 4, mb: 2 }}>
                {t('whatIsBlokaria')}
            </Typography>
            <Typography variant="body1" align="left" sx={{ mt: 3, mb: 2 }}>
                <Box sx={{
                    maxWidth: '100%',
                    my: 3,
                    textAlign: 'center'
                }}>
                    <Link
                        target="_blank"
                        rel="noreferrer noopener"
                        //component={RouterLink} ovo ide na to=""
                        href="/static/presentation/Blokaria_Presentation_9_17_2022.pdf"
                        underline="true"
                        fontWeight="600"
                        color="Orange"
                        sx={{ my: 1 }}
                    >
                        {t('presentation')}
                    </Link>
                </Box>
            </Typography>
            <Typography variant="body1" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('whatIsBlokaria1')}
            </Typography>
            <Typography variant="h5" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('forEntrepreneurs')}
            </Typography>
            <Typography variant="body1" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('forEntrepreneursAnswer')}
            </Typography>
            <Typography variant="h5" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('forCivilAssociations')}
            </Typography>
            <Typography variant="body1" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('forCivilAssociationsAnswer')}
            </Typography>
            <Typography variant="body1" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {' '}
            </Typography>
            <Typography variant="h5" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('whatIsBlockChainTitle')}
            </Typography>
            <Typography variant="body1" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('whatIsBlockChain')}
            </Typography>
            <Typography variant="h5" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('whyBlokariaStoreQrCodesOnBC')}
            </Typography>
            <Typography variant="body1" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('whyBlokariaStoreQrCodesOnBCAnswer')}
            </Typography>
            <Typography variant="h5" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('whyBlokariaStoreMyInformationOnBC')}
            </Typography>
            <Typography variant="body1" display="flex" align="left" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                {t('whyBlokariaStoreMyInformationOnBCAnswer')}
            </Typography>
            <Box sx={{
                maxWidth: '100%',
                my: 3,
                textAlign: 'center'
            }}>
                <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href="/static/localpic/generalFlow.png"
                    underline="none"
                    color="info.light"
                    sx={{ my: 1 }}
                >
                    <img src="/static/localpic/generalFlow.png" height="100%" />
                </Link>
            </Box>
        </Container>
        </>
    );
}

export default UseCaseGeneral;