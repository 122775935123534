import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import GenerateNftComponent from '../../components/GenerateNftComponent';
import Container from '@mui/material/Container';
import GoBackButton from '../../view/GoBackButton';
function GenerateNftPage() {
    return (
        <WrapperPage>
            <WrapperContainer>
                <GoBackButton />
                <Container name="GenerateNft" maxWidth="md" sx={{ pt: 0, pb: 4 }}>
                    <GenerateNftComponent />
                </Container>
            </WrapperContainer>
        </WrapperPage>
    );
}

export default GenerateNftPage;