import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import LinkHref from '../../components/LinkHref';
import '../../css/general.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'animate.css';

const tiers = [
  {
    //title: 'Start generating unique identifier for your product',
    //subheader: 'steps',
    uniqueKey: 'BlablaMainPageQrCode',
    //price: 'Steps',
    description: [
      'Generiši QR kod',
      'Sačuvaj informacije o proizvodu na QR kod',
      'Pošalji QR kod klijentu',
      'Sačuvaj informacije o klijentu na QR kod',
      'Generiši ugovor',
      'Sačuvaj podatke na BlockChain mreži'
    ],
    buttonText: 'Generišite QR kod',
    buttonLink: 'doc/use-case-general',
    buttonVariant: 'contained'
  }

];

const GeneratinQrCodeMainPage = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    // <div className="animate__animated animate__backInLeft">
      <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowWrap: 'break-word'
        }}
      >
        <LinkHref to="/doc/use-case-general" sx={{ mt: 1 }}>
          <img src="/static/generateqrcode.png" height="170px" />
        </LinkHref>
      </Box>


      {/* <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Dokažite autentičnost svojih proizvoda i usluga prodajom preko Blokarije
        </Typography>
        <Typography variant="h5" align="center" color="secondary" component="p"></Typography>
      </Container> */}

      <Container id='generatingQrCodeMainPage' maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h5" align="center" color="black" sx={{ my: 3, fontWeight:600 }}>
          {t('tier_1_homepage.sent1')}
        </Typography>
        <Typography variant="h5" align="center" color="black" sx={{ my: 3 }}>
          {t('tier_1_homepage.sent2')}
        </Typography>
        <Typography variant="body1" align="center" color="black" sx={{ mb: 2 }}>
          {t('tier_1_homepage.sent3')}
        </Typography>
        {/* <Typography variant="body1" align="center" color="info.dark">
          Store specific information about your product or service using Cardano BlockChain. Please note that metadata provided in a transaction will be stored
          on the blockchain forever. Make sure not to include any sensitive data, in particular personally identifiable information (PII).
        </Typography> */}
      </Container>


      <Container maxWidth="md">
        <Grid container spacing={3} alignItems="flex-end" justifyContent="center">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.uniqueKey} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={8}>
              {/*  <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center'
                  }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main
                  }}
                /> */}
              {/* <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      m: 2
                    }}
                  >
                    <Typography component="h2" variant="h3" color="primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      /mo
                    </Typography>
                  </Box>
                </CardContent> 
                <CardActions>
                  <Button
                    fullWidth
                    color='secondary'
                    onClick={() => {
                      navigate(tier.buttonLink);
                    }}
                    variant={tier.buttonVariant}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>*/}
              
              <Button
                fullWidth
                sx={{backgroundColor: (theme) => theme.orangeButton.button}}
                onClick={() => {
                  navigate(tier.buttonLink);
                }}
                variant={tier.buttonVariant}
              >
                {t('more-info')}
                {/* {tier.buttonText} */}
              </Button>

            </Grid>
          ))}
        </Grid>
      </Container>

    </React.Fragment>
    // </div>
    
  );
};

export default GeneratinQrCodeMainPage;
