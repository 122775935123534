import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';




function SequenceDiagramsView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <Box sx={{ backgroundColor: '#323233', mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    BPMN DIAGRAM
                </Typography>
                {/* <Typography variant="body1" align="left" sx={{ my: 2 }}>
                A business process model and notation diagram, or BPMN diagram for short, 
                is used to build easy-to-read business process model flowcharts, 
                which can be shared across organizations and industries. 
                BPMN diagram symbols are categorized into four main groups: flow objects, 
                connecting objects, swimlanes, and artifacts. 
                </Typography> */}
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href="/static/cases/bpmn_1.jpg"
                    underline="none"
                    color="info.light"
                    sx={{ my: 1 }}
                >
                    <img src="/static/cases/bpmn_1.jpg" width="100%" />
                </Link>
                </Typography>
            </Box>
           
            <Box sx={{ backgroundColor: '#323233', mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    Dijagram sekvence
                </Typography>
                {/* <Typography variant="body1" align="left" sx={{ my: 2 }}>
                Sequence Diagrams show elements as they interact over time and 
                they are organized according to object (horizontally) and time (vertically).
                A sequence diagram simply depicts interaction between objects in a sequential order.
                </Typography> */}
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href="/static/cases/Sequence_submit_transaction.jpg"
                    underline="none"
                    color="info.light"
                    sx={{ my: 1 }}
                >
                    <img src="/static/cases/Sequence_submit_transaction.jpg" width="100%" />
                </Link>
                </Typography>
            </Box>
           
        </Container>
    );
}

export default SequenceDiagramsView;