import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {Outlet} from 'react-router-dom';

function UserPage() {
  return (
    <WrapperPage>
      <WrapperContainer>
      <Container name="WrapperContainer" maxWidth="lg" sx={{ marginTop: 4, paddingTop: 0, background:'#094164ab'}}>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
            </Grid>
          </Grid>
        <Outlet />
        </Container>
      </WrapperContainer>
    </WrapperPage>
  );
}

export default UserPage;
