import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SnackBarView = ({ handleClose, openSnackStatus, errorMessage, type }) => {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    (errorMessage) ? <SnackBarWrapper openSnackStatus={openSnackStatus}
      handleClose={handleClose}
      Alert={Alert}
      type={type}
      errorMessage={errorMessage}
    /> : ''
  );
};

const SnackBarWrapper = ({ openSnackStatus, handleClose, type, errorMessage, Alert }) => {
  return (<Snackbar
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'bottom'
    }}
    open={openSnackStatus}
    autoHideDuration={6000}
    onClose={handleClose}
  >
    <Alert onClose={handleClose} severity={type} sx={type === 'error' ? { width: '100%' } : { width: '100%', margin: 7 }}>
      {errorMessage}
    </Alert>
  </Snackbar>
  );
};

export default SnackBarView;
