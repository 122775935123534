import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import UserLogOut from '../../view/UserLogOut';
 import { useNavigate} from 'react-router-dom';
function LogOutPage() {

  let navigate = useNavigate();

  return (
    <WrapperPage>
      <WrapperContainer>
         <UserLogOut navigate={navigate}/>
      </WrapperContainer>
    </WrapperPage>
  );
}

export default LogOutPage;
