import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Box, Link } from '@mui/material';


function TshirtPresentation() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <CssBaseline />
            <Typography variant="h5" align="left" sx={{ mb: 2 }}>
                T-shirt for Collection #1
            </Typography>

            <Box align="left" sx={{ mb: 2 }}>
                Every NFT on &nbsp;
                <Link variant="span" color='purple.main' target="_blank" rel="noreferrer noopener"
                    href="https://testnets.opensea.io/collection/mikitoken-3">
                    this
                </Link>
                &nbsp; collection gets free tshirt
                <ul>
                    <li>In the center of the NFT is a picture of a human from year 7425.</li>
                    {/* <li>In the upper right corner of the picture is a placeholder for 3d rendered golden awards (coin, flaming torch ...)</li>
                    <li>The coat should be animated in neon color</li>*/}
                    <li>Background is to be black or deep space, or distant planet</li>
                    <li>Design a base character model for an NFT project. Layers will be added on and removed when we run the base model through our program that randomizes layers</li>
                </ul>

            </Box>


            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                STORY & CHARACTER BACKGROUND CONCEPT:
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                The concept is that she is a non modified human on distant planet
                lightyears away from earth. She is being created to aid humanity in a
                war against aliens that normal humans are less effective against.
                Beside the fact that she is non-modified, she has a Spirit given power
                to control nuclei in her body and around her.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                She controls nuclei by raising thoughts to higher frequency levels, and in that moment,
                she creates new materia or new life.  Yet, she does not know how she can do that.
            </Typography>

            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                When she is in trouble, then the nuclei start to be seen through the skin in different
                colors depending on the type of danger.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                Her job is to fight against enemies in this galaxy across time differences and multi-dimensions.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                She is calm, serious, strong, powerful, lonely, and full of love.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                Her name is Soka.
            </Typography>


        </Container>
    );
}




export default TshirtPresentation;
