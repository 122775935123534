import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { languages } from '../../languages';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    projectName: yup.string('Unesi ime projekta').max(60, 'Ime može imate maksimalno 60 karaktera').required('Ime projekta je obavezno!'),
    projectMetaData: yup.object().optional().nullable()
    // projectMetaData: yup.lazy(value => {
    //     switch (typeof value) {
    //         case 'object':
    //             return yup.object().required();
    //         case 'string':
    //             return yup.string().error().optional().nullable();
    //         // default:
    //         //     return yup.string().error().optional().nullable();
    //     }
    // })
});

export default function DialogFormView({ handleCloseDialog, openDialog, handleSubmitDialog, getProjectArray }) {

    const formikDialog = useFormik({
        initialValues: {
            projectId: '',
            projectName: '',
            projectDesc: '',
            projectMetaData: ''
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {

            console.log('values.projectMetaData', values.projectMetaData);
            try {
                let dataForm = {
                    projectId: values.projectId,
                    projectName: values.projectName,
                    projectDesc: values.projectDesc,
                    projectMetaData: (values.projectMetaData) ? JSON.parse(values.projectMetaData) : null
                };
                handleSubmitDialog(dataForm);
                resetForm();
            } catch (error) {
                return console.log(error);
            }
        }
    });


    const { lang } = useSelector((state) => state.user);

    useEffect(() => {
        formikDialog.setFieldValue('projectId', getProjectArray[0]);
        formikDialog.setFieldValue('projectName', getProjectArray[1]);
        formikDialog.setFieldValue('projectDesc', getProjectArray[2]);
        formikDialog.setFieldValue('projectMetaData', (!getProjectArray[3]) ? '' : JSON.stringify(getProjectArray[3], null, 2));
    }, [getProjectArray]);

    return (
        <div>
            <Dialog
                open={openDialog}
                fullWidth={true}
                maxWidth={'md'}
                onClose={() => handleCloseDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title" sx={{
                    backgroundColor: (theme) => theme.palette.background.default
                }}>
                    Update project description
                </DialogTitle>
                <DialogContent sx={{
                    backgroundColor: (theme) => theme.palette.background.default
                }}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="projectName"
                        name="projectName"
                        label="Project Title"
                        value={formikDialog.values.projectName}
                        onChange={(e) => {
                            formikDialog.handleChange(e);
                        }}
                        error={formikDialog.touched.projectName && Boolean(formikDialog.errors.projectName)}
                        helperText={formikDialog.touched.projectName && formikDialog.errors.projectName}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        id="projectDesc"
                        name="projectDesc"
                        multiline
                        rows={10}
                        label="Project Description"
                        value={formikDialog.values.projectDesc}
                        onChange={(e) => {
                            formikDialog.handleChange(e);
                        }}
                        error={formikDialog.touched.projectDesc && Boolean(formikDialog.errors.projectDesc)}
                        helperText={formikDialog.touched.projectDesc && formikDialog.errors.projectDesc}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        id="projectMetaData"
                        name="projectMetaData"
                        placeholder='Example: { "Color": "Blue", "Shape": "Circle"}'
                        multiline
                        rows={4}
                        label="Project MetaData : Json Object"
                        value={formikDialog.values.projectMetaData}
                        onChange={(e) => {
                            formikDialog.handleChange(e);
                        }}
                        error={formikDialog.touched.projectMetaData && Boolean(formikDialog.errors.projectMetaData)}
                        helperText={formikDialog.touched.projectMetaData && formikDialog.errors.projectMetaData}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => handleCloseDialog(false)}>{languages[lang].no}</Button>
                    <Button onClick={() => formikDialog.handleSubmit()}>
                        {languages[lang].yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}