import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import GeneratedQrcodeComponent from '../../components/GeneratedQrcodeComponent';

function GeneratedQrcode() {
  return (
    <>
      <WrapperPage>
        <div className='blueTransparentWrapper'>
          <WrapperContainer>
            <GeneratedQrcodeComponent />
          </WrapperContainer>
        </div>
      </WrapperPage>
    </>
  );
}

export default GeneratedQrcode;
