import * as ActionTypes from './actions';
// TOKEN
const initialToken = {
  token: ''
};

const immutableStateToken = Object.freeze({
  token: ''
});

const tokenReducer = (state = initialToken, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_SET_TOKEN:
      return {
        ...state,
        ...immutableStateToken
      };

    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload.tokenData.token
      };

    case ActionTypes.UNSET_TOKEN:
      return {
        ...state,
        token: ''
      };

    default:
      return { ...state };
  }
};

export default tokenReducer;
