import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Link as RouterLink } from 'react-router-dom';
import { checkIfNftExist } from '../../common/commonFunctions';
import LinkHref from '../../components/LinkHref';
import { useTranslation } from 'react-i18next';

const { isEmpty, isArray } = require('lodash');

function ListGridQrCodesView({ transactionData, navigate }) {

    if (isEmpty(transactionData) || !isArray(transactionData)) {
        return <div>...</div>;
    }

    const { t } = useTranslation();

    return (
        <Grid container spacing={3} alignItems="flex-start" >
            {transactionData.map((item, i) => {
                let picLoc = item._image.length ? `${process.env.REACT_APP_MOLECULER_SERVICE}/${item._image[0].productPicture}` : '/static/blokariaQR.png';

                return (
                    <Grid item xs={12} sm={3} md={3} key={i} display={'flex'} flexWrap={'wrap'}>
                        <Card
                            sx={{
                                border: (theme) => `1px solid ${theme.palette.devider.color}`,
                                backgroundColor: (theme) => theme.palette.white.white,
                                color: 'black !important'
                            }}
                            className='maxWidthListQRCodesPhone'
                        >
                            <CardHeader
                                title={item.productName}
                                //subheader={item.walletQrId}
                                titleTypographyProps={{ align: 'center' }}
                                subheaderTypographyProps={{
                                    align: 'center'
                                }}
                                sx={{
                                    backgroundColor: (theme) => theme.palette.white.white
                                }}
                            />
                            <CardContent sx={{ p: 1, backgroundColor: 'black' }}>
                                <CardActionArea component={RouterLink} to={`/status/${item.walletQrId}`} >
                                    <CardMedia
                                        component="img"
                                        loading="lazy"
                                        sx={{
                                            //height: 'auto',
                                            //maxHeight: 'auto',
                                            //width: 'auto',
                                            //maxHeight: '150px',
                                            objectFit: 'contain'
                                            // alignItems: 'center',
                                            // display: 'block',
                                            // marginLeft: 'auto',
                                            // marginRight: 'auto'
                                        }}
                                        image={picLoc}
                                        alt={item.productName}
                                    />
                                </CardActionArea>
                            </CardContent>
                            <CardContent sx={{ p: 0 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        flexDirection: 'column',
                                        p: 2,
                                        backgroundColor: (theme) => theme.palette.white.white
                                    }}
                                >
                                    <Typography variant="body1" color="info">
                                        {t('producer')} : {item.userFullname}
                                    </Typography>
                                    {/* <Typography variant="caption" color="secondary">
                                            Issuer : {item.userEmail}
                                        </Typography> */}
                                    <Typography variant="caption" color="info">
                                        {t('message')} : {item.userDesc}
                                    </Typography>
                                    {/* <Typography variant="caption" color="secondary">
                                           QrId:  {item.walletQrId}
                                        </Typography> */}
                                    <Typography variant="caption" color="secondary">
                                        {t('date')} : {moment(item.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                    </Typography>
                                    <Grid container spacing={2} justifyContent="center" flexDirection="row">
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Box>
                                                <Typography variant="caption" color="info">
                                                    {t('status')}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="caption" color="info">
                                                    {item.qrCodeRedeemStatus ? (
                                                        <Tooltip title="Proizvod je kuplljen">
                                                            <CancelIcon sx={{ color: 'red' }} />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Proizvod je dostupan za kupovinu">
                                                            <CheckCircleIcon sx={{ color: 'green' }} />
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Box>
                                                <Typography variant="caption" color="info">
                                                    {t('public')}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="caption" color="info">
                                                    {item.publicQrCode ? (
                                                        <Tooltip title="Proizvod je javno dostupan za kupovinu">
                                                            <PublicIcon color="primary" />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Proizvod je rezervisan samo za određene korisnike">
                                                            <VpnLockIcon color="warning" />
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Box>
                                                <Typography variant="caption" color="info">
                                                    Story
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="caption" color="info">

                                                    {(item.hasstory && checkIfNftExist(item)) ? (
                                                        <>
                                                            <LinkHref color="orange" to={`/story/${item._id}`}>
                                                                <Tooltip title="Story">
                                                                    <TextSnippetIcon color="success"></TextSnippetIcon>
                                                                </Tooltip>
                                                            </LinkHref>
                                                        </>
                                                    ) : (
                                                        <Tooltip title="">
                                                            <TextSnippetIcon color="info"></TextSnippetIcon>
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>

                            <CardActions
                                sx={{
                                    backgroundColor: (theme) => theme.palette.white.white
                                }}
                            >
                                {item.qrCodeRedeemStatus ? (
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: (theme) => theme.orangeButton.button }}
                                        fullWidth
                                        onClick={() => {
                                            navigate(`/status/${item.walletQrId}`);
                                        }}
                                    >
                                        {t('getStatus')}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: (theme) => theme.orangeButton.button }}
                                        fullWidth
                                        onClick={() => {
                                            navigate(`/generate-contract/${item.walletQrId}`);
                                        }}
                                    >
                                        {t('getOwnership')}
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default ListGridQrCodesView;
