import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SelectProjectView({ listUserProjects, menuItems, projectId, handleChangeProject, walletId }) {

    if (listUserProjects && listUserProjects.length > 0 && menuItems.length > 0) {

        if (projectId === undefined || projectId === null) {
            projectId = 'noproject';
        }

        return (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small"></InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={projectId}

                    label="Project"
                    onChange={(e) => handleChangeProject(walletId, e)}
                >
                    <MenuItem value="noproject" key="projectIdValueZero">
                        <em>None</em>
                    </MenuItem>
                    {menuItems}
                </Select>
            </FormControl>
        );
    }
    return '';

}

export default SelectProjectView;