import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getUserData } from '../../redux/user/actions';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BasicModal from '../../view/BasicModal';
import { useTranslation } from 'react-i18next';

function UserProfile() {
        const { t } = useTranslation();
        const [open, setOpen] = React.useState(false);
        const [textTitleToDisplay, setTextTitleToDisplay] = React.useState('');
        const [textBodyToDisplay, setTextBodyToDisplay] = React.useState('');

        const handleTextTitleToDisplay = (value) => {
                setTextTitleToDisplay(value);
        };
        const handleTextBodyToDisplay = (value) => {
                setTextBodyToDisplay(value);
        };
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        const dispatch = useDispatch();
        const stableDispatch = useCallback(dispatch, [dispatch]);

        const { transactionUserData, errorMessage, internalErrorCode, loading, loaded } = useSelector((state) => state.user);
        const { token } = useSelector((state) => state.token);

        const dispatchCheckExistingQrCode = useCallback(
                (userEmail, token) => {
                        (userEmail && token) ? stableDispatch(getUserData(userEmail, token)) : '';
                },
                [stableDispatch]
        );

        let userEmail = transactionUserData?.userEmail;

        useEffect(() => {
                dispatchCheckExistingQrCode(userEmail, token);
        }, [dispatchCheckExistingQrCode]);

        let qrCodeView = '';

        switch (true) {
                case (loading):
                        qrCodeView = <ShowLoader />;
                        break;
                case (errorMessage !== '' && !loaded):
                        qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
                        break;
                case (loaded && Object.keys(transactionUserData).length > 0):
                        qrCodeView = <><UserTableContainer
                                t={t}
                                handleOpen={handleOpen}
                                transactionUserData={transactionUserData}
                                handleTextTitleToDisplay={handleTextTitleToDisplay}
                                handleTextBodyToDisplay={handleTextBodyToDisplay}

                        /><DescriptionProfile t={t} /></>;
                        break;
                default:
                        qrCodeView = <ShowError errorMessage={t('missingTokenPleaseLogin')} internalErrorCode="Clear State" />;
                        break;
        }

        return (
                <React.Fragment>
                        <BasicModal open={open} handleClose={handleClose}
                                textTitleToDisplay={textTitleToDisplay}
                                textBodyToDisplay={textBodyToDisplay} />
                        {qrCodeView}
                </React.Fragment>
        );
}

const UserTableContainer = ({ transactionUserData, handleOpen, handleTextTitleToDisplay, handleTextBodyToDisplay, t }) => {

        return (
                <>
                        <Typography variant="h4" align="center" sx={{ my: 4 }}>
                                {t('yourProfile')}
                        </Typography>
                        <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                                <TableRow>
                                                        <TableCell> {t('name')} </TableCell>
                                                        <TableCell align="left">{t('value')}</TableCell>
                                                        <TableCell align="left">{t('desc')}</TableCell>
                                                        <TableCell align="left">{t('action')}</TableCell>
                                                </TableRow>
                                        </TableHead>
                                        <TableBody>

                                                <TableRow
                                                        key="1"
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell component="th" scope="row">
                                                                {t('username')}
                                                        </TableCell>
                                                        <TableCell align="left">{transactionUserData.userFullName}</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                </TableRow>
                                                <TableRow
                                                        key="2"
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell component="th" scope="row">
                                                                Email
                                                        </TableCell>
                                                        <TableCell align="left">{transactionUserData.userEmail}</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                </TableRow>
                                                <TableRow
                                                        key="3"
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell component="th" scope="row">
                                                                {t('numberOfNftCoupons')}
                                                        </TableCell>
                                                        <TableCell align="left">{transactionUserData.numberOfCoupons}</TableCell>
                                                        <TableCell align="left">{t('couponsAreUsedDuringNftGeneration')}</TableCell>
                                                        <TableCell align="left">
                                                                <Button
                                                                        sx={{ backgroundColor: (theme) => theme.orangeButton.button }}
                                                                        variant='contained'
                                                                        onClick={() => {
                                                                                handleOpen();
                                                                                handleTextTitleToDisplay(t('addCoupons'));
                                                                                handleTextBodyToDisplay(t('addingCoupons'));
                                                                        }}
                                                                >
                                                                        {t('addCoupons')}
                                                                </Button>

                                                        </TableCell>
                                                </TableRow>
                                                <TableRow
                                                        key="4"
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell component="th" scope="row">
                                                                {t('qrCodesForGeneratingQrCodes')}
                                                        </TableCell>
                                                        <TableCell align="left">{transactionUserData.numberOfTransaction}</TableCell>
                                                        <TableCell align="left">
                                                                {t('howManyQrCodesYouCanGenerate')}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                                <Button
                                                                        variant='contained'
                                                                        sx={{ backgroundColor: (theme) => theme.orangeButton.button }}
                                                                        onClick={() => {
                                                                                handleOpen();
                                                                                handleTextTitleToDisplay(t('addQrCodes'));
                                                                                handleTextBodyToDisplay(t('addingQrCodes'));
                                                                        }}
                                                                >
                                                                        {t('addQrCodes')}
                                                                </Button>

                                                        </TableCell>
                                                </TableRow>
                                                <TableRow
                                                        key="5"
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                </TableRow>
                                        </TableBody>
                                </Table>
                        </TableContainer>
                </>
        );
};

const DescriptionProfile = ({ t }) => {

        return (
                <Box sx={{ m: 5 }} >
                        <Typography sx={{ mt: 2 }}>
                                {t('userDesc.par1')}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                                {t('userDesc.par2')}
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                                {t('userDesc.par3')}
                        </Typography>
                </Box>
        );
};
export default UserProfile;

