import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';



function ThreeminPitchView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <CssBaseline />
            <Typography variant="h5" align="left" sx={{ mb: 2 }}>
                Work Brakedown Structure - General
            </Typography>
            {/* <Box align="left" sx={{ mb: 2 }}>
                Decomposition is a technique used for dividing and subdividing
                the project scope and project deliverables into smaller, more manageable parts.
                The work package is the work defined at the lowest
                level of the WBS for which cost and duration can be estimated and managed.

                Decomposition of the Blokaria total project work into work packages generally involves the following group of functionalities.
            </Box> */}
            <Box align="left" sx={{ mb: 2 }}>
                <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href="/static/cases/wbs.jpg"
                    underline="none"
                    color="info.light"
                    sx={{ my: 1 }}
                >
                    <img src="/static/cases/wbs.jpg" width="100%" />
                </Link>
            </Box>
            <Typography variant="h5" align="left" sx={{ mb: 2, mt: 5 }}>
                Work Brakedown Structure - In Details - BackEnd Services
            </Typography>
            <Box align="left" sx={{ mb: 2 }}>
            U ovoj šemi ćemo dalje dekomponovati MicroServices grupu na atomske funkcionalne komponente
            </Box>
            <Box align="left" sx={{ mb: 2 }}>
                <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href="/static/cases/wbs_actions.jpg"
                    underline="none"
                    color="info.light"
                    sx={{ my: 1 }}
                >
                    <img src="/static/cases/wbs_actions.jpg" width="100%" />
                </Link>
            </Box>



        </Container>
    );
}



export default ThreeminPitchView;
