import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import '../../css/general.css';
//import PurpleButtonComponent from '../../components/PurpleButtonComponent';
//import { useNavigate } from 'react-router-dom';
// import LinkHref from '../../components/LinkHref';
// import Link from '@mui/material/Link';

// import OneGridElementForPrez from '../../view/OneGridElementForPrez';

// const gridData = [
//   {
//     text: 'Merchant Generate QR Code',
//     image: [
//       { link: 'static/cases/rakija_1.jpg' }
//     ]
//   },
//   {
//     text: 'Merchant send generated QR to the Client',
//     image: [
//       { link: 'static/cases/rakija_2.jpg' }
//     ]
//   },
//   {
//     text: 'Client receive link and add his details to create virtual contract',
//     image: [
//       { link: 'static/cases/rakija_3.jpg' }
//     ]
//   },
//   {
//     text: 'Create Virtual contract',
//     image: [
//       { link: 'static/cases/rakija_4.jpg' }
//     ]
//   },
//   {
//     text: 'Store All data on BlockChain',
//     image: [
//       { link: 'static/cases/rakija_5.jpg' }
//     ]
//   },
//   {
//     text: 'Verify on BlockChain',
//     image: [
//       { link: 'static/cases/rakija_6.jpg' }
//     ]
//   }
// ];

function UseCaseProduct() {

  // let navigate = useNavigate();

  return (
    <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
      <CssBaseline />

      <Box className="purpleBox" sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" align="left" sx={{ mb: 2 }}>
          Use Case
        </Typography>
        {/* <Typography variant="body1" align="left" sx={{ my: 2 }}>
          A use case is a written description of how users will perform tasks on your website.
          It outlines, from a user’s point of view, a system’s behavior as it responds to a request.
          Each use case is represented as a sequence of simple steps, beginning with a user
          goal and ending when that goal is fulfilled.
        </Typography> */}
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="/static/cases/useCase.jpg"
            underline="none"
            color="info.light"
            sx={{ my: 1 }}
          >
            <img src="/static/cases/useCase.jpg" width="100%" />
          </Link>
        </Typography>
      </Box>
      <Box className="purpleBox" sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" align="left" sx={{ mb: 2 }}>
          Use Case Scenario - Prodavnica - Proizvođač majica
        </Typography>

        {/* <Typography variant="body1" align="left" sx={{ my: 2 }}>
          In this Use Case Scenario we will present you how t-shirt manufacturer
          generate and add QR code on product.
        </Typography> */}
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="/static/cases/t-shirt-use-case.jpg"
            underline="none"
            color="info.light"
            sx={{ my: 1 }}
          >
            <img src="/static/cases/t-shirt-use-case.jpg" width="100%" />
          </Link>
        </Typography>
      </Box>

      <Box className="turquoiseBox" sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" align="left" sx={{ mb: 2 }}>
          Use Case Scenario - Sadnja Drveća
        </Typography>
        {/* <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          In this Use Case Scenario we will present you how trees are planted with added GPS
          location. For each tree informations who planted and sponsored are stored on BlockChain.
        </Typography> */}

        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="/static/cases/planting-tree-use-case.jpg"
            underline="none"
            color="info.light"
            sx={{ my: 1 }}
          >
            <img src="/static/cases/planting-tree-use-case.jpg" width="100%" />
          </Link>
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="/static/cases/FlowNft.jpg"
            underline="none"
            color="info.light"
            sx={{ my: 1 }}
          >
            <img src="/static/cases/FlowNft.jpg" width="100%" />
          </Link>
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="/static/cases/TreeFlow.jpg"
            underline="none"
            color="info.light"
            sx={{ my: 1 }}
          >
            <img src="/static/cases/TreeFlow.jpg" width="100%" />
          </Link>
        </Typography>

      </Box>
      <Box className="turquoiseBox" sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" align="left" sx={{ mb: 2 }}>
          Use Case Scenario - Vinarija
        </Typography>
        {/* <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          In this Use Case Scenario we will present you how Vine manufacturer
          generate and add QR code on product.
        </Typography> */}

        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="/static/cases/vinery-use-case.jpg"
            underline="none"
            color="info.light"
            sx={{ my: 1 }}
          >
            <img src="/static/cases/vinery-use-case.jpg" width="100%" />
          </Link>
        </Typography>

      </Box>



      {/* <OneGridElementForPrez gridData={gridData} /> */}

    </Container>
  );
}

export default UseCaseProduct;
