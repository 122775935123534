import React, { useCallback, useEffect, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getQrCodeDataNoRedeem, generateNftFromExistingQrCode, clearState } from '../../redux/basic/actions';
import { objHasValues } from '../../common/commonFunctions'; // checkIfNftExist 
import LinkHref from '../LinkHref';
import SnackBarView from '../../view/SnackBarView';
import KeyValueFormView from '../../view/KeyValueFormView/KeyValueFormView';
import { useTranslation } from 'react-i18next';


const validationSchema = yup.object({
    productPicture: yup.mixed().required('Odaberite sliku!'),
    customNftMetaData: yup.object().optional()
});

function GenerateNftComponent() {

    const { t } = useTranslation();
    const [getQrCodeMetaData, setQrCodeMetaData] = useState({});
    const { qrcode } = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [openWarning, warningSetOpen] = useState(false);
    const [showBePatientText, setShowBePatientText] = useState(false);
    const [keyValueList, setKeyValueList] = useState({});
    const { token } = useSelector((state) => state.token);
    const { insertedQrCodeData, loaded, loadingNft, loadedNft, errorMessage } = useSelector((state) => state.basic);//walletQrId, errorMessage, internalErrorCode 

    let projectName = (insertedQrCodeData && insertedQrCodeData?._project && objHasValues(insertedQrCodeData._project) && insertedQrCodeData._project.projectName) ? insertedQrCodeData._project.projectName : '';
    let projectId = (insertedQrCodeData && insertedQrCodeData?._project && objHasValues(insertedQrCodeData._project) && insertedQrCodeData._project._id) ? insertedQrCodeData._project._id : '';
    let projectMetaData = (insertedQrCodeData && insertedQrCodeData?._project && objHasValues(insertedQrCodeData._project) && insertedQrCodeData._project.projectMetaData) ? insertedQrCodeData._project.projectMetaData : '';

    const dispatchCheckExistingQrCode = useCallback(
        (qrcode) => {
            dispatch(clearState());
            dispatch(getQrCodeDataNoRedeem(qrcode, token));
        }
    );

    useEffect(() => {
        if (loadedNft) {
            navigate(`/status/${qrcode}`);
        }
    }, [loaded, loadedNft]);

    useEffect(() => {
        if (errorMessage) {
            warningSetOpen(true);
        }
    }, [errorMessage]);


    const addMetaDataHasStory = (value) => {
        if (!value) {
            setQrCodeMetaData(prevState => ({
                ...prevState,
                ['Link_To_Story']: `${process.env.REACT_APP_WEB_SITE}/story/${insertedQrCodeData._id}`
            }));
        } else {
            let newObject = { ...getQrCodeMetaData };
            delete newObject.Link_To_Story;
            setQrCodeMetaData(newObject);
        }
    };

    const dispatchGeneratingNft = useCallback(
        (dataForm) => {
            dispatch(generateNftFromExistingQrCode({ dataForm, token }));
        }
    );

    useEffect(() => {
        dispatchCheckExistingQrCode(qrcode);
    }, [qrcode]);


    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                800,
                800,
                'JPEG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file'
            );
        });

    const formik = useFormik({
        initialValues: {
            productPicture: '',
            walletQrId: '',
            customNftMetaData: '',
            projectMetaData: projectMetaData,
            qrCodeMetaData: getQrCodeMetaData,
            hasstory: false
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setShowBePatientText(true);
                const image = await resizeFile(values.productPicture);

                let customNftData = keyValueList;

                let finalMetaData = {
                    ...projectMetaData,
                    ...getQrCodeMetaData,
                    ...customNftData
                };

                let dataForm = {
                    walletQrId: qrcode,
                    generatenft: true,
                    finalMetaData: JSON.stringify(finalMetaData),
                    cbnftimage: true,
                    hasstory: values.hasstory,
                    productPicture: image
                };
                console.log('finalMetaData', finalMetaData);
                dispatchGeneratingNft(dataForm);
            } catch (error) {
                return console.log(error);
            }
        }
    });

    useEffect(() => {
        if (loaded) {
            setQrCodeMetaData(prevState => ({
                ...prevState,
                ['QrCode']: qrcode
            }));
        }

        if (insertedQrCodeData.userDesc && loaded) {
            setQrCodeMetaData(prevState => ({
                ...prevState,
                ['PrimaryDetail']: insertedQrCodeData.userDesc
            }));
        }

        if (loaded) {
            formik.setFieldValue('hasstory', insertedQrCodeData.hasstory);
            if (insertedQrCodeData.hasstory)
                setQrCodeMetaData(prevState => ({
                    ...prevState,
                    ['Link_To_Story']: `${process.env.REACT_APP_WEB_SITE}/story/${insertedQrCodeData._id}`
                }));
        }


    }, [loaded, insertedQrCodeData]);

    const keyValueFormData = (keyValueFormData) => {
        console.log('received data from key-value form ', keyValueFormData);

        let finalObject = {};
        keyValueFormData.map(item => {
            if (item.key && item.value) {
                finalObject[item.key] = item.value;
            }
        });

        setKeyValueList(finalObject);
    };

    return (
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <SnackBarView
                handleClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    warningSetOpen(false);
                }}
                errorMessage={errorMessage}
                openSnackStatus={openWarning}
                type={'warning'}
            />

            <Typography variant="h5" align="center" sx={{ my: 2 }}>
                {(loadingNft) ? <CircularProgress /> : 'Generate NFT'}
            </Typography>
            <Typography variant="body1" sx={{ mt: 5 }}>
                {t('qrCodeName')} : &nbsp;
                <LinkHref color="orange" to={`/status/${qrcode}`}>
                    {insertedQrCodeData.productName}
                </LinkHref>

            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
                {t('belongToProject')}  : &nbsp;
                <LinkHref color="orange" to={`/projects/${projectId}`}>
                    {projectName}
                </LinkHref>
            </Typography>

            <Grid container alignItems="left" spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12} md={3}>
                    {t('projectMetaData')} :
                </Grid>
                <Grid item xs={12} md={9}>
                    <Typography variant="body1" component="pre" sx={{ mt: 1 }}>
                        {(projectMetaData) ? JSON.stringify(projectMetaData, null, 2) : ''}
                    </Typography>
                </Grid>
            </Grid>


            <Grid container alignItems="left" spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={12} md={3}>
                    {t('nftMetaData')}  :
                </Grid>
                <Grid item xs={12} md={9}>
                    <Typography variant="body1" component="pre" sx={{ mt: 1 }}>
                        {JSON.stringify(getQrCodeMetaData, null, 2)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="left" spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={12} md={3}>
                    {t('customMetaData')} :
                </Grid>
                <Grid item xs={12} md={9}>
                    <KeyValueFormView keyValueFormData={keyValueFormData} />
                </Grid>
            </Grid>
            <Grid container alignItems="center" >
                <Grid item xs={12} md={3}>
                    {t('selectPicture')} :
                </Grid>
                <Grid item xs={12} md={9}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="productPicture"
                        name="productPicture"
                        type="file"
                        onChange={(event) => {
                            formik.setFieldValue('productPicture', event.currentTarget.files[0]);
                        }}
                        error={formik.touched.productPicture && Boolean(formik.errors.productPicture)}
                        helperText={formik.touched.productPicture && formik.errors.productPicture}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="center" >
                <Grid item xs={12} md={3}>
                    {t('addStory')} :
                </Grid>
                <Grid item xs={12} md={9}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="hasstory"
                                name="hasstory"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    addMetaDataHasStory(!e.target.checked);
                                }}
                                checked={formik.values.hasstory}
                            />
                        }
                        label={t('nftWithStory')}
                    />
                </Grid>
            </Grid>


            <Grid container sx={{ mt: 2 }} spacing={2} alignItems="flex-start" direction="row" item xs={12} md={12}>
                <Grid item xs={6} md={3} hidden>
                    <Typography variant="body1">
                        {t('checkJson')}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={9} hidden>
                    <TextField sx={{ display: true }}
                        fullWidth
                        margin="normal"
                        id="customNftMetaData"
                        name="customNftMetaData"
                        placeholder='Example: { "Color": "Blue", "Shape": "Circle"}'
                        multiline
                        rows={8}
                        label="Add Additional MetaData : Must Be Json Object"
                        //value={formik.values.customNftMetaData}
                        value={JSON.stringify(keyValueList, null, 2)}
                        onChange={(e) => {
                            formik.handleChange(e);
                        }}
                        error={formik.touched.customNftMetaData && Boolean(formik.errors.customNftMetaData)}
                        helperText={formik.touched.formik && formik.errors.customNftMetaData}
                    />
                </Grid>
            </Grid>



            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                {(loadingNft) ? <CircularProgress sx={{
                    color: 'white'
                }} /> : t('generateNft')}
            </Button>
            {showBePatientText && <Typography variant="body1" component="pre" sx={{ mt: 1 }}>
                {t('averageTimeForNftCreation')}
            </Typography>}

        </Box >
    );
}

export default GenerateNftComponent;
