import React from 'react';
import Container from '@mui/material/Container';

const WrapperContainer = (props) => {
  return (
    <Container disableGutters name="WrapperContainer" maxWidth="lg" component="main" sx={{ pt: 0, pb: 4, minHeight: '100vh' }}>
      {props.children}
    </Container>
  );
};

export default WrapperContainer;
