import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
//import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { Link as RouterLink } from 'react-router-dom';
//import LinkHref from '../../components/LinkHref';
// import Link from '@mui/material/Link';
// const gridData = 
const listSokaPicMidJourney = [
    {
        text: 'MidJourney Prompt: Wide angle view, sharp focus, female, full length portrait, dynamic pose, futuristic, unreal engine, hyper realistic, cinematic composition, ',
        image: 'static/soka_mid/soka_mid_1.png'
    },
    {
        text: '2',
        image: 'static/soka_mid/soka_mid_2.png'
    },
    {
        text: '3',
        image: 'static/soka_mid/soka_mid_3.png'
    },
    {
        text: '4',
        image: 'static/soka_mid/soka_mid_4.png'
    }

];
const listSokaPicData = [
    {
        text: 'Before going to battle',
        image: 'static/soka/soka_4.jpeg'
    },
    {
        text: 'Main Character guideline. Calm, peaceful, serious, strong',
        image: 'static/soka/soka_5.jpeg'
    }
    // {
    //     text: 'Slika dva',
    //     image: 'static/soka/soka_2.jpeg'
    // },
    // {
    //     text: 'Slika tri',
    //     image: 'static/soka/soka_3.jpeg'
    // },

    // {
    //     text: 'Slika pet',
    //     image: 'static/soka/soka_6.jpg'
    // }
];

const listSokaPicDataExternal = [
    {
        text: 'Color of skin. Traits: scarf, tattoo',
        image: 'https://i.pinimg.com/564x/1b/5b/87/1b5b87523a01651fbd014a9233285c75.jpg'
    },
    {
        text: 'Face, hair, Eyes',
        image: 'https://i.pinimg.com/564x/36/4d/fa/364dfa70f467cb0a5e42b0b4adf5b96a.jpg'
    },
    {
        text: 'Jeens, pants',
        image: 'https://i.pinimg.com/564x/36/51/57/3651578a8820f3faa481bcdeef5a25c3.jpg'
    },
    {
        text: 'Skirt over pants, bandages on hands',
        image: 'https://i.pinimg.com/564x/7f/60/39/7f6039e51bda2015161d413de88ddcfc.jpg'
    },
    {
        //https://www.artstation.com/artwork/A5e5q
        text: 'Clotes',
        image: 'https://cdna.artstation.com/p/assets/images/images/045/859/652/large/shuo-shi-1-2.jpg?1643721376'
    },
    {
        text: 'Boots',
        image: 'https://cdna.artstation.com/p/assets/images/images/034/616/576/large/shuo-shi-15.jpg?1612782041'
    }
    // https://br.pinterest.com/pin/234961305549722902/
    //'https://cardanoproxies.io/',
    // 'https://www.pinterest.com/pin/470274386102017730/',
    // 'https://www.pinterest.com/pin/596938125623539162/',
    // 'https://www.pinterest.com/pin/2251868554763371/',
    // 'https://www.pinterest.com/pin/307230005840649529/',
    // 'https://www.pinterest.com/pin/13159023902061620/',
    // 'https://www.pinterest.com/pin/170855379605373519/',
    // 'https://www.pinterest.com/pin/96123773288413083/',
    // 'https://www.pinterest.com/pin/637048309802817694/',
    // 'https://www.pinterest.com/pin/4503668369521881/',
    // 'https://www.pinterest.com/pin/38139928087941158/'
];



function NftCharacterView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <CssBaseline />
            <Typography variant="h5" align="left" sx={{ mb: 2 }}>
                NFT Character - Story behind
            </Typography>

            <Box align="left" sx={{ mb: 2 }}>

                Character model requirement:
                <ul>
                    <li>In the center of the NFT is a picture of a human from year 7425.</li>
                    {/* <li>In the upper right corner of the picture is a placeholder for 3d rendered golden awards (coin, flaming torch ...)</li>
                    <li>The coat should be animated in neon color</li>*/}
                    <li>Background is to be black or deep space, or distant planet</li>
                    <li>Design a base character model for an NFT project. Layers will be added on and removed when we run the base model through our program that randomizes layers</li>
                </ul>

            </Box>


            <Typography variant="body1" align="left" sx={{ mb: 2 }} id="nftcharacter">
                STORY & CHARACTER BACKGROUND CONCEPT:
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                The concept is that she is a non modified human on distant planet
                lightyears away from earth. She is being created to aid humanity in a
                war against aliens that normal humans are less effective against.
                Beside the fact that she is non-modified, she has a Spirit given power
                to control nuclei in her body and around her.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                She controls nuclei by raising thoughts to higher frequency levels, and in that moment,
                she creates new materia or new life.  Yet, she does not know how she can do that.
            </Typography>

            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                When she is in trouble, then the nuclei start to be seen through the skin in different
                colors depending on the type of danger.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                Her job is to fight against enemies in this galaxy across time differences and multi-dimensions.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                She is calm, serious, strong, powerful, lonely, and full of love.
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                Her name is Soka.
            </Typography>

            <Box>
                <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                    Character Check list
                </Typography>
            </Box>
            <Box sx={{ mt: 5 }}>
                <ul>
                    <li> <Checkbox id="1" name="1" /> Who is she? Most important part! Please read : STORY & CHARACTER BACKGROUND CONCEPT</li>
                    <li> <Checkbox id="1" name="1" /> Character is from 7500 year and cloting should respresent that year. Without strong colors, high heels, etc</li>
                    <li> <Checkbox id="2" name="2" /> Color of the skin:  ligth brown</li>
                    <li> <Checkbox id="3" name="3" /> Character is: calm, serious, strong, powerful, lonely, and full of love</li>
                    <li> <Checkbox id="3" name="3" /> Full Body, free style, 3/4 front   (creator can decide).</li>
                    <li> <Checkbox id="3" name="3" /> Pose of character: atack pose or moment after winning the batter (calm and peaceful)</li>
                    <li> <Checkbox id="3" name="3" /> Type of desing : photorealistic or cartoonish (I will leave to creator to define type of picture)</li>
                    <li> <Checkbox id="3" name="3" /> Skin: In the moment of attact, or before battle, energy lines (like strams) start to be clearly visible through skin : picture REF 0 </li>
                </ul>
            </Box>
            <Box>
                <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                    Main Character GuideLine
                </Typography>
            </Box>
            <Box>
                <SokaCardPictures listSokaPicData={listSokaPicData} />
            </Box>
            <Box>
                <Typography variant="h5" align="left" sx={{ mt: 5 }} id="midjourney">
                    MidJourney
                </Typography>
            </Box>
            <Box>
                <SokaCardPictures listSokaPicData={listSokaPicMidJourney} />
            </Box>
            <Box>
                <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                    Character GuideLine
                </Typography>
            </Box>
            <Box>
                <SokaCardPicturesExternal listSokaPicDataExternal={listSokaPicDataExternal} />
            </Box>
            <Box sx={{ mt: 5 }}>
                <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                    Requirements for 2D
                </Typography>

                <ul>
                    <li>1 x 2D artwork - character</li>
                    <li>10 x 2D traits/accessories</li>
                    <li>Source file </li>
                    <li>Take a look of REF picutures down below </li>

                </ul>
            </Box>
            <Box sx={{ mt: 5 }}>
                <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                    Requirements for 3D
                </Typography>

                <ul>
                    <li>1 x 3D artwork - character</li>
                    <li>8 x 3D traits/accessories</li>
                    <li>Background/scene</li>
                    <li>Source file </li>
                </ul>
            </Box>
            <Box sx={{ mt: 5 }}>

                <Typography variant="body1" align="left" sx={{ mt: 1 }}>
                    Traits:
                </Typography>
                <ul>
                    <li>Scarf over mouth</li>
                    <li>Helmet</li>
                    <li>Clotes</li>
                    <li>Neckless</li>
                    <li>Skin wiht energy line (like on the picture above )  </li>
                    <li>Other Accessories (designer can suggest)</li>
                </ul>
            </Box>
        </Container>
    );
}


const SokaCardPictures = ({ listSokaPicData }) => {

    return (
        <Grid container spacing={5} alignItems="flex-end">
            {listSokaPicData.map((item, i) => {
                return (<Grid item xs={12} sm={3} md={3} key={i}>
                    <Card
                        sx={{
                            border: (theme) => `1px solid ${theme.palette.devider.color}`
                        }}>

                        <CardActionArea component={RouterLink} to={`/${item.image}`} target="_blank" rel="noreferrer noopener">
                            <CardContent sx={{ p: 0 }}>
                                <CardMedia component="img" image={`${process.env.REACT_APP_WEB_SITE}/${item.image}`} alt={item.text} />
                            </CardContent>
                            <CardContent sx={{ p: 0 }}>
                                {item.text}
                            </CardContent>

                            {/* <CardActions>
                                <Link variant="span" color='info.main' target="_blank" rel="noreferrer noopener"
                                    href={`${process.env.REACT_APP_WEB_SITE}/${item.image}`}>
                                    Big Picture
                                </Link>
                            </CardActions> */}
                        </CardActionArea>
                    </Card>
                </Grid>);
            })}


        </Grid>
    );
};
const SokaCardPicturesExternal = ({ listSokaPicDataExternal }) => {

    return (
        <Grid container spacing={5} alignItems="flex-end">
            {listSokaPicDataExternal.map((item, i) => {
                return (<Grid item xs={12} sm={3} md={3} key={i}>
                    <Card
                        sx={{
                            border: (theme) => `1px solid ${theme.palette.devider.color}`
                        }}>

                        <CardActionArea href={`${item.image}`} target="_blank" rel="noreferrer noopener">
                            <CardContent sx={{ p: 0 }}>
                                {/* <CardMedia component="img" imageUrl={`${item.image}`} alt={item.text} /> */}
                                <CardMedia square component="img" image={`${item.image}`} />
                            </CardContent>
                            <CardContent sx={{ p: 0 }}>
                                {item.text}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>);
            })}


        </Grid>
    );
};

export default NftCharacterView;
