import * as ActionTypes from './actions';

const initialTreeUser = {
  errorMessage: '',
  internalErrorCode: '',
  transactionData: '',
  listOfQrCodeOwnedByUser: [],
  qrCodeOwnersList: [],
  listUserProjects: [],
  loaded: false,
  loading: false
};

const immutableTreeUser = Object.freeze({
  errorMessage: '',
  internalErrorCode: '',
  transactionData: '',
  listOfQrCodeOwnedByUser: [],
  qrCodeOwnersList: [],
  listUserProjects: [],
  loaded: false,
  loading: false

});

const treeReducer = (state = initialTreeUser, action) => {

  switch (action.type) {
    // -----------------------  0 CLEAR USER ---------------------------
    case ActionTypes.CLEAR_STATE_LIST_QR_CODES_BY_USER:
      return {
        ...state,
        ...immutableTreeUser
      };

    // ----------------------- 1   --------------------------
    case ActionTypes.GET_ALL_QR_CODES_BY_USER:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case ActionTypes.GET_ALL_QR_CODES_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        transactionData: action.payload
      };

    case ActionTypes.GET_ALL_QR_CODES_BY_USER_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    // ----------------------- 2 ---------------------------
    case ActionTypes.GET_LIST_QR_CODES_GENERAL:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case ActionTypes.GET_LIST_QR_CODES_GENERAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        transactionData: action.payload
      };

    case ActionTypes.GET_LIST_QR_CODES_GENERAL_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    // ----------------------- 3 ---------------------------
    case ActionTypes.GET_LIST_QR_CODES_OWNERS:
      return {
        ...state,
        loaded: false,
        loading: true,
        qrCodeOwnersList: []
      };

    case ActionTypes.GET_LIST_QR_CODES_OWNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        qrCodeOwnersList: action.payload
      };

    case ActionTypes.GET_LIST_QR_CODES_OWNERS_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    // ----------------------- 4 LIST ALL PROJECTS ---------------------------
    case ActionTypes.LIST_ALL_PROJECTS:
      return {
        ...state,
        listUserProjects: [],
        errorMessage: '',
        internalErrorCode: '',
        loaded: false,
        loading: true
      };

    case ActionTypes.LIST_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        listUserProjects: action.payload,
        loaded: true,
        loading: false
      };

    case ActionTypes.LIST_ALL_PROJECTS_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        loaded: false,
        loading: false
      };

    // ----------------------- 5 DELETE PROJECT  ---------------------------
    case ActionTypes.DELETE_PROJECT:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        loaded: false,
        loading: true
        //listUserProjects: []
      };

    case ActionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
        //listUserProjects: action.payload
      };


    // ----------------------- GET QR CODES PER PROJECT---------------------------
    case ActionTypes.GET_ONE_PROJECT:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case ActionTypes.GET_ONE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        transactionData: action.payload
      };

    case ActionTypes.GET_ONE_PROJECT_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };
    // ----------------------- ADD QR CODE TO PROJECT ---------------------------
    case ActionTypes.ADD_QR_CODE_TO_PROJECT:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case ActionTypes.ADD_QR_CODE_TO_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };

    case ActionTypes.ADD_QR_CODE_TO_PROJECT_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    // UPDATE PROJECT
    case ActionTypes.UPDATE_PROJECT:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        loaded: false,
        loading: true
      };


    // ----------------------- UPDATE STATUS OF QR CODE ---------------------------
    case ActionTypes.CHANGE_STATUS_QR_CODE:
      return {
        ...state,
        loaded: false,
        loading: true
      };

    case ActionTypes.CHANGE_STATUS_QR_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };

    case ActionTypes.CHANGE_STATUS_QR_CODE_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    // ----------------------------- 10  UPDATE LIST OF QR CODES   -------------------------------
    case ActionTypes.UPDATE_LIST_OF_QR_CODES:
      return {
        ...state,
        listOfQrCodeOwnedByUser: action.payload.newListOfQrCodes
      };

    // ------------------  11 PRIVATE  ----------------------------
    case ActionTypes.GET_LIST_QR_CODES_BY_USER_PRIVATE:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        listOfQrCodeOwnedByUser: [],
        loading: true,
        loaded: false
      };
    case ActionTypes.GET_LIST_QR_CODES_BY_USER_PRIVATE_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        listOfQrCodeOwnedByUser: action.payload,
        //listOfOwnedQrCodesByUser: action.payload.listQrCodesOwnedByUser,
        loading: false,
        loaded: true
      };
    case ActionTypes.GET_LIST_QR_CODES_BY_USER_PRIVATE_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload?.data?.internalErrorCode,
        listOfQrCodeOwnedByUser: [],
        walletQrId: '',
        loading: false,
        loaded: false
      };


    // ------------------  5 getListQrCodesByUser  PUBLIC ----------------------------
    case ActionTypes.GET_LIST_QR_CODES_BY_USER:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        listOfQrCodesByUser: [],
        walletQrId: '',
        loading: true,
        loaded: false
      };
    case ActionTypes.GET_LIST_QR_CODES_BY_USER_SUCCESS:

      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        walletQrId: action.payload.walletQrId,
        listOfQrCodesByUser: action.payload,
        loading: false,
        loaded: true
      };
    case ActionTypes.GET_LIST_QR_CODES_BY_USER_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        listOfQrCodesByUser: [],
        walletQrId: '',
        loading: false,
        loaded: false
      };

    // ----------------------------- 11  UPDATE_ONE_ELEMENT_IN_DB   -------------------------------
    case ActionTypes.UPDATE_ONE_ELEMENT_IN_DB:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        loading: true,
        loaded: false
      };

    // ------------------ DEFAULT ----------------------------

    case ActionTypes.DEFAULT_CREATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case ActionTypes.DEFAULT_CREATE_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    default:
      return { ...state };
  }
};

export default treeReducer;
