import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import UserListOwnedQrCodesComponent from '../../components/UserListOwnedQrCodesComponent';
import Container from '@mui/material/Container';

function ListOwnedQRCodesPage() {
  return (
    <WrapperPage>
      <WrapperContainer>
        <Container disableGutters name="WrapperContainer" maxWidth="lg" sx={{ pt: 0, pb: 4 }}>
          <UserListOwnedQrCodesComponent />
        </Container>
      </WrapperContainer>
    </WrapperPage>
  );
}

export default ListOwnedQRCodesPage;
