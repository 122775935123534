const youtubeParser = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};


const isObject = (val) => {
  if (val === null) { return false; }
  return ((typeof val === 'function') || (typeof val === 'object'));
};

const objHasValues = (obj) => {
  if (!obj) return false;
  return Object.keys(obj).length > 0;
};
const objIsEmpty = (obj) => {
  //if (!obj) return true;
  return Object.keys(obj).length === 0;
};

const checkIfNftExist = (data) => {
  return (data?._nfts && data?._nfts.length > 0) ? true : false;
};

const getDataFieldFromNftObj = (data, field) => {
  if (data?._nfts && data?._nfts.length > 0) {
    return data._nfts[0][field];
  }
};

const checkIfNftValueIsTrue = (data, field) => {
  if (checkIfNftExist(data) && data._nfts[0][field]) {
    return data._nfts[0][field];
  }
};

export { youtubeParser, objHasValues, objIsEmpty, checkIfNftExist, getDataFieldFromNftObj, isObject, checkIfNftValueIsTrue };
