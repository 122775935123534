import React from 'react';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function StatusQrCodeComponentError({ dataError }) {
  let errorMessage = dataError.errorMessage;
  let internalErrorCode = dataError.internalErrorCode;

  return (
    <Box noValidate sx={{ mt: 5, mb: 3, pb: 2, width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" color="secondary" align="justify" sx={{ m: 3 }}>
        {errorMessage}
      </Typography>
      <Typography variant="caption" color="secondary" align="justify" sx={{ m: 3 }}>
        Internal Error Code : {internalErrorCode}
      </Typography>
    </Box>
  );
}

export default StatusQrCodeComponentError;
