import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

function ShowSuccess({message}) {
    return (
        <Container maxWidth="xs">
          <Box justifyContent="center" sx={{ mt: 1, mb: 1, pb: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" align="center" sx={{ mt: 3, mb: 2 }}>
              {message}
            </Typography>   
          </Box>
        </Container>
      );
}

export default ShowSuccess;