import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import UserListQrCodes from '../../components/UserListQrCodes';
import Container from '@mui/material/Container';

function ListQRCodesPage() {
  return (
    <WrapperPage>
      <WrapperContainer>
        <Container disableGutters name="WrapperContainer" maxWidth="lg" sx={{ pt: 0, pb: 4, background: '#094164ab' }}>
          <UserListQrCodes />
        </Container>
      </WrapperContainer>
    </WrapperPage>
  );
}

export default ListQRCodesPage;
