import React, { useState, useCallback, useEffect } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
//import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
//import { NavLink } from 'react-router-dom';
import { generateQrCodeInSystem } from '../../redux/basic/actions';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Resizer from 'react-image-file-resizer';
import { userInfo } from '../../common/strings';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import SnackBarView from '../../view/SnackBarView';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object({
  uuid: yup.string('Add Unique String').min(8, 'ID can be min 8 characters').max(38, 'ID can be max 38 characters').required('Unique number is required'),
  email: yup.string('Unesite svoj email').email('Unesite validan email').required('Email je obavezan'),
  // costOfProduct: yup.number('Enter how much cost your product').min(0, 'Can be min 1').max(1000, 'Can be max 1000'), // .required('Number is required'),
  userFullname: yup
    .string('Unesite ime')
    .min(3, 'Ime mora imati bar 3 karaktera')
    .max(40, 'Ime može imati maksimalno 40 karaktera')
    .required('Ime je obavezno'),
  productName: yup
    .string('Unesite ime proizvoda')
    .min(3, 'Ime proizvoda mora imati bar 3 karaktera')
    .max(20, 'Ime proizvoda može imati maksimalno 40 karaktera')
    .required('Ime proizvoda je obavezno'),
  //productName: yup.string('Enter Product name').matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field. No Spaces is allowed').min(11, 'Name can be min 11 characters').required('Name is required'),
  userDesc: yup
    .string('Enter message')
    .min(3, 'Opis proizvoda mora imati bar 3 karaktera')
    .max(55, 'Opis proizvoda može imati maksimalno 63 karaktera')
    .required('Opis je obavezan'),
  longText: yup
    .string('Enter message')
    .max(1000, 'Opis proizvoda može imati maksimalno 63 karaktera')
    .optional(),
  productVideo: yup.string('Unesite YouTube link').min(5, 'Link nije validan').max(30, 'Link je predugačak'),
  generatenft: yup.boolean(),
  productPicture: yup.mixed().when('generatenft', {
    is: true,
    then: (validationSchema) => validationSchema.required('Odaberite sliku!')
  }),
  addcontributor: yup.boolean(),
  contributorData: yup.string().when('addcontributor', {
    is: true,
    then: (validationSchema) => validationSchema.max(63, 'Polje prihvata maksimalno 16 karaktera').required('Niste popunili polje')
  })
});

const GenerateQrCodeForm = () => {
  const { t, i18n } = useTranslation();
  const [uuid, setUuid] = useState(uuidv4());
  const [getShowCostOfGoods, setCostOfGoods] = useState(false);
  const [qrCodelink, setQrCodelink] = useState(`${process.env.REACT_APP_WEB_SITE}/status/${uuid}`);
  const { transactionUserData } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.token);

  const dispatch = useDispatch();

  const handleCostOfGood = () => {
    setCostOfGoods(!getShowCostOfGoods);
  };

  const stableDispatch = useCallback(dispatch, [dispatch]);

  const formQrSubmitDipatch = useCallback(
    (dataForm) => {
      stableDispatch(generateQrCodeInSystem({ dataForm, token }));
    },
    [stableDispatch]
  );

  const changeQrImage = (e) => {
    setUuid(e);
    let fullWebQR = `${process.env.REACT_APP_WEB_SITE}/status/${e}`;
    setQrCodelink(fullWebQR);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const formik = useFormik({
    initialValues: {
      email: transactionUserData?.userEmail,
      uuid: uuid,
      userFullname: transactionUserData?.userFullName,
      productName: '',
      userDesc: '',
      productPicture: '',
      productVideo: '',
      publicQrCode: true,
      costOfProduct: 1, //''
      addcontributor: false,
      contributorData: '',
      generatenft: false,
      longText: ''
      //hasstory: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const image = values.generatenft ? await resizeFile(values.productPicture) : '';
        console.log('image', image);

        let dataForm = {
          walletQrId: values.uuid,
          userDesc: values.userDesc,
          userFullname: values.userFullname,
          productName: values.productName,
          userEmail: values.email,
          costOfProduct: 1, //values.costOfProduct,
          qrCodeRedeemStatus: 0,
          contributorData: values.contributorData,
          generatenft: values.generatenft,
          productVideo: values.productVideo,
          publicQrCode: values.publicQrCode,
          longText: values.longText,
          userLang: i18n.resolvedLanguage,
          // hasstory: values.hasstory,
          productPicture: image //values.productPicture,
        };

        formQrSubmitDipatch(dataForm);
      } catch (error) {
        return console.log(error);
      }
    }
  });

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Grid container spacing={4} direction="row" sx={{ my: 3 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowWrap: 'break-word'
            }}
          >
            <ShowFormForGeneratingQrCode
              handleCostOfGood={handleCostOfGood}
              getShowCostOfGoods={getShowCostOfGoods}
              formik={formik}
              t={t}
              changeQrImage={changeQrImage}
              transactionUserData={transactionUserData}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ShowRightSide qrCodelink={qrCodelink} t={t} uuid={uuid} transactionUserData={transactionUserData} />
        </Grid>
      </Grid>
    </Container>
  );
};

// useEffect(() => {
//   if (errorMessage !== '') {
//     open ? '' : setOpen(true);
//   }
// }, [errorMessage]);
const ShowRightSide = ({ transactionUserData, t }) => {
  const [open, setOpen] = useState(false);
  // const [openWarning, warningSetOpen] = useState(false);
  //const [errorMsgType, setErrorMsgType] = useState('error');
  //const [warrningMsgType, setWarrningMsgType] = useState('warning');
  //const errorMsg="Nemate dovoljno QR kodova na raspolaganju."
  //const warringMsg="Nemate dovoljno kupona na raspolaganju."
  const numberOfQrCodes = transactionUserData.numberOfTransaction ? transactionUserData.numberOfTransaction : userInfo.remainingNumberQrCodes;
  //const numberOfCoupons = transactionUserData.numberOfCoupons ? transactionUserData.numberOfCoupons : userInfo.remainingNumberCoupons;

  // if (numberOfQrCodes > 1) {
  //   setOpen(true);
  // }
  // if (numberOfCoupons < 1) {
  //   warningSetOpen(true);
  // }
  useEffect(() => {
    if (numberOfQrCodes < 1) {
      setOpen(true);
    }
    // if (numberOfCoupons < 1) {
    //   warningSetOpen(true);
    // }
  }, [numberOfQrCodes]);
  // const [openError, errorSetOpen] = useState(false);
  // const errorMessage = '';
  // const internalErrorCode = '';

  return (
    <>
      <Box noValidate sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 6 }}>
        <Typography variant="body" sx={{ my: 1, textAlign: 'left', fontWeight: 'bold' }}>
          {' '}
          {t('yourStats')}:{' '}
        </Typography>
        <Grid container spacing={2} alignItems="left" direction="row" item xs={12} md={12}>
          <Grid item xs={6} md={6}>
            <div>
              <Typography variant="body" sx={{ my: 0, textAlign: 'left' }}>
                {t('remainingNumberQrCodes')}:{' '}
              </Typography>
            </div>
            <div>
              <Typography variant="body" sx={{ my: 0, textAlign: 'left', fontWeight: 'bold' }}>
                {transactionUserData.numberOfTransaction ? transactionUserData.numberOfTransaction : userInfo.remainingNumberQrCodes}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div>
              <Typography variant="body" sx={{ my: 0, textAlign: 'left' }}>
                {t('remaining-number-of-nft-coupons')}:{' '}
              </Typography>
            </div>
            <div>
              <Typography variant="body" sx={{ my: 0, textAlign: 'left', fontWeight: 'bold' }}>
                {transactionUserData.numberOfCoupons ? transactionUserData.numberOfCoupons : userInfo.remainingNumberCoupons}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box noValidate sx={{ mt: 1, mb: 1, pb: 1, width: '100%', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1" align="justify" sx={{ mt: 3, mb: 2 }}>
          {t('onThisPage')}
        </Typography>
      </Box>
      <SnackBarView
        handleClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setOpen(false);
        }}
        errorMessage={'Nemate dovoljno QR kodova na raspolaganju.'}
        openSnackStatus={open}
        type={'error'}
      />
    </>
  );
};
const ShowFormForGeneratingQrCode = ({ formik, changeQrImage, handleCostOfGood, transactionUserData, t }) => {
  // getShowCostOfGoods
  // const [productPictureDisabled, productPictureSetDisabled] = useState(true);
  // const [generateQrCodeBtnDisabled, generateQrCodeBtnSetDisabled] = useState(false);

  const numberOfQrCodes = transactionUserData.numberOfTransaction ? transactionUserData.numberOfTransaction : userInfo.remainingNumberQrCodes;
  //console.log('numberOfQrCodes ', numberOfQrCodes);
  // const numberOfCoupons = transactionUserData.numberOfCoupons ? transactionUserData.numberOfCoupons : userInfo.remainingNumberCoupons;
  //console.log('numberOfCoupons ', numberOfCoupons);
  // const [generatenftDisabled] = useState('true');

  // if (numberOfQrCodes === 0) {
  //   console.log('numberOfQrCodes 0');
  // }
  // if (numberOfCoupons === 0) {
  //   console.log('numberOfCoupons 0');
  //   generateQrCodeBtnSetDisabled(true);
  // }
  return (

    
    <>
      <Typography variant="body" sx={{ my: 4, textAlign: 'center', fontWeight: 'bold' }}>
        {t('startGeneratingItem')}{' '}
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off" sx={{ mt: 1, width: '100%',borderColor:'red !important' }}>
        <TextField
          fullWidth
          margin="normal"
          id="uuid"
          name="uuid"
          label={t('unique-id')}
          value={formik.values.uuid}
          onChange={(e) => {
            formik.handleChange(e);
            changeQrImage(e.target.value);
          }}
          style={{color:'#ff8d00 !important'}}
          error={formik.touched.uuid && Boolean(formik.errors.uuid)}
          helperText={formik.touched.uuid && formik.errors.uuid}
        />

        <TextField
          fullWidth
          margin="normal"
          focused
          id="productName"
          name="productName"
          label={t('productName')}
          onChange={formik.handleChange}
          error={formik.touched.productName && Boolean(formik.errors.productName)}
          helperText={formik.touched.productName && formik.errors.productName}
        />
        <TextField
          fullWidth
          margin="normal"
          focused
          id="userFullname"
          name="userFullname"
          label={t('creatorName')}
          value={formik.values.userFullname}
          onChange={formik.handleChange}
          error={formik.touched.userFullname && Boolean(formik.errors.userFullname)}
          helperText={formik.touched.userFullname && formik.errors.userFullname}
        />

        <TextField
          fullWidth
          focused
          margin="normal"
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          fullWidth
          focused
          margin="normal"
          id="userDesc"
          name="userDesc"
          label={t('product-detail')}
          value={formik.values.userDesc}
          onChange={formik.handleChange}
          error={formik.touched.userDesc && Boolean(formik.errors.userDesc)}
          helperText={formik.touched.userDesc && formik.errors.userDesc}
        />
        <TextField
          fullWidth
          focused
          margin="normal"
          multiline
          rows={4}
          id="longText"
          name="longText"
          label={t('long-product-desc')}
          value={formik.values.longText}
          onChange={formik.handleChange}
          error={formik.touched.longText && Boolean(formik.errors.longText)}
          helperText={formik.touched.longText && formik.errors.longText}
        />

        <Button type="submit" fullWidth variant="contained" disabled={numberOfQrCodes < 1 ? true : false} sx={{ mt: 3, mb: 2,backgroundColor:(theme)=> theme.orangeButton.button }}>
          {t('generateQrTag')}
        </Button>


        <Typography variant="body1" align="left" sx={{ mt: 3, mb: 1 }}>
          {t('additional-configuration')}
        </Typography>

        <TextField
          fullWidth
          focused
          margin="normal"
          id="productVideo"
          name="productVideo"
          label={t('product-video')}
          value={formik.values.productVideo}
          onChange={formik.handleChange}
          error={formik.touched.productVideo && Boolean(formik.errors.productVideo)}
          helperText={formik.touched.productVideo && formik.errors.productVideo}
        />

        <FormGroup>
          <Grid container spacing={2} alignItems="center" direction="row" item xs={12} md={12}>
            <Grid item xs={6} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="addcontributor"
                    name="addcontributor"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    style ={{
                      color: '#ff8d00'
                    }}
                    checked={formik.values.addcontributor}
                  />
                }
                label={t('add-sponsor')}
              />
            </Grid>
          </Grid>
        </FormGroup>

        <AddContributor formik={formik}></AddContributor>

        <FormGroup sx={{ mt: 3 }}>
          <FormLabel id="demo-row-radio-buttons-group-label">{t('stateOfQrCode')}</FormLabel>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            id="publicQrCode"
            name="publicQrCode"
            value={formik.values.radiobutton}
            //onChange={formik.handleChange}
            onChange={(e) => {
              formik.handleChange(e);
              handleCostOfGood(e.target.value);
            }}
            defaultValue="true"
          >
            <FormControlLabel value="false" control={<Radio color="warning" />} label={t('private-qrcode')} />
            <FormControlLabel value="true" control={<Radio color="warning" />} label={t('public-qrcode')} />
          </RadioGroup>
        </FormGroup>
      </Box >
    </>
  );
};

const AddContributor = ({ formik }) => {
  let returnData = '';
  if (formik.values.addcontributor) {
    returnData = (
      <TextField
        fullWidth
        focused
        margin="normal"
        id="contributorData"
        name="contributorData"
        label="Saradnik / Sponzor / Kompanija"
        value={formik.values.contributorData}
        onChange={formik.handleChange}
        error={formik.touched.contributorData && Boolean(formik.errors.contributorData)}
        helperText={formik.touched.contributorData && formik.errors.contributorData}
      />
    );
  }
  return returnData;
};

export default GenerateQrCodeForm;
