import { takeEvery, call, put, fork } from 'redux-saga/effects';
import * as ActionTypes from './actions';
import * as ActionTokenTypes from '../token/actions';
import Api from '../../api/calls';

function* registerUser(action) {
  try {
    const result = yield call(Api.user.registerUser, action.payload);
    yield put(ActionTypes.registerUserSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.registerUserError(error.response.data));
  }
}

function* logInUser(action) {
  try {
    const result = yield call(Api.user.logInUser, action.payload);
    yield put(ActionTokenTypes.setToken(result.data));
    yield put(ActionTypes.logInUserSuccess(result.data.user));
  } catch (error) {
    yield put(ActionTypes.logInUserError(error.response.data));
  }
}

function* getUserData(action) {
  try {
    const result = yield call(Api.user.getUserData, action.payload);
    yield put(ActionTypes.getUserDataSuccess(result.data[0]));
  } catch (error) {
    yield put(ActionTypes.getUserDataError(error.response.data));
  }
}

function* resetPassword(action) {
  try {
    const result = yield call(Api.user.resetPassword, action.payload);
    yield put(ActionTypes.resetPasswordSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.resetPasswordError(error.response.data));
  }
}

function* resetPasswordCode(action) {
  try {
    const result = yield call(Api.user.resetPasswordCode, action.payload);
    yield put(ActionTypes.resetPasswordCodeSuccess(result.data));
    yield fork(clearStateUser);
  } catch (error) {
    yield put(ActionTypes.resetPasswordCodeError(error.response.data));
  }
}

function* clearStateUser() {
  try {
    yield put(ActionTypes.clearStateUserSuccess());
    yield put(ActionTokenTypes.unsetToken());
  } catch (error) {
    yield put(ActionTypes.clearStateUserError());
  }
}

function* clearStateNoUserData() {
  try {
    yield put(ActionTypes.clearStateNoUserDataSuccess());
  } catch (error) {
    yield put(ActionTypes.clearStateNoUserDataError());
  }
}

function* sendApprovalToClient(action) {
  try {
    const result = yield call(Api.user.sendApprovalToClient, action.payload);
    yield put(ActionTypes.sendApprovalToClientSuccess(result.data[0]));
  } catch (error) {
    yield put(ActionTypes.sendApprovalToClientError(error.response.data));
  }
}

export default function* userSaga() {
  yield takeEvery(ActionTypes.REGISTER_USER, registerUser);
  yield takeEvery(ActionTypes.LOGIN_USER, logInUser);
  yield takeEvery(ActionTypes.GET_USER_DATA, getUserData);
  yield takeEvery(ActionTypes.RESET_PASSWORD, resetPassword);
  yield takeEvery(ActionTypes.RESET_PASSWORD_CODE, resetPasswordCode);
  yield takeEvery(ActionTypes.CLEAR_STATE_USER, clearStateUser);
  yield takeEvery(ActionTypes.CLEAR_STATE_NO_USER_DATA, clearStateNoUserData);
  yield takeEvery(ActionTypes.SEND_APPROVAL_TO_CLIENT, sendApprovalToClient);
}
