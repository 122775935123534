import React from 'react'; // useState
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ShowPictureAndVideo from '../ShowPictureAndVideo';
import LinkHref from '../../components/LinkHref';
import NftData from '../../view/NftData';
import { objHasValues } from '../../common/commonFunctions';
var QRCode = require('qrcode.react');
import { useTranslation } from 'react-i18next';


//import { checkIfNftExist, getDataFieldFromNftObj } from '../../common/commonFunctions';

function StatusQrCodeComponent({ data }) {

  const { t } = useTranslation();
  let transactionId = data.transactionId;
  let linkToCardanoExplorer = data.linkToCardanoExplorer;
  let fullWebQR = data.fullWebQR;
  let qrcode = data.qrcode;
  let insertedQrCodeData = data.insertedQrCodeData;
  let qrCodeRedeemStatus = insertedQrCodeData.qrCodeRedeemStatus;
  let QRCodeLogo = require('../../qrcodelogo.png');
  let PhoneQRCode = require('../../phoneforqrcode.png');
  let BlokariaLogo = require('../../BlokariaLogoPlava.png');
  let mintedNft = (objHasValues(insertedQrCodeData) &&
    insertedQrCodeData?._nfts &&
    insertedQrCodeData?._nfts.length > 0) ? true : false;

  return (
    <Grid>
      {qrCodeRedeemStatus ? (
        <>
          <Grid container direction="row" sx={{ pt: 1 }}>
            <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
              {t('qrCodeId')}:
            </Grid>
            <Grid item xs={12} sm={9} md={10}>
              {qrcode}
            </Grid>
          </Grid>
          {transactionId ?
            (<Grid container direction="row" sx={{ pt: 1 }}>
              <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
                {t('txId')}:
              </Grid>

              <Grid item xs={12} sm={9} md={10}>
                <Link variant="span" underline="none" sx={{ color: 'yellow' }} target="_blank" rel="noreferrer noopener" href={linkToCardanoExplorer}>
                  {t('onThisLinkContractBlockchain')}:
                </Link>
              </Grid>
            </Grid>) : ''
          }
        </>
      ) : (
        <Grid container direction="row">
          <Typography variant="body1" align="justify" sx={{ my: 3 }}>
            {t('click')}{' '}
            <LinkHref sx={{ color: 'yellow' }} to={`/generate-contract/${qrcode}`}>
              {t('here')}
            </LinkHref>{' '}
            {t('toBecomeQrCodeOwner')}
          </Typography>
        </Grid>
      )}

      {qrCodeRedeemStatus ? (
        <Grid container direction="row">
          <Grid item xs={12} sm={3} md={2}></Grid>
          <Grid item xs={12} sm={9} md={10}>

          </Grid>
        </Grid>
      ) : ('')}

      {qrCodeRedeemStatus ? <ClientData insertedQrCodeData={insertedQrCodeData} t={t} /> : ''}

      {insertedQrCodeData.contributorData ? <Contributor insertedQrCodeData={insertedQrCodeData} t={t} /> : ''}

      <CreatorBox t={t} insertedQrCodeData={insertedQrCodeData} />
      {mintedNft ? <NftData insertedQrCodeData={insertedQrCodeData} /> : ''}


      {insertedQrCodeData.longText ? <AdditionalText t={t} insertedQrCodeData={insertedQrCodeData.longText} /> : ''}

      {(insertedQrCodeData._image.length > 0 || insertedQrCodeData.productVideo) ? <ShowPictureAndVideo data={{ picture: insertedQrCodeData._nfts, video: insertedQrCodeData.productVideo }} /> : ''}

      <Box className="darkBlueBox" noValidate sx={{ mt: 4, py: 1, width: '100%', justifyContent: 'center', display: 'flex', color: '#000', fontWeight: 600 }}>
        QR code for this transaction
        <img src={QRCodeLogo} alt="QR Code Logo" style={{ marginLeft: '10px', marginTop: '-3px', width: '30px', height: '30px' }} />
      </Box>

      <Box noValidate className='phoneWrapper' sx={{ mt: 5, mb: 3, pb: 2, width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <div style={{ position: 'relative' }}>
          <QRCode className='QRCodeInPhone' value={fullWebQR} style={{ height: '200px', maxWidth: '100%', width: '200px', position: 'absolute' }} />
          <img className='phoneImageClass' src={PhoneQRCode} alt=" Phone QR Code Logo" style={{ marginLeft: '10px', width: '600px', height: '600px' }} />
          <img className='blokariaLogoPhone' src={BlokariaLogo} alt="Blokaria Logo" style={{ marginLeft: '10px' }} />
          <Typography className='thisIsYourQRCode' sx={{ position: 'absolute' }}>This is your QR code</Typography>
        </div>
        <Typography variant="body1" color="secondary" align="justify" sx={{ m: 3 }}></Typography>
      </Box>
    </Grid>
  );
}

// CREATOR BOX
const CreatorBox = ({ insertedQrCodeData, t }) => {
  let LogoCreator = require('../../creator.png');
  return <>
    <Box className="darkBlueBox" noValidate sx={{ my: 1, py: 1, width: '100%', justifyContent: 'center', color: '#000', fontWeight: 600, display: 'flex' }}>
      {t('producer')}
      <img src={LogoCreator} alt="Creator Logo" style={{ marginLeft: '10px', width: '30px', height: '25px' }} />
    </Box>
    <div className='WrapperContent'>
      <Grid container direction="row" sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('productName')}:
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {insertedQrCodeData.productName}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('creatorName')}:
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {insertedQrCodeData.userFullname}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('creatorEmail')}:
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {insertedQrCodeData.userEmail}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('creatorMsg')} :
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {insertedQrCodeData.userDesc}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} display={insertedQrCodeData.metaDataRandomNumber ? '' : 'none'} sx={{ fontWeight: 'bold' }}>
          {t('metaDataNumber')}:
        </Grid>
        <Grid item xs={12} sm={9} md={10} display={insertedQrCodeData.metaDataRandomNumber ? '' : 'none'}>
          {insertedQrCodeData.metaDataRandomNumber}
        </Grid>
      </Grid>
    </div>
  </>;
};

// ADITIONAL TEXT
const AdditionalText = ({ insertedQrCodeData, t }) => {
  let AdditionalTextLogo = require('../../additionaltext.png');

  return (<Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
    <Box className="darkBlueBox" noValidate sx={{ mt: 4, py: 1, width: '100%', justifyContent: 'center', display: 'flex', color: '#000', fontWeight: 600 }}>
      {t('additional-text')}
      <img src={AdditionalTextLogo} alt="Additional Text Logo" style={{ marginLeft: '10px', width: '30px', height: '30px' }} />
    </Box>
    <div className='WrapperContent'>
      <Typography sx={{ mt: 1, mb: 1, pb: 2 }} dangerouslySetInnerHTML={{ __html: insertedQrCodeData }}>
      </Typography>
    </div>
  </Container>);
};

// CLIENT DATA
const ClientData = ({ t, insertedQrCodeData }) => {
  let LogoOwner = require('../../owner.png');
  return (
    <>
      <Box className="darkBlueBox" noValidate sx={{ mt: 4, py: 1, width: '100%', justifyContent: 'center', display: 'flex', color: '#000', fontWeight: 600 }}>
        {t('owner')}
        <img src={LogoOwner} alt="Owner Logo" style={{ marginLeft: '10px', width: '25px', height: '25px' }} />
      </Box>
      <div className='WrapperContent'>
        <Grid container direction="row" sx={{ pt: 1 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            {t('ownerName')}:
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {insertedQrCodeData.clientName}
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            {!insertedQrCodeData.ownernamecb ? t('name-will-not-be-added-to-bc') : t('name-will-be-added-to-bc')}
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ pt: 1 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            {t('ownerEmail')}:
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {insertedQrCodeData.clientEmail}
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            {!insertedQrCodeData.clientemailcb ? t('email-will-not-be-added-to-bc') : t('email-will-be-added-to-bc')}
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ pt: 1 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            <div style={{ maxWidth: '0px !important' }}>
              {t('ownerMsg')}:
            </div>
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            {insertedQrCodeData.clientMessage}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

// CONTRIBUTOR
const Contributor = ({ insertedQrCodeData, t }) => {
  let LogoSponsor = require('../../spon.png');
  return (
    <>
      <Box className="darkBlueBox" noValidate sx={{ mt: 4, mb: 1, py: 1, width: '100%', justifyContent: 'center', display: 'flex', color: '#000', fontWeight: 600 }}>
        {t('sponsorSupporterContributor')}
        <img src={LogoSponsor} alt="Owner Logo" style={{ marginLeft: '10px', width: '30px', height: '25px' }} />
      </Box>
      <div className='WrapperContent'>
        <Grid container direction="row" sx={{ pt: 1 }}>
          <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
            {t('name')}:
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            {insertedQrCodeData.contributorData}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default StatusQrCodeComponent;
