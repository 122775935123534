import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



function GoToMarketStrategyView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <CssBaseline />
            <Typography variant="h5" align="left" sx={{ mb: 2 }}>
            GoToMarketStrategyView
            </Typography>
            <Box align="left" sx={{ mb: 2 }}>
           Opis
            </Box>
           
        </Container>
    );
}



export default GoToMarketStrategyView;
