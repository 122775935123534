import { red, grey, common, lightBlue } from '@mui/material/colors';
// green, lime, teal, indigo, orange, blueGrey
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: lightBlue[800],
      light: lightBlue[700],
      dark: lightBlue[900],
      black: lightBlue[900]
    },
    secondary: {
      main: lightBlue[800],
      light: lightBlue[700],
      dark: lightBlue[900]
    },
    info: {
      main: grey[300],
      light: grey[200],
      dark: grey[400]
    },
    purple: {
      main: '#e82fa0',
      light: '#f043ad',
      dark: '#9f206e'
    },
    white: {
      main: '#0277bd',
      white:'#fff',
      transparentWhite:'rgba(255,255,255, 0.8)'
    },
    black:{
      main:'#0000'
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff'
    },
    headerFooter:{
      both:'#09416400',
      footer:'#094164ab'
    },
    background: {
      default: '#082a3f',
      defaultPrimary: '#1973ac',
      defaultSecondary: '#007CC7',
      paper: '#007CC7',
      paperGray: '#305268',
      darkBlue: '#031724',
      blueTransparent: 'rgb(52 94 141 / 57%) !important'
    },
    devider: {
      color: grey[800]
    },
    text: {
      primary: common.white,
      secondary: common.white
    },
    error: {
      main: red.A400
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFF'
        }
      }
    }
  },
  orangeButton:{
    flexGrow: {
      flex: '1'
    },
    button: {
      backgroundColor: '#ff8d00',
      color: '#fff',
      fontWeight: '600',
      '&:hover': {
        backgroundColor: '#0377be',
        color: 'white'
      }
    }
  },
  grayButton:{
    flexGrow: {
      flex: '1'
    },
    button: {
      backgroundColor: 'gray',
      color: '#fff',
      fontWeight: '600',
      '&:hover': {
        backgroundColor: '#0377be',
        color: 'white'
      }
    }
  },
  redButton:{
    flexGrow: {
      flex: '1'
    },
    button: {
      backgroundColor: 'red',
      color: '#fff',
      fontWeight: '600',
      '&:hover': {
        backgroundColor: '#0377be',
        color: 'white'
      }
    }
  },
  linkHref:{
    link:{
      color: '#ff8d00',
      fontWeight: '600',
      '&:hover': {
        color: 'white'
      }
    },
    linkWhite:{
      color: 'white',
      fontWeight: '600',
      '&:hover': {
        color: '#ff8d00'
      }
    }
  }
});

export default theme;
