import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/Router';
import ReactGA from 'react-ga4';
ReactGA.initialize(process.env.REACT_APP_GA4);
ReactGA.send('pageview');

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
