import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CheckIcon from '@mui/icons-material/Check';
var QRCode = require('qrcode.react');
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { useTranslation } from 'react-i18next';



function QrCodeGeneratedSuccessfully({ data }) {
  const { t } = useTranslation();

  let qrcode = data.qrcode;
  let insertedQrCodeData = data.insertedQrCodeData;
  let PhoneQRCode = require('../../phoneforqrcode.png');
  let BlokariaLogo = require('../../BlokariaLogoPlava.png');
  let picLoc = insertedQrCodeData._image.length
    ? `${process.env.REACT_APP_MOLECULER_SERVICE}/${insertedQrCodeData._image[0].productPicture}`
    : '/static/no_image.png';

  let linkCreated = insertedQrCodeData.publicQrCode
    ? `${process.env.REACT_APP_WEB_SITE}/generate-contract/${qrcode}`
    : `${process.env.REACT_APP_WEB_SITE}/generate-contract/${qrcode}/${insertedQrCodeData.accessCode}`;

  const walletPicture = () => {
    return (
      <Grid container direction="row" item xs={12} sx={{ mt: 3 }}>
        <Grid item xs={4}>
          {t('productPicture')}
        </Grid>
        <Grid item xs={8}>
          <img src={picLoc} width="200px" />
        </Grid>
      </Grid>
    );
  };
  const walletVideo = () => {
    return (
      <Grid container direction="row" item xs={12} sx={{ mt: 3 }}>
        <Grid item xs={4}>
          Video o proizvodu:
        </Grid>
        <Grid item xs={8}>
          {t('productVideo')}
        </Grid>
      </Grid>
    );
  };
  return (
    <Box sx={{ my: 5, pt: 0 }}>
      <Typography variant="h4" align="justify" sx={{ m: 3, pt: 3, textAlign: 'center' }}>
        {t('itemSuccessfullyCreated')}
      </Typography>
      <Box sx={{ my: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <CheckIcon sx={{ fontSize: 80, textAlign: 'center' }} />
      </Box>

      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4}>
            {t('qrCodeId')}
          </Grid>
          <Grid item xs={8}>
            {qrcode}
          </Grid>
        </Grid>

        {/* <Box noValidate sx={{ mt: 2, mb: 3, pb: 2, width: '100%', justifyContent: 'center', display: 'flex', borderBottom: '1px solid' }}>
          Merchant
        </Box> */}
        <Grid container direction="row" item xs={12} sx={{ mt: 3 }}>
          <Grid item xs={4}>
            {t('item')}
          </Grid>
          <Grid item xs={8}>
            {insertedQrCodeData.productName}
          </Grid>
        </Grid>

        <Grid container direction="row" item xs={12} sx={{ mt: 3 }}>
          <Grid item xs={4}>
            {t('creatorMsg')}
          </Grid>
          <Grid item xs={8}>
            {insertedQrCodeData.userDesc}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4}>
            {t('creatorName')}
          </Grid>
          <Grid item xs={8}>
            {insertedQrCodeData.userFullname}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4}>
            {t('creatorEmail')}:
          </Grid>
          <Grid item xs={8}>
            {insertedQrCodeData.userEmail}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12} sx={{ mt: 3 }}>
          <Grid item xs={4}>
            {t('description')}:
          </Grid>
          <Grid item xs={8}>
            {insertedQrCodeData.longText}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4}>
            {t('hasStory')}:
          </Grid>
          <Grid item xs={8}>
            {(insertedQrCodeData.hasstory) ? 'true' : 'false'}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4}>
            {t('publicPrivateOption')}:
          </Grid>
          <Grid item xs={8}>
            {insertedQrCodeData.publicQrCode ? <PublicIcon color="primary" /> : <VpnLockIcon color="warning" />}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4}>
            {t('redeemStatus')}:
          </Grid>
          <Grid item xs={8}>
            {insertedQrCodeData.qrCodeRedeemStatus ? t('redeemStatusValueRedeemed') : t('free')}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12} sx={{ mt: 3 }}>
          <Grid item xs={4} display={insertedQrCodeData._nfts.length ? '' : 'none'}>
            {t('nftAssetId')}:
          </Grid>
          <Grid item xs={8} display={insertedQrCodeData._nfts.length ? '' : 'none'}>
            {insertedQrCodeData._nfts.length ?
              (
                <Link
                  variant="span"
                  sx={{ color: 'yellow' }}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${process.env.REACT_APP_TESTNET}${insertedQrCodeData._nfts[0].assetId}`}
                >
                  {insertedQrCodeData._nfts[0].assetId}
                </Link>)
              : ''}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4} display={insertedQrCodeData._nfts.length ? '' : 'none'}>
            {t('txId')}:
          </Grid>
          <Grid item xs={8} display={insertedQrCodeData._nfts.length ? '' : 'none'}>
            {insertedQrCodeData._nfts.length ?
              (
                <Link
                  variant="span"
                  sx={{ color: 'yellow' }}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${process.env.REACT_APP_TESTNET}${insertedQrCodeData._nfts[0].transactionId}`}
                >
                  {insertedQrCodeData._nfts[0].transactionId}
                </Link>)
              : ''}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12}>
          <Grid item xs={4} display={insertedQrCodeData._nfts.length ? '' : 'none'}>
            {t('nftLink')}:
          </Grid>
          <Grid item xs={8} display={insertedQrCodeData._nfts.length ? '' : 'none'}>
            {insertedQrCodeData._nfts.length ? (
              <Link
                variant="span"
                sx={{ color: 'yellow' }}
                target="_blank"
                rel="noreferrer noopener"
                href={`https://ipfs.io/ipfs/${insertedQrCodeData._nfts[0].cid}`}
              >
                https://ipfs.io/ipfs/${insertedQrCodeData._nfts[0].cid}
              </Link>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {/* <Grid container direction="row" item xs={12}>
          <Grid item xs={4}>
            Cost of Product / Service :
          </Grid>
          <Grid item xs={8}>
            { (insertedQrCodeData.costOfProduct) ? insertedQrCodeData.costOfProduct : ''}
          </Grid>
        </Grid> */}
        {insertedQrCodeData.contributorData ? <ContributorData t={t} insertedQrCodeData={insertedQrCodeData} /> : ''}
        {insertedQrCodeData._image.length ? walletPicture() : ''}
        {insertedQrCodeData.productVideo ? walletVideo() : ''}
      </Grid>

      <Box sx={{ mt: 5, mb: 3, pb: 2, width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1" align="justify" sx={{ mt: 3 }}>
          {t('youWillNeedToSendLink')}:
        </Typography>

        <Box sx={{ p: 5, my: 2, backgroundColor: '#fff', borderRadius: 5, color: '#000', border: '1px solid #fff' }}>
          <Typography variant="h6" align="center" sx={{ mt: 1, mb: 1 }}>
            <Link
              underline="none"
              href={linkCreated}
              color="primary.black"
              target="_blank"
            // onClick={() => {
            //   navigate(`/generate-contract/${qrcode}`);
            // }}
            //sx={{ my: 6, mx: 1.5 }}
            >
              {linkCreated}
            </Link>
          </Typography>
        </Box>
        <Box className='phoneWrapper' >
          <div style={{ position: 'relative' }}>
            {/* <QRCode className='QRCodeInPhone' value={fullWebQR} style={{ height: '200px', maxWidth: '100%', width: '200px', position: 'absolute' }} /> */}
            <QRCode className='QRCodeInPhone' value={`${process.env.REACT_APP_WEB_SITE}/generate-contract/${qrcode}`} style={{ height: '200px', maxWidth: '100%', width: '200px', position: 'absolute' }} />
            <img className='phoneImageClass' src={PhoneQRCode} alt=" Phone QR Code Logo" style={{ marginLeft: '10px', width: '600px', height: '600px' }} />
            <img className='blokariaLogoPhone' src={BlokariaLogo} alt="Blokaria Logo" style={{ marginLeft: '10px' }} />
            <Typography className='thisIsYourQRCode' sx={{ position: 'absolute' }}>This is your QR code</Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

const ContributorData = ({ insertedQrCodeData, t }) => {

  return (
    <Grid container direction="row" item xs={12}>
      <Grid item xs={4}>
        {t('contributor')}:
      </Grid>
      <Grid item xs={8}>
        {insertedQrCodeData.contributorData}
      </Grid>
    </Grid>
  );
};

export default QrCodeGeneratedSuccessfully;
