import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateUser } from '../../redux/user/actions';
import { clearSetToken } from '../../redux/token/actions';
import { clearState } from '../../redux/basic/actions';
import { objIsEmpty } from '../../common/commonFunctions';
import { useTranslation } from 'react-i18next';

function UserLogOut({ navigate }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.token);
  const { transactionUserData } = useSelector((state) => state.user);

  const stableDispatch = useCallback(dispatch, [dispatch]);

  const formLogOutUserDipatch = useCallback(() => {
    stableDispatch(clearStateUser());
    stableDispatch(clearSetToken());
    stableDispatch(clearState());
  }, [stableDispatch]);

  useEffect(() => {
    (!token && objIsEmpty(transactionUserData)) ? navigate('/') : '';
  });


  return (
    <div>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" align="center" sx={{ mt: 3, mb: 2 }}>
          {t('youAreLoggedin')}
        </Typography>
      </Box>

      <Box sx={{ mt: 5, textAlign: 'center' }}>
        <Typography align="center" variant="h5" sx={{ mt: 8, mb: 2 }}>
          {t('logoutBtnText')}
        </Typography>

        <Button
          onClick={() => {
            formLogOutUserDipatch();
          }}
          //fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2,backgroundColor: (theme) => theme.orangeButton.button }}>
          {t('logout')}
        </Button>
      </Box>
    </div>
  );
}

export default UserLogOut;
