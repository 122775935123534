import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import QrCodesListComponent from '../../components/QrCodesListComponent';

function QrCodesListPage() {
  return (
    <>
      <WrapperPage>
          <WrapperContainer>
            <QrCodesListComponent />
          </WrapperContainer>
      </WrapperPage>
    </>
  );
}

export default QrCodesListPage;


