import { takeEvery, call, put, fork } from 'redux-saga/effects';
import * as ActionTypes from './actions';
import Api from '../../api/calls';

function* getAllQrCodesByUser(action) {
  try {
    const result = yield call(Api.tree.getAllQrCodesByUser, action.payload);
    yield put(ActionTypes.getAllQrCodesByUserSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getAllQrCodesByUserError(error.response.data));
  }
}

function* getListQrCodesGeneral(action) {
  try {
    const result = yield call(Api.tree.getListQrCodesGeneral, action.payload);
    yield put(ActionTypes.getListQrCodesGeneralSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getListQrCodesGeneralError(error.response.data));
  }
}

function* getListQrCodesOwners(action) {
  try {
    const result = yield call(Api.tree.getListQrCodesOwners, action.payload);
    yield put(ActionTypes.getListQrCodesOwnersSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getListQrCodesOwnersError(error.response.data));
  }
}

function* deleteQrCode(action) {
  try {
    const result = yield call(Api.tree.deleteQrCode, action.payload);
    yield put(ActionTypes.deleteQrCodeSuccess(result.data));
    yield put(ActionTypes.getListQrCodesByUserPrivate(action.payload.token));
  } catch (error) {
    yield put(ActionTypes.deleteQrCodeError(error.response.data));
  }
}

function* listAllProject(action) {
  try {
    const result = yield call(Api.tree.listAllProject, action.payload);
    yield put(ActionTypes.listAllProjectSuccess(result.data._projects));
  } catch (error) {
    yield put(ActionTypes.listAllProjectError(error.response.data));
  }
}

function* createNewProject(action) {
  try {
    const result = yield call(Api.tree.createNewProject, action.payload);
    yield put(ActionTypes.createNewProjectSuccess(result));

    let actionPayload = {
      payload: action.payload.token
    };

    yield fork(listAllProject, actionPayload);
  } catch (error) {
    yield put(ActionTypes.createNewProjectError(error.response.data));
  }
}

function* addQrCodeToProject(action) {
  try {
    const result = yield call(Api.tree.addQrCodeToProject, action.payload);
    yield put(ActionTypes.addQrCodeToProjectSuccess(result.data._projects));
    let token = action.payload.token;
    let generated = action.payload.generated;
    yield put(ActionTypes.getListQrCodesByUserPrivate(token, generated));
  } catch (error) {
    yield put(ActionTypes.addQrCodeToProjectError(error.response.data));
  }
}

function* deleteProject(action) {
  try {
    const result = yield call(Api.tree.deleteProject, action.payload);
    yield put(ActionTypes.deleteProjectSuccess(result.data._projects));

    let actionPayload = {
      payload: action.payload.token
    };

    yield fork(listAllProject, actionPayload);

  } catch (error) {
    yield put(ActionTypes.deleteProjectError(error.response.data));
  }
}
function* updateProject(action) {
  try {
    const result = yield call(Api.tree.updateProject, action.payload);
    yield put(ActionTypes.addQrCodeToProjectSuccess(result.data._projects));

    let actionPayload = {
      payload: action.payload.token
    };

    yield fork(listAllProject, actionPayload);

  } catch (error) {
    yield put(ActionTypes.addQrCodeToProjectError(error.response.data));
  }
}

function* getOneProject(action) {
  try {
    const result = yield call(Api.tree.getOneProject, action.payload);
    yield put(ActionTypes.getOneProjectSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getOneProjectError(error.response.data));
  }
}

function* changeStatusOfQrCode(action) {
  try {
    const result = yield call(Api.tree.changeStatusOfQrCode, action.payload);
    yield put(ActionTypes.changeStatusOfQrCodeSuccess(result.data));
    let token = action.payload.token;
    let generated = action.forward.generated;
    yield put(ActionTypes.getListQrCodesByUserPrivate(token, generated));
  } catch (error) {
    yield put(ActionTypes.changeStatusOfQrCodeError(error.response.data));
  }
}

function* listAllProjectNrApi(action) {
  try {
    const result = yield call(Api.tree.listAllProjectNrApi, action.payload);
    yield put(ActionTypes.listAllProjectSuccess(result.data._user._projects));
  } catch (error) {
    yield put(ActionTypes.listAllProjectError(error.response.data));
  }
}


function* getListQrCodesByUserPrivate(action) {
  try {
    const result = yield call(Api.tree.getListQrCodesByUserPrivate, action.payload);
    yield put(ActionTypes.getListQrCodesByUserPrivateSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getListQrCodesByUserPrivateError(error.response.data));
  }
}

function* getListQrCodesByUser(action) {
  console.log('NE KORISTI SE');
  try {
    const result = yield call(Api.tree.getListQrCodesByUser, action.payload);
    yield put(ActionTypes.getListQrCodesByUserSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getListQrCodesByUserError(error.response.data));
  }
}
function* updateOneElementInDb(action) {
  try {
    const result = yield call(Api.tree.updateListDataInDb, action.payload);
    yield put(ActionTypes.defaultCreateSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.defaultCreateError(error.response.data));
  }
}

export default function* userSaga() {
  yield takeEvery(ActionTypes.GET_ALL_QR_CODES_BY_USER, getAllQrCodesByUser);
  yield takeEvery(ActionTypes.GET_LIST_QR_CODES_GENERAL, getListQrCodesGeneral);
  yield takeEvery(ActionTypes.GET_LIST_QR_CODES_OWNERS, getListQrCodesOwners);
  yield takeEvery(ActionTypes.GET_LIST_QR_CODES_BY_USER, getListQrCodesByUser);
  yield takeEvery(ActionTypes.DELETE_QR_CODE, deleteQrCode);
  yield takeEvery(ActionTypes.CREATE_NEW_PROJECT, createNewProject);
  yield takeEvery(ActionTypes.LIST_ALL_PROJECTS, listAllProject);
  yield takeEvery(ActionTypes.ADD_QR_CODE_TO_PROJECT, addQrCodeToProject);
  yield takeEvery(ActionTypes.DELETE_PROJECT, deleteProject);
  yield takeEvery(ActionTypes.UPDATE_PROJECT, updateProject);
  yield takeEvery(ActionTypes.GET_ONE_PROJECT, getOneProject);
  yield takeEvery(ActionTypes.CHANGE_STATUS_QR_CODE, changeStatusOfQrCode);
  yield takeEvery(ActionTypes.LIST_ALL_PROJECTS_NR_API, listAllProjectNrApi);
  yield takeEvery(ActionTypes.GET_LIST_QR_CODES_BY_USER_PRIVATE, getListQrCodesByUserPrivate);
  yield takeEvery(ActionTypes.UPDATE_ONE_ELEMENT_IN_DB, updateOneElementInDb);
}
