import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid, Box, Link } from '@mui/material';
import '../../css/general.css';

let arrayImages1 = [
    { number: '1.', text: 'Primer veza', imageLink: 'keep_it_real.jpg' },
    { number: '2.', text: 'Predlog kljucnih reci dizajna po kojoj treba da se napravi vez', imageLink: 'NFC_TAG.jpg' },
    { number: '3.', text: 'Dimenzija su D30mm - naknadno javljam tacne dimenzije', imageLink: '' },
    { number: '4.', text: 'Kod ovog veza, NFC tag se nalazi izmedju veza i majice. Dimenzije NFC taga su D25mm pa sam zato stavio D30mm za vez', imageLink: 'NFT_TAG_Model.jpg' }
];
let arrayImages2 = [
    { number: '1.', text: 'Predlog veza', imageLink: 'Vez_Pariz.jpg' },
    { number: '2.', text: 'Dizjan po kojoj treba da se napravi vez - Ostavljam dizajneru', imageLink: '' },
    { number: '3.', text: 'Predlog dimenzija 100x40mm', imageLink: '' }
];

let arrayImages3 = [
    { number: '1.', text: 'Dimeznije printa - predlog 10x10cm - mogu i druge dimenzije', imageLink: '' },
    { number: '2.', text: 'Predlog dizajna - u ovom delu treba biti sto kreativniji - crtezi, motivi, slike', imageLink: '' },
    { number: '3.', text: 'Svaki print mora da ima QR code dovoljnih dimenzija da moze da se skeniraSve ostalo je opciono', imageLink: '' },
    { number: '4.', text: 'Rezolucija printa na majici je 300DPI', imageLink: '' }
];


function LabelDesingRequirementsView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>

            <Box className="" sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{ mb: 2 }}>
                    Dizajn zahtevi
                </Typography>

                <Box sx={{ backgroundColor: '#323233', mb: 2, p: 2 }}>
                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        Potrebno je da mi se izdradi dizajn za dve etikete koje idu na majicu sivene zakard tehnikom*,
                        kao i dizajn za print koji ide na unutrsnju stranu majice kod vrata.
                    </Typography>
                    <Typography variant="body" align="left" sx={{ m: 4 }}>
                        *to su ušivne etikete, tkane na mašinskom razboju, od poliester konca, u traci ili sečene na komad.
                    </Typography>

                    <Grid container direction="row" justifyContent="space-around" alignItems="top"
                        sx={{ my: 1, p: 1, border: '1px solid', color: 'white' }}>
                        <Grid item xs={9} md={9} className='tableGridAddProject'>
                            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                                Inicijelna intro slika sa svim detaljima i pozicijama
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Link
                                target="_blank"
                                rel="noreferrer noopener"
                                href={'/static/labelsdesing/Blokaria_majice.jpg'}
                                underline="none"
                                color="info.light"
                                sx={{ my: 1 }}
                            >
                                <img src="/static/labelsdesing/Blokaria_majice.jpg" width="30%" />
                            </Link>
                        </Grid>
                    </Grid>

                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        <Link
                            target="_blank"
                            rel="noreferrer noopener"
                            href={'https://drive.google.com/file/d/1ila4jgZSTNViKlDBShkovwvQQLSWigqy/view?usp=share_link'}
                            //underline="none"
                            color="info.light"
                            sx={{ my: 1, color: 'yellow' }}
                        >
                            Video link ka prezentaciji
                        </Link>
                    </Typography>

                </Box>

                <Box sx={{ mt: 5 }}>
                    <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                        1. Vez - NFC u donjem desnom uglu majice
                    </Typography>
                    {
                        arrayImages1.map((item) => {
                            return gridView({ number: item.number, text: item.text, imageLink: item.imageLink });
                        })
                    }
                </Box>

                <Box sx={{ mt: 5 }}>
                    <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                        2. Vez na unutrasnjoj strani majice - desno dole
                    </Typography>
                    {
                        arrayImages2.map((item) => {
                            return gridView({ number: item.number, text: item.text, imageLink: item.imageLink });
                        })
                    }
                </Box>
                <Box sx={{ mt: 5 }}>
                    <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                        3. Print na unutrasnjoj strani majice - kod vrata
                    </Typography>
                    {
                        arrayImages3.map((item) => {
                            return gridView({ number: item.number, text: item.text, imageLink: item.imageLink });
                        })
                    }
                </Box>

                <Typography variant="h6" align="left" sx={{ m: 4 }}>
                    Ponudu dostavite na nemanjamil@gmail.com
                </Typography>


            </Box>
        </Container>
    );
}

const gridView = ({ number = '', text, imageLink }) => {

    return (<Grid container direction="row" justifyContent="space-around" alignItems="top"
        sx={{ my: 1, p: 1, border: '1px solid gray', color: 'white' }}>
        <Grid item xs={9} md={9} className='tableGridAddProject'>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                {number} {text}
            </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
            {imageLink ?
                <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`${imageLink}`}
                    underline="none"
                    color="info.light"
                    sx={{ my: 1 }}
                >
                    <img src={`/static/labelsdesing/${imageLink}`} width="30%" />
                </Link>
                : ''}
        </Grid>
    </Grid>);

};

export default LabelDesingRequirementsView;