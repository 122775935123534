import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LinkHref from '../../components/LinkHref';
import { useTranslation } from 'react-i18next';

function PlantingTreeMainPage() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' }, color: 'black' }} />
      <CssBaseline />

      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowWrap: 'break-word'
        }}
      >
        <LinkHref to="/tree-list" sx={{ mt: 1 }}>
          <img src="/static/qrcodeimage.png" style={{ marginTop: '19px' }} height="170px" width="159.4px" />
        </LinkHref>
      </Box>


      <Container id='plantingTreeMainpageHeight' maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h5" align="center" color="black !important" sx={{ my: 3, fontWeight: 600 }}>
          {t('tier_2_homepage.sent1')}
        </Typography>
        <Typography variant="h5" align="center" color="black !important" sx={{ my: 3 }}>
          {t('tier_2_homepage.sent2')}
        </Typography>
        <Typography id='typographyHeight' variant="body1" align="center" color="black !important" sx={{ mb: 2 }}>
          {t('tier_2_homepage.sent3')}
        </Typography>
      </Container>

      <Container maxWidth="md">
        <Grid container spacing={3} alignItems="flex-end" marginTop="2.5em" justifyContent="center">
          <Grid item xs={12} md={8}>
            <Button
              fullWidth
              onClick={() => {
                navigate('/tree-list');
              }}
              sx={{ backgroundColor: (theme) => theme.orangeButton.button }}
            // variant={tier.buttonVariant}
            >
              {t('explore-offer')}
              {/* {tier.buttonText} */}
            </Button>
          </Grid>
        </Grid>
      </Container>

    </React.Fragment>
  );

}

export default PlantingTreeMainPage;