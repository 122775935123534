export const loadState = () => {
  try {

    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    let serializedStateJson = JSON.parse(serializedState);

    const now = new Date();
    console.log('AAA now.getTime()', now.getTime());
    console.log('AAA serializedStateJson.expiry', serializedStateJson.expiry);

    if (now.getTime() > serializedStateJson.expiry) {

      console.log('now.getTime()', now.getTime());
      console.log('serializedStateJson.expiry', serializedStateJson.expiry);

      localStorage.removeItem('state');

      console.log('Cookie Session Expired');

      return undefined;
    }

    return {
      basic: serializedStateJson.basic,
      user: serializedStateJson.user,
      token: serializedStateJson.token,
      tree: serializedStateJson.tree
    };
  } catch (error) {
    return undefined;
  }
};
// https://dev.to/sababg/set-expiry-time-ttl-for-localstorage-with-javascript-432l
export const saveState = (state) => {
  const now = new Date();
  try {
    let serializedStateJson = JSON.stringify({
      basic: state.basic,
      user: state.user,
      token: state.token,
      tree: state.tree,
      expiry: now.getTime() + (120 * 60 * 1000)
    });

    localStorage.setItem('state', serializedStateJson);
  } catch (error) {
    console.log('Failed to save state!');
  }
};
