import React from 'react';
import AppBarResponsive from '../AppBarResponsive';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';


function Copyright(props) {
  return (
    <>
      <div style={{ paddingTop: '1em' }}>
        <Typography variant="body2" sx={{ color: 'white !important' }} align="center" {...props}>
          {'Copyright © '}
          <Link sx={{ color: 'white !important' }} target="_blank" rel="noreferrer noopener" href="https://blokaria.com/">
            Blokaria
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </div>
    </>

  );
}

const WrapperPage = (props) => {
  const { t } = useTranslation();

  const footers = [
    {
      title: 'Company',
      description: [
        //{ title: 'Home', link: '/' },
        //{ title: 'Generate QR CODE', link: '/generate-qr-code' },
        //{ title: 'Register', link: '/reg' },
        { title: `${t('latest-qr-codes')}`, link: '/latest-generated-qr-codes' }
      ]
    },
    {
      title: 'Product',
      description: [
        { title: `${t('what-is-blokaria')}`, link: '/doc/use-case-general' }
        // { title: 'Faq', link: '/doc/faq' }
        // { title: 'Use Case Unique Product', link: '/doc/use-case-product' },
        // { title: 'Use Case Planting Tree', link: '/doc/use-case-planting-tree' }
      ]
    },
    {
      title: 'Resources',
      description: [
        //{ title: 'About BlockChain', link: 'https://cardano.org/', href: 'https://cardano.org/' }
        { title: `${t('home')}`, link: '/' }
      ]
    },
    {
      title: 'Info',
      description: [{ title: `${t('about-us')}`, link: '/about-us' }]
    }
  ];
  //let navigate = useNavigate();
  return (
    <React.Fragment>
      <AppBarResponsive />
      {props.children}

      <Container
        maxWidth="false"
        className='footerHeight'
        sx={{
          background: (theme) => theme.palette.headerFooter.footer,
          mt: 1,
          py: 2,
          height: '8rem'
        }}
      >
        <CssBaseline />

        <Container maxWidth="lg" component="footer">
          <Grid container spacing={4} justifyContent="center">
            {footers.map((footer) => (
              <Grid item xs={12} sm={2} key={footer.title}>
                <List dence="true">
                  {footer.description.map((item) => (
                    <ListItem key={item.link} sx={{ p: 0 }}>
                      <ListItemText sx={{ p: 0, m: 0 }}>
                        <Link
                          //href="" //{ (item.href) ? item.href : '#'}
                          //to=
                          to={{
                            pathname: item.link
                            //search: "?sort=name",
                            //hash: "#the-hash",
                            //state: { fromDashboard: true }
                          }}
                          component={RouterLink}
                          underline="none"
                          target={item.href ? '_blank' : ''}
                          rel={item.href ? 'noreferrer noopener' : ''}
                          variant="subtitle1"
                          color="info.dark"
                        >
                          {item.title}
                        </Link>

                      </ListItemText>
                    </ListItem>
                  )

                  )}
                </List>
              </Grid>
            ))}
            <Grid item xs={12} sm={2}>
              <List dence="true">
                {(process.env.REACT_APP_ENV === 'DEV') ? (<Link target="_blank" underline="none" variant="subtitle1" color="info.dark" rel="noreferrer noopener" href='https://blokaria.com/'>
                  Blokaria Mainnet
                </Link>) :
                  (<Link target="_blank" underline="none" variant="subtitle1" color="info.dark" rel="noreferrer noopener" href='https://testnet.blokaria.com/'>
                    Blokaria Testnet
                  </Link>)
                }
              </List>
            </Grid>
          </Grid>
          <Copyright sx={{ mt: 0 }} />
        </Container>
      </Container>
    </React.Fragment >
  );
};

export default WrapperPage;
