import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';



function FaqView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ mt: 5, minHeight: '70vh' }}>
            <Typography variant="h5" align="center" sx={{ mt: 3, mb: 2 }}>
            Najčešća pitanja
            </Typography>
        </Container>
    );
}

export default FaqView;