import React, { useEffect, useCallback } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQrCodeFromId, clearState } from '../../redux/basic/actions';
import Container from '@mui/material/Container';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';
import { objHasValues } from '../../common/commonFunctions';

function RedirectStatusPage() {

    const { idcode } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { insertedQrCodeData, errorMessage, internalErrorCode, loading, loaded } = useSelector((state) => state.basic);

    const dispatchCheckExistingQrCode = useCallback(
        (idcode) => {
            dispatch(clearState());
            dispatch(getQrCodeFromId(idcode));
        }
    );

    useEffect(() => {
        dispatchCheckExistingQrCode(idcode);
    }, []);

    useEffect(() => {
        if (insertedQrCodeData.walletQrId) {
            navigate(`/status/${insertedQrCodeData.walletQrId}`);
        }
    }, [insertedQrCodeData]);

    let qrCodeView;

    switch (true) {
        case loading:
            qrCodeView = <ShowLoader />;
            break;
        case errorMessage !== '' && !loaded:
            qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
            break;
        case (loaded && insertedQrCodeData && objHasValues(insertedQrCodeData)):
            qrCodeView = <ShowLoader />;
            break;
        default:
            qrCodeView = <ShowLoader color="secondary" />;
            break;
    }


    return (
        <WrapperPage>
            <WrapperContainer>
                <Container component="main" maxWidth="sm">
                    {qrCodeView}
                </Container>
            </WrapperContainer>
        </WrapperPage>
    );
}

export default RedirectStatusPage;
