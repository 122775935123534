import axios from 'axios';

export const makePostRequest = (route, token, data) => {

  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
      //'x-functions-key': 'asdads'
    },
    data,
    url: `${process.env.REACT_APP_MOLECULER_SERVICE}/${route}`
  };

  return axios(options);
};

export const makeGetRequest = (route, token) => {
  const options = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    url: `${process.env.REACT_APP_MOLECULER_SERVICE}/${route}`
  };

  return axios(options);
};

const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

export const makePostFileRequest = (route, token, data) => {
  let generatedData = getFormData(data);
  const options = {
    method: 'post',
    url: `${process.env.REACT_APP_MOLECULER_SERVICE}/${route}`,
    data: generatedData,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${generatedData._boundary}`,
      Authorization: `Bearer ${token}`
    }
  };
  return axios(options);
};
