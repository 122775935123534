// ----------------------------- 0 ---------------------------
export const CLEAR_STATE_LIST_QR_CODES_BY_USER = 'tree/CLEAR_STATE_LIST_QR_CODES_BY_USER';
export const clearStateListQrCodesByUser = () => ({
  type: CLEAR_STATE_LIST_QR_CODES_BY_USER
});





// ------------------  5 getListQrCodesByUser  PUBLIC ----------------------------
export const GET_LIST_QR_CODES_BY_USER = 'tree/GET_LIST_QR_CODES_BY_USER';
export const GET_LIST_QR_CODES_BY_USER_SUCCESS = 'tree/GET_LIST_QR_CODES_BY_USER_SUCCESS';
export const GET_LIST_QR_CODES_BY_USER_ERROR = 'tree/GET_LIST_QR_CODES_BY_USER_ERROR';

export const getListQrCodesByUser = (token) => {
  return {
    type: GET_LIST_QR_CODES_BY_USER,
    payload: {
      token
    }
  };
};

export const getListQrCodesByUserSuccess = (data) => {
  return {
    type: GET_LIST_QR_CODES_BY_USER_SUCCESS,
    payload: data
  };
};

export const getListQrCodesByUserError = (error) => {
  return {
    type: GET_LIST_QR_CODES_BY_USER_ERROR,
    payload: error
  };
};

// ----------------------------- 1 LIST USER WHO OWNES QR CODES -------------------------------
export const GET_ALL_QR_CODES_BY_USER = 'tree/GET_ALL_QR_CODES_BY_USER';
export const GET_ALL_QR_CODES_BY_USER_SUCCESS = 'tree/GET_ALL_QR_CODES_BY_USER_SUCCESS';
export const GET_ALL_QR_CODES_BY_USER_ERROR = 'tree/GET_ALL_QR_CODES_BY_USER_ERROR';

export const getAllQrCodesByUser = (userEmail) => {
  return {
    type: GET_ALL_QR_CODES_BY_USER,
    payload: userEmail
  };
};

export const getAllQrCodesByUserSuccess = (data) => {
  return {
    type: GET_ALL_QR_CODES_BY_USER_SUCCESS,
    payload: data
  };
};

export const getAllQrCodesByUserError = (error) => {
  return {
    type: GET_ALL_QR_CODES_BY_USER_ERROR,
    payload: error
  };
};

// ----------------------------- 2  GENERAL -------------------------------
export const GET_LIST_QR_CODES_GENERAL = 'tree/GET_LIST_QR_CODES_GENERAL';
export const GET_LIST_QR_CODES_GENERAL_SUCCESS = 'tree/GET_LIST_QR_CODES_GENERAL_SUCCESS';
export const GET_LIST_QR_CODES_GENERAL_ERROR = 'tree/GET_LIST_QR_CODES_GENERAL_ERROR';

export const getListQrCodesGeneral = ({ limit, skip }) => {
  return {
    type: GET_LIST_QR_CODES_GENERAL,
    payload: {
      limit,
      skip
    }
  };
};

export const getListQrCodesGeneralSuccess = (data) => {
  return {
    type: GET_LIST_QR_CODES_GENERAL_SUCCESS,
    payload: data
  };
};

export const getListQrCodesGeneralError = (error) => {
  return {
    type: GET_LIST_QR_CODES_GENERAL_ERROR,
    payload: error
  };
};

// ----------------------------- 3  GENERAL -------------------------------
export const GET_LIST_QR_CODES_OWNERS = 'tree/GET_LIST_QR_CODES_OWNERS';
export const GET_LIST_QR_CODES_OWNERS_SUCCESS = 'tree/GET_LIST_QR_CODES_OWNERS_SUCCESS';
export const GET_LIST_QR_CODES_OWNERS_ERROR = 'tree/GET_LIST_QR_CODES_OWNERS_ERROR';

export const getListQrCodesOwners = () => {
  return {
    type: GET_LIST_QR_CODES_OWNERS
  };
};

export const getListQrCodesOwnersSuccess = (data) => {
  return {
    type: GET_LIST_QR_CODES_OWNERS_SUCCESS,
    payload: data
  };
};

export const getListQrCodesOwnersError = (error) => {
  return {
    type: GET_LIST_QR_CODES_OWNERS_ERROR,
    payload: error
  };
};

// ------------------  5 getListQrCodesByUser  PRIVATE  ----------------------------
export const GET_LIST_QR_CODES_BY_USER_PRIVATE = 'tree/GET_LIST_QR_CODES_BY_USER_PRIVATE';
export const GET_LIST_QR_CODES_BY_USER_PRIVATE_SUCCESS = 'tree/GET_LIST_QR_CODES_BY_USER_PRIVATE_SUCCESS';
export const GET_LIST_QR_CODES_BY_USER_PRIVATE_ERROR = 'tree/GET_LIST_QR_CODES_BY_USER_PRIVATE_ERROR';

export const getListQrCodesByUserPrivate = (token, generated) => {
  return {
    type: GET_LIST_QR_CODES_BY_USER_PRIVATE,
    payload: {
      token,
      generated
    }
  };
};

export const getListQrCodesByUserPrivateSuccess = (data) => {
  return {
    type: GET_LIST_QR_CODES_BY_USER_PRIVATE_SUCCESS,
    payload: data
  };
};

export const getListQrCodesByUserPrivateError = (error) => {
  return {
    type: GET_LIST_QR_CODES_BY_USER_PRIVATE_ERROR,
    payload: error
  };
};


// ----------------------------- 4  DELETE QR CODE -------------------------------
export const DELETE_QR_CODE = 'tree/DELETE_QR_CODE';
export const DELETE_QR_CODE_SUCCESS = 'tree/DELETE_QR_CODE_SUCCESS';
export const DELETE_QR_CODE_ERROR = 'tree/DELETE_QR_CODE_ERROR';

export const deleteQrCode = (walletQrId, token) => {
  return {
    type: DELETE_QR_CODE,
    payload: {
      walletQrId,
      token
    }
  };
};

export const deleteQrCodeSuccess = (data) => {
  return {
    type: DELETE_QR_CODE_SUCCESS,
    payload: {
      walletQrId: data
    }
  };
};

export const deleteQrCodeError = (error) => {
  return {
    type: DELETE_QR_CODE_ERROR,
    payload: error
  };
};
// ----------------------------- 5  CREATE NEW PROJECT -------------------------------
export const CREATE_NEW_PROJECT = 'tree/CREATE_NEW_PROJECT';
export const CREATE_NEW_PROJECT_SUCCESS = 'tree/CREATE_NEW_PROJECT_SUCCESS';
export const CREATE_NEW_PROJECT_ERROR = 'tree/CREATE_NEW_PROJECT_ERROR';

export const createNewProject = ({ dataForm, token }) => {
  return {
    type: CREATE_NEW_PROJECT,
    payload: {
      data: dataForm,
      token
    }
  };
};

export const createNewProjectSuccess = (data) => {
  return {
    type: CREATE_NEW_PROJECT_SUCCESS,
    payload: data
  };
};

export const createNewProjectError = (error) => {
  return {
    type: CREATE_NEW_PROJECT_ERROR,
    payload: error
  };
};


// ----------------------------- 6  LIST ALL PROJECTS  -------------------------------
export const LIST_ALL_PROJECTS = 'tree/LIST_ALL_PROJECTS';
export const LIST_ALL_PROJECTS_SUCCESS = 'tree/LIST_ALL_PROJECTS_SUCCESS';
export const LIST_ALL_PROJECTS_ERROR = 'tree/LIST_ALL_PROJECTS_ERROR';

export const listAllProject = (token) => {
  return {
    type: LIST_ALL_PROJECTS,
    payload: token
  };
};


export const listAllProjectSuccess = (data) => {
  return {
    type: LIST_ALL_PROJECTS_SUCCESS,
    payload: data
  };
};

export const listAllProjectError = (error) => {
  return {
    type: LIST_ALL_PROJECTS_ERROR,
    payload: error
  };
};

// ----------------------------- 6.a  LIST ALL PROJECTS NR API -------------------------------

export const LIST_ALL_PROJECTS_NR_API = 'tree/LIST_ALL_PROJECTS_NR_API';
export const listAllProjectNrApi = (idcode) => {
  return {
    type: LIST_ALL_PROJECTS_NR_API,
    payload: { idcode }
  };
};


// ----------------------------- 7  ADD QR CODE TO PROJECT  -------------------------------
export const ADD_QR_CODE_TO_PROJECT = 'tree/ADD_QR_CODE_TO_PROJECT';
export const ADD_QR_CODE_TO_PROJECT_SUCCESS = 'tree/ADD_QR_CODE_TO_PROJECT_SUCCESS';
export const ADD_QR_CODE_TO_PROJECT_ERROR = 'tree/ADD_QR_CODE_TO_PROJECT_ERROR';

export const addQrCodeToProject = (itemId, projectId, token, generated = false) => {
  return {
    type: ADD_QR_CODE_TO_PROJECT,
    payload: {
      token,
      itemId,
      projectId,
      generated
    }
  };
};


export const addQrCodeToProjectSuccess = (data) => {
  return {
    type: ADD_QR_CODE_TO_PROJECT_SUCCESS,
    payload: data
  };
};

export const addQrCodeToProjectError = (error) => {
  return {
    type: ADD_QR_CODE_TO_PROJECT_ERROR,
    payload: error
  };
};


// ----------------------------- 8  DELETE PROJECT  -------------------------------
export const DELETE_PROJECT = 'tree/DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'tree/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'tree/DELETE_PROJECT_ERROR';

export const deleteProject = ({ projectId, token }) => {
  return {
    type: DELETE_PROJECT,
    payload: {
      token,
      projectId
    }
  };
};


export const deleteProjectSuccess = (data) => {
  return {
    type: DELETE_PROJECT_SUCCESS,
    payload: data
  };
};

export const deleteProjectError = (error) => {
  return {
    type: DELETE_PROJECT_ERROR,
    payload: error
  };
};

// ----------------------------- 8  UPDATE PROJECT  -------------------------------

export const UPDATE_PROJECT = 'tree/UPDATE_PROJECT';

export const updateProject = ({ projectData, projectId, token }) => {
  return {
    type: UPDATE_PROJECT,
    payload: {
      projectData, projectId, token
    }
  };
};


// ----------------------------- 8  GET ONE PROJECT  -------------------------------
export const GET_ONE_PROJECT = 'tree/GET_ONE_PROJECT';
export const GET_ONE_PROJECT_SUCCESS = 'tree/GET_ONE_PROJECT_SUCCESS';
export const GET_ONE_PROJECT_ERROR = 'tree/GET_ONE_PROJECT_ERROR';

export const getOneProject = ({ projectId, token }) => {

  return {
    type: GET_ONE_PROJECT,
    payload: {
      projectId,
      token
    }
  };
};

export const getOneProjectSuccess = (data) => {
  return {
    type: GET_ONE_PROJECT_SUCCESS,
    payload: data
  };
};

export const getOneProjectError = (error) => {
  return {
    type: GET_ONE_PROJECT_ERROR,
    payload: error
  };
};

// ----------------------------- 9  UPDATE STATUS OF QR CODE  -------------------------------
export const CHANGE_STATUS_QR_CODE = 'tree/CHANGE_STATUS_QR_CODE';
export const CHANGE_STATUS_QR_CODE_SUCCESS = 'tree/CHANGE_STATUS_QR_CODE_SUCCESS';
export const CHANGE_STATUS_QR_CODE_ERROR = 'tree/CHANGE_STATUS_QR_CODE_ERROR';

export const changeStatusOfQrCode = (qrcode, status, generated, token) => {
  return {
    type: CHANGE_STATUS_QR_CODE,
    payload: {
      qrcode, status, token
    },
    forward: {
      generated
    }
  };
};

export const changeStatusOfQrCodeSuccess = (data) => {
  return {
    type: CHANGE_STATUS_QR_CODE_SUCCESS,
    payload: data
  };
};

export const changeStatusOfQrCodeError = (error) => {
  return {
    type: CHANGE_STATUS_QR_CODE_ERROR,
    payload: error
  };
};


// ----------------------------- 10  UPDATE LIST OF QR CODES   -------------------------------
export const UPDATE_LIST_OF_QR_CODES = 'tree/UPDATE_LIST_OF_QR_CODES';

export const updateListQrCodesOnlyRedux = (newListOfQrCodes) => {
  return {
    type: UPDATE_LIST_OF_QR_CODES,
    payload: {
      newListOfQrCodes
    }
  };
};
// ----------------------------- 10  UPDATE LIST OF QR CODES   -------------------------------
export const UPDATE_ONE_ELEMENT_IN_DB = 'tree/UPDATE_ONE_ELEMENT_IN_DB';

export const updateOneElementInDb = (token, qrCode, state) => {

  return {
    type: UPDATE_ONE_ELEMENT_IN_DB,
    payload: {
      token, qrCode, state
    }
  };
};

export const DEFAULT_CREATE_SUCCESS = 'tree/DEFAULT_CREATE_SUCCESS';
export const DEFAULT_CREATE_ERROR = 'tree/DEFAULT_CREATE_ERROR';

export const defaultCreateSuccess = (data) => {
  return {
    type: DEFAULT_CREATE_SUCCESS,
    payload: data
  };
};

export const defaultCreateError = (error) => {
  return {
    type: DEFAULT_CREATE_ERROR,
    payload: error
  };
};