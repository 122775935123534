import React, { useCallback, useEffect, useState } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import GenerateQrCodeWelcome from '../../view/GenerateQrCodeWelcome';
import GenerateQrCodeForm from '../../components/GenerateQrCodeForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearState } from '../../redux/basic/actions';
import { getUserData, clearStateUser } from '../../redux/user/actions';
import ShowError from '../../view/ShowError';
import SnackBarView from '../../view/SnackBarView';
import ShowLoader from '../../view/ShowLoader';
import { objHasValues, objIsEmpty } from '../../common/commonFunctions';

function GenerateQrCode() {
  const [newForm, setNewForm] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const { errorMessage, internalErrorCode, walletQrId, loading: loadingImage, loaded: loadedImage } = useSelector((state) => state.basic);
  const { loading, loaded, transactionUserData, errorMessage: userErrorMessage, internalErrorCode: userInternalErrorCode } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.token);

  const dispatchToClearRedux = useCallback(
    (userEmail, token) => {
      stableDispatch(clearState());
      userEmail && token ? stableDispatch(getUserData(userEmail, token)) : stableDispatch(clearStateUser());
      setNewForm(true);
    },
    [stableDispatch]
  );

  let userEmail = transactionUserData?.userEmail;
  useEffect(() => {
    dispatchToClearRedux(userEmail, token);
  }, [dispatchToClearRedux]);

  useEffect(() => {
    if (newForm && loadedImage && walletQrId) {
      navigate(`/generated-qrcode/${walletQrId}`);
    }
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (errorMessage !== '' && !loadedImage) {
      open ? '' : setOpen(true);
    }
  }, [errorMessage, loadedImage]);

  let generateQrCodeComponentShow = '';

  switch (true) {
    case loading || loadingImage:
      generateQrCodeComponentShow = <ShowLoader />;
      break;
    case userErrorMessage && !loaded:
      generateQrCodeComponentShow = <ShowError errorMessage={errorMessage} internalErrorCode={userInternalErrorCode} />;
      break;
    case objIsEmpty(transactionUserData):
      generateQrCodeComponentShow = <ShowError errorMessage="Molimo vas da se ulogujete" internalErrorCode="BlaBla" />;
      break;
    case loaded && objHasValues(transactionUserData):
      generateQrCodeComponentShow = <GenerateQrCodeForm navigate={navigate} />;
      break;
    default:
      generateQrCodeComponentShow = <ShowLoader color="warning" />;
      break;
  }

  return (
    <>
      <div>
        <WrapperPage>
          <WrapperContainer>
            <div className='generateQrCodeWellcome'>
              <GenerateQrCodeWelcome />
              {generateQrCodeComponentShow}
              <SnackBarView handleClose={handleClose} errorMessage={errorMessage} internalErrorCode={internalErrorCode} openSnackStatus={open} type="error" />
            </div>
          </WrapperContainer>
        </WrapperPage>
      </div>
    </>
  );
}

export default GenerateQrCode;
