import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LinkHref from '../LinkHref';
import { objHasValues, objIsEmpty } from '../../common/commonFunctions';
import '../../css/general.css';
import { useTranslation } from 'react-i18next';


const lngs = {
  en: { nativeName: 'English' },
  sr: { nativeName: 'Serbian' }
};


const AppBarResponsive = () => {
  const { i18n, t } = useTranslation();

  const settings = [
    { name: `${t('profile')}`, link: 'user', loggedUser: true },
    { name: `${t('generated-qr-codes')}`, link: 'list-qr-codes', loggedUser: true },
    { name: `${t('qrcodes-owned-by-me')}`, link: 'list-owned-qr-codes', loggedUser: true },
    { name: `${t('my-projects')}`, link: 'my-projects', loggedUser: true },
    { name: `${t('registration')}`, link: 'register-user', loggedUser: false },
    { name: `${t('change-password')}`, link: 'lost-pass', loggedUser: false }
  ];

  const pages = [
    { name: 'home', link: '', nameValue: `${t('home')}` },
    { name: 'lastest-qr-codes', link: 'latest-generated-qr-codes', nameValue: `${t('latest-qr-codes')}` },
    { name: 'qrcodes-creators', link: 'tree-list', nameValue: `${t('qr-code-creators')}` },
    { name: 'faq', link: 'doc/use-case-general', nameValue: `${t('faq')}` },
    { name: 'about-us', link: 'about-us', nameValue: ` ${t('about-us')}` }
  ];


  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const { token } = useSelector((state) => state.token);
  const { transactionUserData } = useSelector((state) => state.user);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar position="static" sx={{
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      background: (theme) => theme.palette.headerFooter.both
    
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          {/* <Typography variant="h6" color="inherit" noWrap sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}> */}

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="info"
            >
              <MenuIcon className='triCrte' fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'block' }
              }}
            >
              {pages.map((page) => (

                <MenuItem key={page.name}
                  onClick={() => {
                    navigate(`/${page.link}`);
                    handleCloseNavMenu;
                  }}>
                  <Typography textAlign="left" variant="button">
                    {page.nameValue}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            <LinkHref to="/">
              <img src="/BlokariaLogoWhite.png" height="40px" />
            </LinkHref>

            {/* <Link
              underline="none"
              href="#"
              onClick={() => {
                navigate('/');
              }}
            >
              <img src="/BlokariaLogoFont.png" height="40px" />
            </Link> */}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'block', md: 'flex' }, mr: 2 }}>
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <GTranslateIcon color="white" sx={{fill:'white'}} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                {Object.keys(lngs).map((lng) => (
                  <MenuItem key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => { i18n.changeLanguage(lng); handleClose(); }
                  }>
                    {lngs[lng].nativeName}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Box>

          <Typography
            noWrap
            color="secondary"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <LinkHref to="/">
              <img src="/BlokariaLogoWhite.png" height="30px" />
            </LinkHref>

            {/* <Link
              variant="button"
              underline="none"
              href="#"
              color="info.main"
              onClick={() => {
                navigate('/');
              }}
              sx={{ my: 1, mx: 1.5 }}
            >
              BLOKARIA
            </Link> */}

          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none' } }}>
            {pages.map((page) => (
              <Button
                key={`${page.name}b`}
                //onClick={handleCloseNavMenu}
                onClick={() => {
                  navigate(`/${page.link}`);
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.nameValue}
              </Button>
            ))}
          </Box>
          {/* <Box sx={{ display: { xs: 'none', md: 'block' }, mx: 3 }}>
            <Button
              variant='outlined'
              onClick={() => {
                navigate('/generate-qr-code', {
                  state: {
                    openForm: true
                  }
                });
              }}

             
              color="secondary"
              sx={{ my: 2, display: 'block' }}
            >
              Generate QR Code
            </Button>
          </Box> */}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AvatarIcon transactionUserData={transactionUserData} token={token} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

              {
                (token && objHasValues(transactionUserData)) ? <GenerateQrCode handleCloseUserMenu={handleCloseUserMenu} navigate={navigate} link="/generate-qr-code" name={t('generate-qr-code')} /> : ''
              }

              {settings.map((setting) => {
                if (
                  (token && objHasValues(transactionUserData) && setting.loggedUser) ||
                  (!token && objIsEmpty(transactionUserData) && !setting.loggedUser)
                ) {
                  return <MenuItemHeader key={setting.link} setting={setting}
                    handleCloseUserMenu={handleCloseUserMenu} navigate={navigate} />;
                }
              }
              )
              }

              {
                (token && objHasValues(transactionUserData)) ?
                  <SignOutOut handleCloseUserMenu={handleCloseUserMenu} navigate={navigate} link="/log-out-user" name={t('logout')} /> :
                  <SignOutOut handleCloseUserMenu={handleCloseUserMenu} navigate={navigate} link="/log-in-user" name={t('login')} />
              }

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const MenuItemHeader = ({ setting, handleCloseUserMenu, navigate }) => {
  return <MenuItem
    key={setting.name}
    onClick={() => {
      navigate(`/${setting.link}`);
      handleCloseUserMenu;
    }}
  >
    <Typography textAlign="center" variant="button">
      {setting.name}
    </Typography>
  </MenuItem>;
};

const SignOutOut = ({ handleCloseUserMenu, navigate, link, name }) => {
  return <MenuItem key="signInOut"
    onClick={() => {
      navigate(`${link}`);
      handleCloseUserMenu;
    }}
  >
    <Typography textAlign="center" variant="button">
      {name}
    </Typography>
  </MenuItem>;
};

const GenerateQrCode = ({ handleCloseUserMenu, navigate, link, name }) => {
  return <MenuItem key="GenerateQrCode"
    onClick={() => {
      navigate(`${link}`, {
        state: {
          openForm: true
        }
      });
      handleCloseUserMenu;
    }}
  >
    <Typography textAlign="center" variant="button">
      {name}
    </Typography>
  </MenuItem>;
};

const AvatarIcon = ({ transactionUserData, token }) => {

  let userName = '';
  let bgcolor = '';
  let src = '/broken-image.jpg';
  let classNameCss = 'triCrte';

  if (transactionUserData && token) {
    userName = transactionUserData.userFullName;
    bgcolor = 'orange';
  }
  return (<Avatar className={classNameCss} {...stringAvatar(userName, src, bgcolor)} alt={userName} />);
};

function stringAvatar(name, src) {
  // let namenew = (!name) ? src : name;
  return {
    sx: {
      bgcolor: '#ff8d00',
      width: 40,
      height: 40
    },
    //children: namenew // `${namenew.split(' ')[0][0]}` //${name.split(' ')[1][0]}
    src: src // `${namenew.split(' ')[0][0]}` //${name.split(' ')[1][0]}
  };
}



export default AppBarResponsive;