import React from 'react';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { checkIfNftValueIsTrue, getDataFieldFromNftObj } from '../../common/commonFunctions';

const NftData = ({ insertedQrCodeData, showOnlyNftCreationDetails = false }) => {
    const { t } = useTranslation();
    let LogoNft = require('../../nftlogo2.png');

    return (<>
        <Box className="darkBlueBox" noValidate sx={{ mt: 2, py: 1, width: '100%', justifyContent: 'center', display: 'flex',color: '#000',fontWeight: 600 }}>
            NFT Data
            <img src={LogoNft} alt="Logo NFT" style={{ marginLeft: '10px', width: '30px', height: '28px' }} />

        </Box>
        {/* <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
            <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
              {languages[lang].walletName}:
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              {insertedQrCodeData?._nfts.length > 0 ? insertedQrCodeData._nfts[0].walletNameSource : ''}
            </Grid>
          </Grid> */}

        {(showOnlyNftCreationDetails) ? '' : <TransactionDetialsToClient
            insertedQrCodeData={insertedQrCodeData} t={t} />}


        <Divider sx={{ mt: 1 }}>
            <Chip label="NFT Creation Details" />
        </Divider>

        <Grid container direction="row" sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
                {t('nftAssetId')}:
            </Grid>
            <Grid item xs={12} sm={8} md={9} sx={{ wordWrap: 'break-word' }}>
                {insertedQrCodeData?._nfts[0].assetId ?
                    (
                        <Link
                            variant="span"
                            sx={{ color: 'yellow' }}
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`${process.env.REACT_APP_TESTNET_TOKEN}${insertedQrCodeData._nfts[0].assetId}`}
                        >
                            {insertedQrCodeData._nfts[0].assetId}
                        </Link>)
                    : ''}
            </Grid>
        </Grid>

        {insertedQrCodeData?._nfts[0].nftlocation ? (
            <Grid container direction="row" sx={{ pt: 1 }}>
                <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
                    NFT Location:
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Link
                        variant="span"
                        sx={{ color: 'white !important' }}
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`${insertedQrCodeData._nfts[0].nftlocation}`}
                    >
                        {insertedQrCodeData._nfts[0].nftlocation}
                    </Link></Grid>
            </Grid>
        ) : (
            ''
        )}


        <Grid container direction="row" sx={{ pt: 1 }}>
            <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
                {t('txIdFromNft')}:
            </Grid>
            <Grid item xs={12} sm={8} md={9} sx={{ wordWrap: 'break-word' }}>
                {insertedQrCodeData?._nfts[0].transactionId ? (
                    <Link
                        variant="span"
                        sx={{ color: 'orange' }}
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`${process.env.REACT_APP_TESTNET}${insertedQrCodeData?._nfts[0].transactionId}`}
                    >
                        {insertedQrCodeData?._nfts[0].transactionId}
                    </Link>
                ) : (
                    ''
                )}
            </Grid>
        </Grid>
        {checkIfNftValueIsTrue(insertedQrCodeData, 'cid') ? (
            <Grid container direction="row" sx={{ pt: 1 }}>
                <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
                    {t('nftImage')}:
                </Grid>
                <Grid item xs={9} sx={{ wordWrap: 'break-word' }}>
                    <Link
                        target="_blank"
                        rel="noreferrer noopener"
                        //component={RouterLink} ovo ide na to=""
                        href={`https://ipfs.io/ipfs/${getDataFieldFromNftObj(insertedQrCodeData, 'cid')}`}
                        underline="none"
                        color="purple.light"
                        sx={{ my: 1 }}
                    >
                        {getDataFieldFromNftObj(insertedQrCodeData, 'cid')}
                    </Link>
                </Grid>
            </Grid>
        ) : (
            ''
        )}


        {(insertedQrCodeData.hasstory) ? (
            <><Divider sx={{ mt: 1 }}>
                <Chip label="NFT Story Details" />
            </Divider>
                <Grid container direction="row" sx={{ pt: 1 }}>
                    <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
                        {t('nftStoryDetails')}:
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <Link
                            variant="span"
                            sx={{ color: 'orange' }}
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`${process.env.REACT_APP_WEB_SITE}/story/${insertedQrCodeData._id}`}
                        >
                            {t('nftStory')}
                        </Link></Grid>
                </Grid></>
        ) : (
            ''
        )
        }

    </>
    );
};

const TransactionDetialsToClient = ({ t, insertedQrCodeData }) => {
    return (<>
        <Box display={insertedQrCodeData?._nfts[0].addressClientWallet ? '' : 'none'}>
            <Divider sx={{ mt: 1 }}>
                <Chip label="Transaction Details to Client" />
            </Divider>
        </Box>


        <Grid container direction="row" sx={{ pt: 1 }} display={insertedQrCodeData?._nfts[0].addressClientWallet ? '' : 'none'}>
            <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
                {t('nftReceiverAddressWallet')}:
            </Grid>
            <Grid xs={12} sm={8} md={9} sx={{ wordWrap: 'break-word' }}>
                {insertedQrCodeData?._nfts[0].addressClientWallet ? insertedQrCodeData._nfts[0].addressClientWallet : ''}
            </Grid>
        </Grid>
        <Grid container direction="row" sx={{ pt: 1 }} display={insertedQrCodeData?._nfts[0].clientTxHash ? '' : 'none'}>
            <Grid item xs={12} sm={4} md={3} sx={{ fontWeight: 'bold' }}>
                {t('nfTTxIdToClientWallet')}
            </Grid>
            <Grid item xs={12} sm={8} md={9} sx={{ wordWrap: 'break-word' }}>
                {insertedQrCodeData?._nfts[0].clientTxHash ? (
                    <Link
                        variant="span"
                        sx={{ color: 'orange' }}
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`${process.env.REACT_APP_TESTNET}${insertedQrCodeData._nfts[0].clientTxHash}`}
                    >
                        {insertedQrCodeData._nfts[0].clientTxHash}
                    </Link>
                ) : (
                    ''
                )}
            </Grid>
        </Grid></>);
};

export default NftData;