import React, { useCallback, useEffect, useState } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import { useDispatch, useSelector } from 'react-redux';
import AddProjectComponent from '../../components/AddProjectComponent';
import { listAllProject } from '../../redux/tree/actions';
import DialogView from '../../view/DialogView';
import { deleteProject, updateProject } from '../../redux/tree/actions';
import DialogFormView from '../../view/DialogFormView';

function MyProjectsPage() {

    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.token);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [getProjectArray, setProjectArray] = useState(['', '', '', '']);

    const getListOfProjects = useCallback((token) => {
        dispatch(listAllProject(token));
    });

    useEffect(() => {
        getListOfProjects(token);
    }, []);

    const dispatchDeleteProject = useCallback((projectId) => {
        dispatch(deleteProject({ projectId, token }));
    });

    const handleClose = (value) => {
        setOpen(false);
        (value) ? dispatchDeleteProject(getProjectArray) : '';
    };

    const dispatchDeleteProjectHandler = (projectId) => {
        setOpen(true);
        setProjectArray(projectId);
    };

    ////////// HANDLE PROJECT UPDATE ////////
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const dispatchToUpdateProject = useCallback((projectData) => {
        dispatch(updateProject({ projectData, token }));
    });

    const handleSubmitDialog = (projectData) => {

        dispatchToUpdateProject(projectData);
        setOpenDialog(false);
    };

    const handleUpdateProject = (array) => {
        setProjectArray(array);
        setOpenDialog(true);
    };

    return (
        <>
            <WrapperPage>
                <WrapperContainer>
                    <div className='generateQrCodeWellcome' style={{ paddingBottom: '2rem' }}>
                        <DialogView
                            handleClose={handleClose}
                            open={open} />
                        <AddProjectComponent
                            handleUpdateProject={handleUpdateProject}
                            dispatchDeleteProjectHandler={dispatchDeleteProjectHandler} />
                        <DialogFormView handleCloseDialog={handleCloseDialog}
                            openDialog={openDialog}
                            getProjectArray={getProjectArray}
                            handleSubmitDialog={handleSubmitDialog} />
                    </div>
                </WrapperContainer>
            </WrapperPage>
        </>
    );
}

export default MyProjectsPage;
