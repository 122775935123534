import { initReactI18next } from 'react-i18next';
//import { createRoot } from 'react-dom/client';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
// https://www.youtube.com/watch?v=w04LXKlusCQ

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

        //lng: 'en', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        react: { useSuspense: true },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain']
        }
    });
