import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function BreadcrumbsView() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { qrcode } = useParams();
    return (
        <Container maxWidth="lg" sx={{ mt: 3, pt: 4 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="#"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    {t('contractFlow')}
                </Link>
                <Link
                    underline="hover"
                    color="secondary"
                    href="#"
                    onClick={() => {
                        navigate(`/generate-contract/${qrcode}`);
                    }}
                >
                    {t('generateDeal')}
                </Link>
                <Link
                    underline="hover"
                    color="secondary"
                    href="#"
                    onClick={() => {
                        navigate(`/verify-contract/${qrcode}`);
                    }}
                >
                    {t('verifyDeal')}
                </Link>
                <Typography color="secondary" aria-current="page">
                    {t('submitDataToBlockchain')}
                </Typography>
            </Breadcrumbs>
        </Container>);
}

export default BreadcrumbsView;