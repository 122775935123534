import React, { useCallback, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getListQrCodesGeneral, clearStateListQrCodesByUser } from '../../redux/tree/actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ShowLoader from '../../view/ShowLoader';
import ListGridQrCodesView from '../../view/ListGridQrCodesView';
import { useTranslation } from 'react-i18next';

function QrCodesListComponent() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);
    const { transactionData, loading, loaded } = useSelector((state) => state.tree);


    const dispatchCheckExistingQrCode = useCallback(
        () => {
            stableDispatch(clearStateListQrCodesByUser());
            stableDispatch(getListQrCodesGeneral({ limit: 45, skip: 0 }));
        },
        [stableDispatch]
    );

    useEffect(() => {
        dispatchCheckExistingQrCode();
    }, [dispatchCheckExistingQrCode]);


    let showData = '';
    switch (true) {
        case (loading):
            showData = <ShowLoader />;
            break;
        case (loaded && transactionData.length > 0):
            showData = <ListGridQrCodesView navigate={navigate} transactionData={transactionData} />;
            break;
        default:
            break;
    }


    return (
        <>
            <Container name="WrapperContainer" maxWidth="xl"
                sx={{ pt: 0, pb: 4, minHeight: '100vh' }}>
                <QrCodeListTitle t={t} />
                <div className='showListOfQRCodes'>
                    <div className='showDataWidth'>{showData}</div>
                </div>
            </Container>
        </>
    );
}

const QrCodeListTitle = ({ t }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                my: 4
            }}
        >
            <Typography variant="h4" color="info" sx={{ mt: 4 }}>
                {t('latestGeneratedQRcodes')}
            </Typography>
        </Box>
    );
};

export default QrCodesListComponent;