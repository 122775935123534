import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import TransactionCreatedWelcome from '../../view/TransactionCreatedWelcome';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import TransactionCreatedComponent from '../../components/TransactionCreatedComponent';

function TransactionCreated() {
  return (
    <>
      <WrapperPage>
        <div className='blueTransparentWrapper'>
          <WrapperContainer>
            <TransactionCreatedWelcome />
            <TransactionCreatedComponent />
          </WrapperContainer>
        </div>
      </WrapperPage>
    </>
  );
}

export default TransactionCreated;
