import React, { useEffect, useCallback, useState } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import EditQrCodePageComponent from '../../components/EditQrCodePageComponent';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQrCodeDataNoRedeem, clearState, updateQrCodeUrlForward } from '../../redux/basic/actions';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';
import { objHasValues } from '../../common/commonFunctions';
import GoBackButton from '../../view/GoBackButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SnackBarView from '../../view/SnackBarView';

function EditQrCodePage() {

    const [urlForwardingState, setUrlForwardingState] = useState(false);
    const { token } = useSelector((state) => state.token);
    const { insertedQrCodeData, errorMessage, loading, loaded } = useSelector((state) => state.basic);
    //const { lang } = useSelector((state) => state.user);
    const { qrcode } = useParams();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);

    const dispatchCheckExistingQrCode = useCallback(
        (qrcode) => {
            dispatch(clearState());
            dispatch(getQrCodeDataNoRedeem(qrcode, token));
        }
    );
    const dispatchUpdateQrCodeUrlForward = useCallback(
        (data) => {
            //dispatch(clearState());
            dispatch(updateQrCodeUrlForward(data));
        }
    );


    useEffect(() => {
        dispatchCheckExistingQrCode(qrcode);
    }, []);

    const openSuccessDialogValue = (openSuccessDialogValue) => {
        if (!errorMessage) {
            setOpenSuccessDialog(openSuccessDialogValue);
        }
    };

    useEffect(() => {
        if (!loading && loaded && insertedQrCodeData?._nfts && insertedQrCodeData?._nfts.length > 0) {
            console.log('USAO U SET', insertedQrCodeData?._nfts[0]?.urlforwarding);
            console.log('USAO U SET insertedQrCodeData', insertedQrCodeData);
            let urlFor = insertedQrCodeData?._nfts[0]?.urlforwarding;
            console.log('USAO U SET insertedQrCodeData urlFor', urlFor);
            setUrlForwardingState((!urlFor) ? false : urlFor);
        }
    }, [insertedQrCodeData, errorMessage]);

    const handleChangeUrl = () => {
        let dataForm = {
            qrcode,
            token,
            urlforwarding: !urlForwardingState
        };
        dispatchUpdateQrCodeUrlForward(dataForm);
        setUrlForwardingState(!urlForwardingState);
    };

    let qrCodeView;

    useEffect(() => {
        setOpenDialog(true);
    }, [errorMessage]);

    switch (true) {
        case loading:
            qrCodeView = <ShowLoader />;
            break;
        case token === '':
            qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode="noToken" />;
            break;
        // case errorMessage !== '' && !loaded:
        //     qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
        //     break;
        case errorMessage !== '' && !loaded:
            qrCodeView = <EditQrCodePageComponent qrcode={qrcode} handleChangeUrl={handleChangeUrl}
                urlForwardingState={urlForwardingState} insertedQrCodeData={insertedQrCodeData} />;
            break;
        case (loaded && insertedQrCodeData && objHasValues(insertedQrCodeData)):
            qrCodeView = <EditQrCodePageComponent qrcode={qrcode} handleChangeUrl={handleChangeUrl}
                urlForwardingState={urlForwardingState} insertedQrCodeData={insertedQrCodeData} openSuccessDialogValue={openSuccessDialogValue} />;
            break;
        default:
            qrCodeView = <ShowLoader color="secondary" />;
            break;
    }

    return (
        <>
            <WrapperPage>
                <div className="blueTransparentWrapper">
                    <WrapperContainer>
                        <GoBackButton />
                        <Container component="main" maxWidth="sm">
                            <Box
                                sx={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <SnackBarView
                                    handleClose={(event, reason) => {
                                        if (reason === 'clickaway') {
                                            return;
                                        }
                                        setOpenDialog(false);
                                    }}
                                    errorMessage={errorMessage}
                                    openSnackStatus={openDialog}
                                    type={'error'} />
                                <SnackBarView
                                    handleClose={(event, reason) => {
                                        if (reason === 'clickaway') {
                                            return;
                                        }
                                        setOpenSuccessDialog(false);
                                    }}
                                    errorMessage={'Uspešno sačuvano!'}
                                    openSnackStatus={openSuccessDialog}
                                    type={'success'} />
                                <Typography variant="h4" align="center">
                                    Edit Product
                                </Typography>
                            </Box>
                            {qrCodeView}
                        </Container>
                    </WrapperContainer>
                </div>
            </WrapperPage>
        </>
    );
}

export default EditQrCodePage;
