import * as ActionTypes from './actions';
// USER
const initialStateUser = {
  errorMessage: '',
  internalErrorCode: '',

  transactionUserData: {},
  loaded: false,
  loading: false,
  resetPasswordData: '',
  lang: 'srb',
  sendApprovalFromClient: false,
  loadedReg: false
};

const immutableStateUser = Object.freeze({
  errorMessage: '',
  internalErrorCode: '',

  transactionUserData: {},
  loaded: false,
  loading: false,
  resetPasswordData: '',
  lang: 'srb',
  sendApprovalFromClient: false,
  loadedReg: false
});

const immutableStateUserNoUserData = Object.freeze({
  errorMessage: '',
  internalErrorCode: '',
  loaded: false,
  loading: false,
  resetPasswordData: '',
  lang: 'srb',
  sendApprovalFromClient: false,
  loadedReg: false
});

const userReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    // -----------------------  0 CLEAR USER ---------------------------
    case ActionTypes.CLEAR_STATE_USER_SUCCESS:
      return {
        ...state,
        ...immutableStateUser
      };
    // -----------------------  0 - 1 CLEAR USER NO USER DATA ---------------------------

    case ActionTypes.CLEAR_STATE_NO_USER_DATA:
      return {
        ...state,
        ...immutableStateUserNoUserData
      };
    case ActionTypes.CLEAR_STATE_NO_USER_DATA_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        loaded: true,
        loading: false
      };
    case ActionTypes.CLEAR_STATE_NO_USER_DATA_ERROR:
      return {
        ...state,
        errorMessage: 'Neki User Error',
        internalErrorCode: 'Error1234567',
        loaded: true,
        loading: false
      };

    // ----------------------- 1 REGISTER USER  ---------------------------

    case ActionTypes.REGISTER_USER:
      return {
        ...state,
        loadedReg: false,
        loaded: false,
        loading: true
      };
    case ActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loaded: true,
        loadedReg: true,
        loading: false,
        transactionUserData: {}
        //transactionUserData: action.payload[0]
      };
    case ActionTypes.REGISTER_USER_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    // ----------------------- 2 LOG IN USER  ---------------------------

    case ActionTypes.LOGIN_USER:
      console.warn('LOGIN_USER ocistio transactionUserData ');
      return {
        ...state,
        loaded: false,
        loading: true,
        transactionUserData: {},
        errorMessage: '',
        internalErrorCode: ''
      };
    case ActionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        transactionUserData: action.payload
      };
    case ActionTypes.LOGIN_USER_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload?.data?.internalErrorCode
      };

    // ----------------------- 3 RESET PASSWORD  ---------------------------

    case ActionTypes.RESET_PASSWORD:
      return {
        ...state,
        loaded: false,
        loading: true,
        resetPasswordData: ''
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        resetPasswordData: action.payload.response
      };
    case ActionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        resetPasswordData: ''
      };
    // ----------------------- 4 RESET PASSWORD CODE  ---------------------------

    case ActionTypes.RESET_PASSWORD_CODE:
      return {
        ...state,
        loaded: false,
        loading: true,
        resetPasswordData: ''
      };
    case ActionTypes.RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        resetPasswordData: action.payload
      };
    case ActionTypes.RESET_PASSWORD_CODE_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        resetPasswordData: ''
      };

    // ----------------------- 4 GET USER DATA  ---------------------------

    case ActionTypes.GET_USER_DATA:
      // console.warn('GET_USER_DATA ocistio  transactionUserData');
      return {
        ...state,
        loaded: false,
        loading: true,
        transactionUserData: {}
      };
    case ActionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        transactionUserData: action.payload
      };
    case ActionTypes.GET_USER_DATA_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        transactionUserData: {}
      };


    // ----------------------- 6 SEND_APPROVAL_TO_CLIENT  ---------------------------

    case ActionTypes.SEND_APPROVAL_TO_CLIENT:
      return {
        ...state,
        loaded: false,
        loading: true,
        sendApprovalFromClient: false
      };

    case ActionTypes.SEND_APPROVAL_TO_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        sendApprovalFromClient: true
      };
    case ActionTypes.SEND_APPROVAL_TO_CLIENT_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    default:
      return { ...state };
  }
};

export default userReducer;
