import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import ShowLoader from '../../view/ShowLoader';
import ShowError from '../../view/ShowError';

const TransactionCreatedComponentSuccess = React.lazy(() => import('../../view/TransactionCreatedComponentSuccess'));

const TransactionCreatedComponent = () => {
  const { loadedBlockChainTransaction, loading, walletQrId, transactionData, insertedQrCodeData, errorMessage, internalErrorCode } = useSelector(
    (state) => state.basic
  );
  let { qrcode } = useParams();

  let showTransactionData = '';

  if (loading) {
    showTransactionData = <ShowLoader />;
  } else if (loadedBlockChainTransaction && insertedQrCodeData && walletQrId) {
    showTransactionData = <TransactionCreatedComponentSuccess data={{ walletQrId, transactionData, insertedQrCodeData }} />;
  } else if (errorMessage && internalErrorCode) {
    showTransactionData = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
  } else {
    showTransactionData = <Navigate to={`/status/${qrcode}`} replace={true} />;
  }
  return <Suspense fallback={<div>Loading...</div>}>{showTransactionData}</Suspense>;
};

export default TransactionCreatedComponent;
