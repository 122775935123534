import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import YoutubeEmbed from '../YoutubeEmbed';
import '../YoutubeEmbed/styles.css';
import Grid from '@mui/material/Grid';
//import GenerateContractFormDataText from '../GenerateContractFormDataText';
import NftStoryComponent from '../../components/NftStoryComponent';
import GenerateContractWelcome from '../GenerateContractWelcome';
import { youtubeParser } from '../../common/commonFunctions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { checkIfNftExist } from '../../common/commonFunctions';


function GenerateContractFormData({ formik, qrcode, insertedQrCodeData }) {

  const { t } = useTranslation();

  let showNameIcon = (formik.values.ownernamecb) ?
    <Tooltip title={t('name-will-be-added-to-bc')}><VisibilityIcon sx={{ color: 'grey' }} /></Tooltip>
    :
    <Tooltip title={t('name-will-not-be-added-to-bc')}><VisibilityOffIcon sx={{ color: 'grey' }} /></Tooltip>;

  let showEmailIcon = (formik.values.clientemailcb) ?
    <Tooltip title={t('email-will-be-added-to-bc')}><VisibilityIcon sx={{ color: 'grey' }} /></Tooltip>
    :
    <Tooltip title={t('email-will-not-be-added-to-bc')}><VisibilityOffIcon sx={{ color: 'grey' }} /></Tooltip>;

  let nftDataExist = checkIfNftExist(insertedQrCodeData);
  console.log('nftDataExist', nftDataExist);
  console.log('formik.values.cbnftimage', formik.values.cbnftimage);

  return (

    <React.Fragment>
      <Container maxWidth="lg" >
        <CssBaseline />
        <GenerateContractWelcome />
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} sx={{ my: 3, mb: 4 }} alignItems="flex-start" justifyContent="space-between">
            <Grid item xs={12} lg={6} md={6}>
              <Box noValidate sx={{ mt: 2, mb: 3, width: '100%', justifyContent: 'center', display: 'flex' }}>
                {t('owner')}
              </Box>

              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="clientName"
                    name="clientName"
                    label={t('ownerName')}
                    value={formik.values.clientName}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    error={formik.touched.clientName && Boolean(formik.errors.clientName)}
                    helperText={formik.touched.clientName && formik.errors.clientName}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox
                      id="ownernamecb"
                      name="ownernamecb"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      style={{
                        color: '#ff8d00'
                      }}
                      checked={formik.values.ownernamecb}
                    />} label="" />
                  </FormGroup>
                </Grid>
                <Grid item xs={1}>
                  {showNameIcon}
                </Grid>
              </Grid>

              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="clientEmail"
                    name="clientEmail"
                    label={t('ownerEmail')}
                    value={formik.values.clientEmail}
                    onChange={formik.handleChange}
                    error={formik.touched.clientEmail && Boolean(formik.errors.clientEmail)}
                    helperText={formik.touched.clientEmail && formik.errors.clientEmail}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox
                      id="clientemailcb"
                      name="clientemailcb"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      style={{
                        color: '#ff8d00'
                      }}
                      checked={formik.values.clientemailcb}
                    />} label="" />
                  </FormGroup>
                </Grid>
                <Grid item xs={1}>
                  {showEmailIcon}
                </Grid>
              </Grid>

              <TextField
                //variant="standard"
                //color="warning"
                focused
                fullWidth
                margin="normal"
                id="clientMessage"
                name="clientMessage"
                label={t('addMesageForBlockChain')}
                value={formik.values.clientMessage}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                error={formik.touched.clientMessage && Boolean(formik.errors.clientMessage)}
                helperText={formik.touched.clientMessage && formik.errors.clientMessage}
              />



              {/* <IpfsImageLink formik={formik}></IpfsImageLink> */}
              <NftSendAddress formik={formik} nftDataExist={nftDataExist}></NftSendAddress>

              {/* <Typography sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: (insertedQrCodeData?.longText) ? insertedQrCodeData.longText : '' }}>

              </Typography> */}
              {/* <Typography sx={{ mt: 2 }}>
               Coupons : {transactionUserData.numberOfCoupons}
              </Typography>
              <Typography sx={{ mt: 2 }}>
               Availability to continue : {(availabilityToContinue) ? 'OK' : 'Not'}
              </Typography> */}

            </Grid>
            <Grid item xs={12} lg={6} md={6}>

              <Box noValidate sx={{ mt: 2, mb: 3, width: '100%', justifyContent: 'center', display: 'flex' }}>
                {t('productCreatorNoAllowedToChange')}
              </Box>

              <TextField size="small" color="error" margin="normal" required fullWidth id="qrcode" label="Qr Kod" name="qrcode" value={qrcode} />
              <TextField
                size="small"
                color="error"
                margin="normal"
                required
                fullWidth
                id="productName"
                label={t('productName')}
                name="productName"
                value={insertedQrCodeData.productName}
              />
              <TextField
                size="small"
                color="error"
                margin="normal"
                required
                fullWidth
                id="merchantname"
                label={t('creatorName')}
                name="merchantname"
                value={insertedQrCodeData.userFullname}
              />
              <TextField
                margin="normal"
                size="small"
                color="error"
                required
                fullWidth
                id="merchantmessage"
                label={t('creatorMsg')}
                name="merchantmessage"
                value={insertedQrCodeData.userDesc}
              />

              <TextField
                size="small"
                color="error"
                margin="normal"
                required
                fullWidth
                id="merchantemail"
                label={t('ownerEmail')}
                name="merchantemail"
                value={insertedQrCodeData.userEmail}
              />

              {/* {insertedQrCodeData.costOfProduct ? costOfProduct(insertedQrCodeData) : ''} */}
              {/* {insertedQrCodeData?._image?.length ? productPictureForm(insertedQrCodeData._image[0].productPicture) : ''} */}
              {insertedQrCodeData.productVideo ? productVideoForm(insertedQrCodeData, t) : ''}
              {insertedQrCodeData.contributorData ? <ContributorData t={t} insertedQrCodeData={insertedQrCodeData} /> : ''}

              <Typography sx={{ mt: 2 }}>
                {t('redeemStatus')}: {insertedQrCodeData.qrCodeRedeemStatus == 0 ? t('redeemStatusValueNotRedeemed') : t('redeemStatusValueRedeemed')}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                {t('publicStatus')}: {(insertedQrCodeData.publicQrCode) ? t('public') : t('private')}
              </Typography>

            </Grid>
          </Grid>
          <Button type="submit" color="primary" fullWidth variant="contained" sx={{ mt: 3, mb: 0, backgroundColor: (theme) => theme.orangeButton.button }}>
            {t('generateContract')}
          </Button>
        </Box>
      </Container>

      {
        showPictureAndVideo({
          picture: (insertedQrCodeData?._image[0]?.productPicture) ? insertedQrCodeData?._image[0]?.productPicture : '',
          video: insertedQrCodeData.productVideo
        })
      }
      <NftStoryComponent insertedQrCodeData={insertedQrCodeData} showPic={false} />
      {/* <GenerateContractFormDataText /> */}
    </React.Fragment >
  );
}

// const costOfProduct = (insertedQrCodeData) => {
//   return (
//     <TextField
//       margin="normal"
//       color="error"
//       size="small"
//       required
//       fullWidth
//       id="costOfProduct"
//       label="Cost of Product"
//       name="costOfProduct"
//       value={insertedQrCodeData.costOfProduct}
//     />
//   );
// };

const ContributorData = ({ insertedQrCodeData, t }) => {
  return (
    <TextField
      size="small"
      color="error"
      margin="normal"
      required
      fullWidth
      id="contributorData"
      label={t('sponsorSupporterContributor')}
      name="contributorData"
      value={insertedQrCodeData.contributorData}
    />
  );

};

const productVideoForm = (insertedQrCodeData, t) => {
  return (
    <TextField
      size="small"
      color="error"
      margin="normal"
      required
      fullWidth
      id="productVideo"
      label={t('productVideo')}
      name="productVideo"
      value={insertedQrCodeData.productVideo}
    />
  );
};

/* const productPictureForm = (productPicture) => {
  return (
    <TextField
      margin="normal"
      disabled
      color="error"
      size="small"
      required
      fullWidth
      id="productPicture"
      label="Product Picture"
      name="productPicture"
      value={productPicture}
    />
  );
}; */

/* const IpfsImageLink = ({formik}) => {
  let returnData = '';
  if (formik.values.cbnftimage) {
     returnData = (<TextField
      focused
      fullWidth
      margin="normal"
      id="nftimage"
      name="nftimage"
      label="Add IPFS Image Code if you want to Mint NFT"
      value={formik.values.nftimage}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      error={formik.touched.nftimage && Boolean(formik.errors.nftimage)}
      helperText={formik.touched.nftimage && formik.errors.nftimage}
    />);
   }
  return returnData;
};
 */

const NftSendAddress = ({ formik, nftDataExist }) => {
  let returnData = '';

  returnData = (<>
    <FormGroup sx={{ display: 'none' }}>
      <FormControlLabel control={<Checkbox
        disabled
        id="cbnftimage"
        name="cbnftimage"
        onChange={(e) => {
          formik.handleChange(e);
        }}
        checked={formik.values.cbnftimage}
      />} label="Kreiraj NFT" />
    </FormGroup>

    {(nftDataExist) ? <> <TextField
      focused
      fullWidth
      margin="normal"
      id="nftsendaddress"
      name="nftsendaddress"
      label="Adresa novčanika primaoca"
      value={formik.values.nftsendaddress}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      error={formik.touched.nftsendaddress && Boolean(formik.errors.nftsendaddress)}
      helperText={formik.touched.nftsendaddress && formik.errors.nftsendaddress}
    /> <Link href="https://www.youtube.com/watch?v=mSVAAvmA_-k" target="_blank" color="inherit">
        If you don&apos;t have Cardano Wallet for your NFT, please check this video.
      </Link> </> : ''}

  </>);

  return returnData;
};

const showPictureAndVideo = ({ picture, video }) => {
  if (picture) {
    picture = (
      <Card>
        <CardActionArea>
          <CardMedia component="img" widht="200" image={`${process.env.REACT_APP_MOLECULER_SERVICE}/${picture}`} alt="Picture" />
        </CardActionArea>
      </Card>
    );
  }
  if (video) {
    let embedId = youtubeParser(video);
    video = (
      <Card>
        <CardActionArea>
          <CardMedia>
            <YoutubeEmbed embedId={`${embedId}`} />
          </CardMedia>
        </CardActionArea>
      </Card>
    );
  }
  return (
    <>
      <Container maxWidth="md" sx={{ mb: 2, mt: 4 }}>
        <Grid container spacing={4} direction="row" justifyContent="center" alignItems="flex-start">
          {(picture && video) ? <>
            <Grid item xs={12} md={6}>
              {picture}
            </Grid>
            <Grid item xs={12} md={6}>
              {video}
            </Grid> </> : <> <Grid item xs={12} md={12}>
              {picture}
            </Grid>
            <Grid item xs={12} md={12}>
              {video}
            </Grid></>}
        </Grid>
      </Container>
    </>
  );
};

export default GenerateContractFormData;
