import React, { useState } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import '../../css/general.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const { isEmpty } = require('lodash');

function DocumentPage() {
  const { t } = useTranslation();

  const docPages = [
    {
      name: `${t('what-is-blokaria')}`,
      link: '/doc/use-case-general',
      mainLink: true
    },

    // {
    //   name: `${t('how-to-create-qr-code')}`,
    //   link: '/doc/how-to-create-qr-code',
    //   mainLink: true
    // },
    {
      name: `${t('t-shirt-collection1')}`,
      link: '/doc/t-shirt-presentation',
      mainLink: true
    },
    {
      name: `${t('three-min-pitch')}`,
      link: '/doc/three-min-pitch',
      mainLink: true
    }
  ];
  const docPagesHidden = [
    // {
    //   name: 'Roadmap',
    //   link: '/doc/roadmap',
    //   mainLink: false
    // },
    // {
    //   name: 'Diagrams',
    //   link: '/doc/sequence-diagrams',
    //   mainLink: false
    // },
    // {
    //   name: 'Business case',
    //   link: '/doc/business-case',
    //   mainLink: false
    // },
    // {
    //   name: 'WBS',
    //   link: '/doc/work-brakedown-structure',
    //   mainLink: false
    // },
    // {
    //   name: 'User Interface Flow',
    //   link: '/doc/user-interface-flow',
    //   mainLink: false
    // },
    {
      name: 'Use Cases',
      link: '/doc/use-case-product',
      mainLink: false
    },
    // {
    //   name: 'Requirements',
    //   link: '/doc/requirements',
    //   mainLink: false
    // },
    // {
    //   name: 'GO 2 Market Strategy',
    //   link: '/doc/go-to-market-strategy',
    //   mainLink: false
    // },
    {
      name: 'Technical Architecture',
      link: '/doc/technical-architecture',
      mainLink: false
    }
    // {
    //   name: 'Open code on git',
    //   link: '/doc/open-code-on-git',
    //   mainLink: false
    // },
    // {
    //   name: 'NFT Character',
    //   link: '/doc/nft-character',
    //   mainLink: false
    // }
  ];

  const fullMergePages = [...docPages, ...docPagesHidden];


  let navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const { transactionUserData } = useSelector((state) => state.user);

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const triggerToggle = () => {
    setVisible(!visible);
  };

  return (
    <WrapperPage>
      <WrapperContainer>
        <Grid container name="WrapperContainer" spacing={4} direction="row" maxWidth="lg" sx={{ pt: 5, pb: 4 }}>
          <Grid item xs={12} md={3}>
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="secondary"
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'block' }
                }}
              >
                {fullMergePages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="left">
                      <Link
                        variant="button"
                        underline="none"
                        color="info"
                        onClick={() => {
                          navigate(page.link);
                        }}
                        sx={{ my: 1, mx: 1.5 }}
                      >
                        {page.name}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {docPages.map((item, i) => {
                return <OneGridQrCode key={i} navigate={navigate} item={item} />;
              })}
              {!isEmpty(transactionUserData) ? <Grid key="toggleButton" xs={12} md={12} item sx={{ textAlign: 'left', my: 1 }}>
                <Button variant="contained" className="turquoiseBox" onClick={triggerToggle}>
                  {t('show-other-fields')}
                </Button>
              </Grid> : ''}


              {visible && transactionUserData.userRole === 5
                ? docPagesHidden.map((item, i) => {
                  return <OneGridQrCode key={i} navigate={navigate} item={item} />;
                })
                : ''}
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Outlet />
          </Grid>
        </Grid>
      </WrapperContainer>
    </WrapperPage>
  );
}
const OneGridQrCode = ({ navigate, item }) => {
  return (
    <Grid key={item.link} xs={12} md={12} item sx={{ textAlign: 'left', my: 1 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate(item.link);
        }}
      >
        {item.name}
      </Button>
    </Grid>
  );
};
export default DocumentPage;
