import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import ResetPasswordComponent from '../../components/ResetPasswordComponent';
import { clearStateNoUserData } from '../../redux/user/actions';


function ResetPasswordPage() {

    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);

    const dispatchToClearRedux = useCallback(() => {
        stableDispatch(clearStateNoUserData());
    }, [stableDispatch]);

    useEffect(() => {
        dispatchToClearRedux();
    }, [dispatchToClearRedux]);

    return (
        <WrapperPage>
            <WrapperContainer>
                <ResetPasswordComponent />
            </WrapperContainer>
        </WrapperPage>
    );
}

export default ResetPasswordPage;
