import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const TransactionCreatedWelcome = () => {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="xs" sx={{ pt: 1 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography variant="h4" color="warning" align="center">
          {t('statusAndTxInfo')}
        </Typography>
      </Box>
    </Container>
  );
};

export default TransactionCreatedWelcome;
