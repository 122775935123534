import React, { useCallback, useEffect } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateNoUserData } from '../../redux/user/actions';
import LogInUser from '../../components/LogInUser';
import { objIsEmpty } from '../../common/commonFunctions';

function LogInPage() {

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const { token } = useSelector((state) => state.token);
  const { transactionUserData } = useSelector((state) => state.user);



  const dispatchToClearRedux = useCallback(() => {
    stableDispatch(clearStateNoUserData());
  }, [stableDispatch]);

  useEffect(() => {
    (!token || objIsEmpty(transactionUserData)) ? dispatchToClearRedux() : '';
  }, [dispatchToClearRedux]);

  return (
    <>
      <WrapperPage>
        <WrapperContainer>
          <LogInUser />
        </WrapperContainer>
      </WrapperPage>
    </>
  );
}

export default LogInPage;
