import { makePostRequest, makePostFileRequest } from '../request';

export const generateContract = ({ data, token }) => makePostRequest('api/wallet/generateContract', token, data);

export const generateContractApproval = ({ data, token }) => makePostRequest('api/wallet/sendContractEmail', token, data);

export const initiateTransactionToClientWallet = ({ qrcode, token, accessCode, userLang }) => makePostRequest('api/wallet/initiateTransactionToClientWallet', token, { qrcode, accessCode, userLang });

export const generateQrCodeInSystem = ({ dataForm, token }) => makePostFileRequest('upload/multi', token, dataForm);

export const generateNftFromExistingQrCode = ({ dataForm, token }) => makePostFileRequest('upload/generateNftFromExistingQrCode', token, dataForm);

export const generateQrCodeInSystemNoImage = ({ dataForm, token }) => makePostRequest('api/image/generateQrCodeInSystemNoImage', token, dataForm);

export const getQrCodeData = ({ qrcode, accessCode, token }) => makePostRequest('api/wallet/getQrCodeData', token, { qrcode, accessCode });

export const getQrCodeDataNoRedeem = ({ qrcode, token }) => makePostRequest('nrapi/wallet/getQrCodeDataNoRedeem', token, { qrcode });

export const updateQrCodeText = ({ qrcode, longText, token }) => makePostRequest('api-verify/wallet/updateQrCodeText', token, { qrcode, longText });

export const updateQrCodeUrl = ({ qrcode, nftlocation, token }) => makePostRequest('api-verify/nftcardano/updateQrCodeUrl', token, { qrcode, nftlocation });

export const updateQrCodeUrlForward = ({ qrcode, urlforwarding, token }) => makePostRequest('api-verify/nftcardano/updateQrCodeUrlForward', token, { qrcode, urlforwarding });

export const updateNftStory = ({ qrcode, nftStory, token }) => makePostRequest('api/nftcardano/updateNftStory', token, { qrcode, nftStory });

export const getQrCodeFromId = (idcode) => makePostRequest('nrapi/wallet/getQrCodeFromId', null, { idcode });
