// ----------------------------- 0 ---------------------------
export const CLEAR_STATE = 'basic/CLEAR_STATE';
export const clearState = () => ({
  type: CLEAR_STATE
});

// ----------------------------- 1 - 0 NO REDEEM STATUS -------------------------------
export const GET_QR_CODE_DATA_NO_REDEEM = 'basic/GET_QR_CODE_DATA_NO_REDEEM';

export const getQrCodeDataNoRedeem = (qrcode, token) => {
  return {
    type: GET_QR_CODE_DATA_NO_REDEEM,
    payload: {
      qrcode,
      token
    }
  };
};


// ----------------------------- 1 -------------------------------
export const GET_QR_CODE_DATA = 'basic/GET_QR_CODE_DATA';
export const GET_QR_CODE_DATA_SUCCESS = 'basic/GET_QR_CODE_DATA_SUCCESS';
export const GET_QR_CODE_DATA_ERROR = 'basic/GET_QR_CODE_DATA_ERROR';

export const getQrCodeData = (qrcode, token, accessCode) => {
  return {
    type: GET_QR_CODE_DATA,
    payload: {
      qrcode,
      token,
      accessCode
    }
  };
};

export const getQrCodeDataSuccess = (data) => {
  return {
    type: GET_QR_CODE_DATA_SUCCESS,
    payload: data
  };
};

export const getQrCodeDataError = (error) => {
  return {
    type: GET_QR_CODE_DATA_ERROR,
    payload: error
  };
};

// ----------------------------- 2 -------------------------------
export const GENERATE_CONTRACT = 'basic/GENERATE_CONTRACT';
export const GENERATE_CONTRACT_SUCCESS = 'basic/GENERATE_CONTRACT_SUCCESS';
export const GENERATE_CONTRACT_ERROR = 'basic/GENERATE_CONTRACT_ERROR';

export const generateContract = (data, token) => {
  return {
    type: GENERATE_CONTRACT,
    payload: {
      data,
      token
    }
  };
};

export const generateContractSuccess = (data) => {
  return {
    type: GENERATE_CONTRACT_SUCCESS,
    payload: data
  };
};

export const generateContractError = (error) => {
  return {
    type: GENERATE_CONTRACT_ERROR,
    payload: error
  };
};

// ----------------------------- 2.1 -------------------------------
export const GENERATE_CONTRACT_APPROVAL = 'basic/GENERATE_CONTRACT_APPROVAL';
export const GENERATE_CONTRACT_APPROVAL_SUCCESS = 'basic/GENERATE_CONTRACT_APPROVAL_SUCCESS';
export const GENERATE_CONTRACT_APPROVAL_ERROR = 'basic/GENERATE_CONTRACT_APPROVAL_ERROR';

export const generateContractApproval = (data, token) => {
  return {
    type: GENERATE_CONTRACT_APPROVAL,
    payload: {
      data,
      token
    }
  };
};

export const generateContractApprovalSuccess = (data) => {
  return {
    type: GENERATE_CONTRACT_APPROVAL_SUCCESS,
    payload: data
  };
};

export const generateContractApprovalError = (error) => {
  return {
    type: GENERATE_CONTRACT_APPROVAL_ERROR,
    payload: error
  };
};

// ------------------  3 ----------------------------
export const INITIATE_TRANSACTION_TO_CLIENT_WALLET = 'basic/INITIATE_TRANSACTION_TO_CLIENT_WALLET';
export const INITIATE_TRANSACTION_TO_CLIENT_WALLET_SUCCESS = 'basic/INITIATE_TRANSACTION_TO_CLIENT_WALLET_SUCCESS';
export const INITIATE_TRANSACTION_TO_CLIENT_WALLET_ERROR = 'basic/INITIATE_TRANSACTION_TO_CLIENT_WALLET_ERROR';

export const initiateTransactionToClientWallet = (walletQrId, token, accessCode, userLang) => {
  return {
    type: INITIATE_TRANSACTION_TO_CLIENT_WALLET,
    payload: {
      qrcode: walletQrId,
      token,
      accessCode,
      userLang
    }
  };
};

export const initiateTransactionToClientWalletSuccess = (data) => {
  return {
    type: INITIATE_TRANSACTION_TO_CLIENT_WALLET_SUCCESS,
    payload: data
  };
};

export const initiateTransactionToClientWalletError = (error) => {
  return {
    type: INITIATE_TRANSACTION_TO_CLIENT_WALLET_ERROR,
    payload: error
  };
};

// ------------------  4 ----------------------------
export const GENERATE_QR_CODE_IN_SYSTEM = 'basic/GENERATE_QR_CODE_IN_SYSTEM';
export const GENERATE_QR_CODE_IN_SYSTEM_SUCCESS = 'basic/GENERATE_QR_CODE_IN_SYSTEM_SUCCESS';
export const GENERATE_QR_CODE_IN_SYSTEM_ERROR = 'basic/GENERATE_QR_CODE_IN_SYSTEM_ERROR';

export const generateQrCodeInSystem = ({ dataForm, token }) => {
  return {
    type: GENERATE_QR_CODE_IN_SYSTEM,
    payload: {
      dataForm,
      token
    }
  };
};

export const generateQrCodeInSystemSuccess = (data) => {
  return {
    type: GENERATE_QR_CODE_IN_SYSTEM_SUCCESS,
    payload: data
  };
};

export const generateQrCodeInSystemError = (error) => {
  return {
    type: GENERATE_QR_CODE_IN_SYSTEM_ERROR,
    payload: error
  };
};


// ------------------  6 Update Qr Code Text  ----------------------------
export const UPDATE_QR_CODE_TEXT = 'basic/UPDATE_QR_CODE_TEXT';
export const UPDATE_QR_CODE_TEXT_SUCCESS = 'basic/UPDATE_QR_CODE_TEXT_SUCCESS';
export const UPDATE_QR_CODE_TEXT_ERROR = 'basic/UPDATE_QR_CODE_TEXT_ERROR';

export const updateQrCodeText = (data) => {
  return {
    type: UPDATE_QR_CODE_TEXT,
    payload: data
  };
};

export const updateQrCodeTextSuccess = (data) => {
  return {
    type: UPDATE_QR_CODE_TEXT_SUCCESS,
    payload: data
  };
};

export const updateQrCodeTextError = (error) => {
  return {
    type: UPDATE_QR_CODE_TEXT_ERROR,
    payload: error
  };
};

// ------------------  6 Update Qr Code Text  ----------------------------
export const UPDATE_QR_CODE_URL = 'basic/UPDATE_QR_CODE_URL';

export const updateQrCodeUrl = (data) => {
  return {
    type: UPDATE_QR_CODE_URL,
    payload: data
  };
};
// ------------------  7 URL FORWARDING  ----------------------------
export const UPDATE_QR_CODE_FORWARD = 'basic/UPDATE_QR_CODE_FORWARD';

export const updateQrCodeUrlForward = (data) => {
  return {
    type: UPDATE_QR_CODE_FORWARD,
    payload: data
  };
};

// ------------------  8 STORY  ----------------------------
export const UPDATE_NFT_STORY = 'basic/UPDATE_NFT_STORY';

export const updateNftStory = (data) => {
  return {
    type: UPDATE_NFT_STORY,
    payload: data
  };
};

// ------------------  9 STORY  ----------------------------
export const GET_QR_CODE_FROM_ID = 'basic/GET_QR_CODE_FROM_ID';

export const getQrCodeFromId = (idcode) => {
  return {
    type: GET_QR_CODE_FROM_ID,
    payload: idcode
  };
};


// ------------------  10 ----------------------------
export const GENERATE_NFT_FROM_EXITING_QR_CODE = 'basic/GENERATE_NFT_FROM_EXITING_QR_CODE';
export const GENERATE_NFT_FROM_EXITING_QR_CODE_SUCCESS = 'basic/GENERATE_NFT_FROM_EXITING_QR_CODE_SUCCESS';
export const GENERATE_NFT_FROM_EXITING_QR_CODE_ERROR = 'basic/GENERATE_NFT_FROM_EXITING_QR_CODE_ERROR';

export const generateNftFromExistingQrCode = ({ dataForm, token }) => {
  return {
    type: GENERATE_NFT_FROM_EXITING_QR_CODE,
    payload: {
      dataForm,
      token
    }
  };
};

export const generateNftFromExistingQrCodeSuccess = (data) => {
  return {
    type: GENERATE_NFT_FROM_EXITING_QR_CODE_SUCCESS,
    payload: data
  };
};

export const generateNftFromExistingQrCodeError = (error) => {
  return {
    type: GENERATE_NFT_FROM_EXITING_QR_CODE_ERROR,
    payload: error
  };
};


