import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateNoUserData } from '../../redux/user/actions';
import { objIsEmpty } from '../../common/commonFunctions';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import RegisterUser from '../../components/RegisterUser';

function RegisterPage() {

  const { transactionUserData } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const dispatchToClearRedux = useCallback(() => {
    stableDispatch(clearStateNoUserData());
  }, [stableDispatch]);

  useEffect(() => {
    (objIsEmpty(transactionUserData) || !token) ? dispatchToClearRedux() : '';
  }, [dispatchToClearRedux]);


  return (
    <>
      <WrapperPage>
        <div className='blueTransparentWrapper'>
          <WrapperContainer>
            <RegisterUser />
          </WrapperContainer>
        </div>
      </WrapperPage>
    </>
  );
}

export default RegisterPage;
