import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function ShowLoader({color}) {
  let colorMain = (color) ? color : 'primary';
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color={colorMain} />
    </Box>
  );
}

export default ShowLoader;
