const userInfo = {
  remainingNumberCoupons: 0,
  remainingNumberQrCodes: 0,
  addingQrCodes: 'Pošaljite nam email, kako bi dodali QR kodove na Vaš nalog',
  addingCoupons: 'Pošaljite nam email, kako bi dodali kupone na Vaš nalog'
};

module.exports = {
  userInfo
};
