import { combineReducers } from 'redux';

import basicReducer from './basic/reducer';
import userReducer from './user/reducer';
import tokenReducer from './token/reducer';
import treeReducer from './tree/reducer';

const rootReducer = combineReducers({
  basic: basicReducer,
  user: userReducer,
  token: tokenReducer, 
  tree: treeReducer
});

export default rootReducer;
