import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import WrapperPage from '../../components/WrapperPage';
import BasicModal from '../../view/BasicModal';



function PricelistPage() {
        const [open, setOpen] = React.useState(false);

        const [textTitleToDisplay, setTextTitleToDisplay] = React.useState('');
        const [textBodyToDisplay, setTextBodyToDisplay] = React.useState('');

        const handleTextTitleToDisplay = (value) => {
                setTextTitleToDisplay(value);
        };
        const handleTextBodyToDisplay = (value) => {
                setTextBodyToDisplay(value);
        };
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);
        return (
                <>
                        <WrapperPage>
                                <WrapperContainer>
                                        <BasicModal open={open} handleClose={handleClose}
                                                textTitleToDisplay={textTitleToDisplay}
                                                textBodyToDisplay={textBodyToDisplay} />
                                        <Typography variant="h5" align="center" sx={{ my: 4 }}>
                                                Cenovnik QR kodova
                                        </Typography>
                                        <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                                <TableRow>
                                                                        <TableCell>Naziv paketa</TableCell>
                                                                        <TableCell align="left">Broj QR kodova u paketu</TableCell>
                                                                        <TableCell align="left">Cena</TableCell>

                                                                </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                                <TableRow>
                                                                        <TableCell>SingleQR</TableCell>
                                                                        <TableCell align="left">1</TableCell>
                                                                        <TableCell align="left">$0.98</TableCell>
                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell>StarterQR</TableCell>
                                                                        <TableCell align="left">5</TableCell>
                                                                        <TableCell align="left">$3.8</TableCell>
                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell>IntermediateQR</TableCell>
                                                                        <TableCell align="left">10</TableCell>
                                                                        <TableCell align="left">$5.8</TableCell>
                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell>AdvancedQR</TableCell>
                                                                        <TableCell align="left">20</TableCell>
                                                                        <TableCell align="left">$7.8</TableCell>
                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell align="left">Niste pronašli odgovarajući paket za Vas? Slobodno nas kontaktirajte, a mi ćemo Vam izaći u susret u najkraćem roku.</TableCell>
                                                                        <TableCell></TableCell>

                                                                        <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                                onClick={() => {
                                                                                        handleOpen();
                                                                                        handleTextTitleToDisplay('Dodavanje QR kodova');
                                                                                        handleTextBodyToDisplay('Za veće količine, pošaljite upit na service@blokaria.com');
                                                                                }}
                                                                        >
                                                                                Upit
                                                                        </Button></TableCell>
                                                                </TableRow>
                                                        </TableBody>

                                                </Table>
                                        </TableContainer>


                                        <Typography variant="h5" align="center" sx={{ my: 4 }}>
                                                Cenovnik kupona
                                        </Typography>
                                        <TableContainer>
                                                <Table aria-label="simple table">
                                                        <TableHead>
                                                                <TableRow>
                                                                        <TableCell>Naziv paketa</TableCell>
                                                                        <TableCell align="left">Broj kupona u paketu</TableCell>
                                                                        <TableCell align="left">Cena</TableCell>

                                                                </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                                <TableRow>
                                                                        <TableCell>SingleCoupon</TableCell>
                                                                        <TableCell align="left">1</TableCell>
                                                                        <TableCell align="left">$3.48</TableCell>
                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell>StarterCoupon</TableCell>
                                                                        <TableCell align="left">5</TableCell>
                                                                        <TableCell align="left">$16.95</TableCell>
                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell>IntermediateCoupon</TableCell>
                                                                        <TableCell align="left">10</TableCell>
                                                                        <TableCell align="left">$28.2</TableCell>
                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell>AdvancedCoupon</TableCell>
                                                                        <TableCell align="left">20</TableCell>
                                                                        <TableCell align="left">$45.2</TableCell>

                                                                        {/* <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                        // onClick={() => {
                                                                        //         handleOpen();
                                                                        //         handleTextTitleToDisplay('Dodavanje kupona');
                                                                        //         handleTextBodyToDisplay(userInfo.addingCoupons);
                                                                        // }}
                                                                        >
                                                                                Kupi
                                                                        </Button></TableCell> */}
                                                                </TableRow>
                                                                <TableRow>
                                                                        <TableCell align="left">Niste pronašli odgovarajući paket za Vas? Slobodno nas kontaktirajte, a mi ćemo Vam izaći u susret u najkraćem roku.</TableCell>

                                                                        <TableCell></TableCell>
                                                                        <TableCell align="left"><Button
                                                                                color='secondary'
                                                                                variant='contained'
                                                                                onClick={() => {
                                                                                        handleOpen();
                                                                                        handleTextTitleToDisplay('Dodavanje kupona');
                                                                                        handleTextBodyToDisplay('Za veće količine, pošaljite upit na service@blokaria.com');
                                                                                }}
                                                                        >
                                                                                Upit
                                                                        </Button></TableCell>

                                                                </TableRow>
                                                        </TableBody>

                                                </Table>
                                        </TableContainer>
                                </WrapperContainer>
                        </WrapperPage>
                </>
        );
}

export default PricelistPage;

