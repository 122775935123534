import React, { useCallback } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPasswordCode } from '../../redux/user/actions';
import ShowLoader from '../../view/ShowLoader';
import ShowError from '../../view/ShowError';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');


const validationSchema = yup.object({
  password: yup.string('Unesite lozinku').min(8, 'Minimalno 8 karaktera').required('Obavezno polje!'),
  confirmPassword: yup
    .string()
    .required('Obavezno!')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Obe lozinke moraju biti iste!')
    })
});

function ResetPasswordComponent() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email, clearPassword } = useParams();

  const { loading, loaded, errorMessage, internalErrorCode, resetPasswordData } = useSelector((state) => state.user);

  const stableDispatch = useCallback(dispatch, [dispatch]);

  const formResetPassDipatch = useCallback(
    (dataForm) => {
      stableDispatch(resetPasswordCode(dataForm));
    },
    [stableDispatch]
  );
  const goToLoginPage = () => {
    navigate('/log-in-user');
  };
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let dataForm = {
        userEmail: email,
        userPassword: values.password,
        clearPassword: clearPassword
      };
      console.log('dataForm', dataForm);

      formResetPassDipatch(dataForm);
    }
  });

  let showData = '';
  if (loading) {
    showData = <ShowLoader />;
  } else if (loaded && _.isEmpty(resetPasswordData)) {
    showData = <FormBoxResetPass t={t} formik={formik} />;
  } else if (errorMessage && internalErrorCode) {
    showData = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
  } else {
    showData = <PasswordSuccessfulyRest t={t} goToLoginPage={goToLoginPage} />;
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 5 }}>
      <CssBaseline />
      {showData}
    </Container>
  );
}

const PasswordSuccessfulyRest = ({ t, goToLoginPage }) => {
  return (
    <>
      <Typography variant="h5" align="center" sx={{ mt: 3, mb: 2 }}>
        {t('logInWithNewParameters')}
      </Typography>

      <Button type="button" onClick={goToLoginPage} fullWidth color="primary" variant="contained" sx={{ mt: 3, mb: 2 }}>
        {t('goToLogInPage')}
      </Button>
    </>
  );
};

const FormBoxResetPass = ({ formik, t }) => {
  return (
    <>
      <Typography variant="h5" align="center" sx={{ mt: 3, mb: 2 }}>
        {t('resetPassword')}
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off" sx={{ mt: 1, width: '100%' }}>
        <TextField
          fullWidth
          focused
          margin="normal"
          type="password"
          id="password"
          name="password"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <TextField
          fullWidth
          focused
          type="password"
          margin="normal"
          id="confirmPassword"
          name="confirmPassword"
          label={t('verify-password')}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />

        <Button type="submit" fullWidth color="primary" variant="contained" sx={{ mt: 3, mb: 2 }}>
          {t('resetPassword')}
        </Button>
      </Box>
    </>
  );
};

export default ResetPasswordComponent;
