import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//import Chart from 'react-google-charts';
import LinkHref from '../../components/LinkHref';
// https://github.com/orgs/github/projects/4247/views/1


// function daysToMilliseconds(days) {
//     return days * 24 * 60 * 60 * 1000;
// }
// const columns = [
//     { type: 'string', label: 'Task ID' },
//     { type: 'string', label: 'Task Name' },
//     { type: 'date', label: 'Start Date' },
//     { type: 'date', label: 'End Date' },
//     { type: 'number', label: 'Duration' },
//     { type: 'number', label: 'Percent Complete' },
//     { type: 'string', label: 'Dependencies' }
// ];

// const rows = [
//     [
//         'Research',
//         'Find sources',
//         new Date(2015, 0, 1),
//         new Date(2015, 0, 5),
//         null,
//         100,
//         null
//     ],
//     [
//         'Write',
//         'Write paper',
//         null,
//         new Date(2015, 0, 9),
//         daysToMilliseconds(3),
//         25,
//         'Research,Outline'
//     ],
//     [
//         'Cite',
//         'Create bibliography',
//         null,
//         new Date(2015, 0, 7),
//         daysToMilliseconds(1),
//         20,
//         'Research'
//     ],
//     [
//         'Complete',
//         'Hand in paper',
//         null,
//         new Date(2015, 0, 10),
//         daysToMilliseconds(1),
//         0,
//         'Cite,Write'
//     ],
//     [
//         'Outline',
//         'Outline paper',
//         null,
//         new Date(2015, 0, 6),
//         daysToMilliseconds(1),
//         100,
//         'Research'
//     ]
// ];

function RoadmapView() {

    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <Typography variant="h5" align="center" sx={{ mb: 5 }}>
                 RoadMap - Phase 1 and 2
            </Typography>
            <Box align="left" sx={{ mb: 5 }}>
                Faza 1 - MVP
                <ul>
                    <li>Definisanje Scope-a posla</li>
                    <li>Pustanje u rad Test-Net Okruzenja</li>
                    <li>Pustanje u rad Main-Net Okruzenja</li>
                    <li>PR i Marketing</li>
                </ul>
            </Box>
            <Box variant="root" align="left" sx={{ mb: 5 }}>
                Faza 2 - NFT
                <ul>
                    <li>Define revenue streams</li>
                    <li>Kreiranje character story-a za 
                        <LinkHref sx={{ color: 'orange' }} to="/doc/nft-character">
                            {' '} NFT {' '}
                        </LinkHref>
                    </li>
                    <li>Update sajta sa kreiranjem NFT-a za svaku akciju</li>
                    <li>Mintovanje NFT-a na Cardanu i Solani</li>
                    <li>Postavljanje NFT-a na marketplaces</li>
                    <li>Bildovanje NFT-a na Marketu</li>
                </ul>
            </Box>

            {/* <Chart
                chartType="Gantt"
                data={[columns, ...rows]}
                width="100%"
                height="50%"
                legendToggle
                            // options={{
                            //     title: 'Some Values',
                            //     backgroundColor: {
                            //         fill: '#E4E4E4'
                            //     }
                            //   }}
            /> */}

        </Container>
    );
}

export default RoadmapView;