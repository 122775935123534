export const SET_TOKEN = 'token/SET_TOKEN';
export const UNSET_TOKEN = 'token/UNSET_TOKEN';
export const CLEAR_SET_TOKEN = 'token/CLEAR_SET_TOKEN';

export const clearSetToken = () => ({
  type: CLEAR_SET_TOKEN
});

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token
  };
};

export const unsetToken = () => {
  return {
    type: UNSET_TOKEN
  };
};
