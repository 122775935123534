import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import VerifyContractWelcome from '../../view/VerifyContractWelcome';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import VerifyContractComponent from '../../components/VerifyContractComponent';
import BreadcrumbsView from '../../view/BreadcrumbsView';

function VerifyContract() {
  return (
    <>
      <WrapperPage>
        <div className='blueTransparentWrapper'>
          <WrapperContainer>
            <BreadcrumbsView />
            <VerifyContractWelcome />
            <VerifyContractComponent />
          </WrapperContainer>
        </div>
      </WrapperPage>
    </>
  );
}

export default VerifyContract;
