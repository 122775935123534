import React from 'react';
import { Box, Container } from '@mui/material';
import { getDataFieldFromNftObj } from '../../common/commonFunctions';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import '../../css/general.css';

function NftStoryComponent({ insertedQrCodeData, showPic = true }) {

    let text = getDataFieldFromNftObj(insertedQrCodeData, 'nftStory');

    return (
        <Container maxWidth="lg" >
            {(showPic) ? <ShowPic insertedQrCodeData={insertedQrCodeData} /> : ''}

            <Box noValidate sx={{ mt: 1, mb: 1, pb: 2, 'marginRight': '5px' }}>
                <Typography className="changeAhrefColor" sx={{ mt: 5, mb: 1 }} dangerouslySetInnerHTML={{ __html: text }}>
                </Typography>
            </Box>
        </Container>
    );
}

const ShowPic = ({ insertedQrCodeData }) => {
    return (<Box sx={{ mt: 1, mb: 1, pb: 2, float: 'left' }}>
        <Link
            target="_blank"
            rel="noreferrer noopener"
            href={`https://ipfs.io/ipfs/${insertedQrCodeData._nfts[0].cid}`}
            underline="none"
            color="info.light"
            sx={{ my: 1 }}
        >
            <img src={`https://ipfs.io/ipfs/${insertedQrCodeData._nfts[0].cid}`} width="50%" />
        </Link>
    </Box>);
};

export default NftStoryComponent;

