import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import GenerateContractComponent from '../../components/GenerateContractComponent';

function GenerateContract() {
  return (
    <>
      <WrapperPage>
        <div className='blueTransparentWrapper'>
          <WrapperContainer>
            <GenerateContractComponent />
          </WrapperContainer>
        </div>
      </WrapperPage>
    </>
  );
}

export default GenerateContract;
