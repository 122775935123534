import React, { useCallback, useEffect } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logInUser } from '../../redux/user/actions';
import ShowLoader from '../../view/ShowLoader';
import ShowError from '../../view/ShowError';
import { objHasValues } from '../../common/commonFunctions';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: yup.string('Enter password').min(8, 'Min 8 Characters Required').required('Required')
});

function LogInUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, errorMessage, internalErrorCode, transactionUserData, loaded } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.token);

  // startFresh, transactionUserData, errorMessage, internalErrorCode,
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const formLogInUserDipatch = useCallback(
    (dataForm) => {
      stableDispatch(logInUser(dataForm));
    },
    [stableDispatch]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let dataForm = {
        userEmail: values.email,
        userPassword: values.password
      };

      formLogInUserDipatch(dataForm);
    }
  });

  useEffect(() => {
    if (loaded && token && objHasValues(transactionUserData)) {
      navigate('/');
    }
  }, [token, transactionUserData]);

  let showData, showErrorMessage;
  if (loading) {
    showData = <ShowLoader />;
  } else {
    showData = <FormBox t={t} formik={formik} />;
  }

  if (errorMessage) { //  && internalErrorCode removed because some errors has no internal value
    showErrorMessage = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
  }


  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 5 }}>
      <CssBaseline />
      {showErrorMessage}
      {showData}
    </Container>
  );
}
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'orange'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'orange'
      }
    }
  }
})(TextField);

const FormBox = ({ formik, t }) => {
  return (
    <>
      <div className='loginPageCentralDiv'>
        <Typography variant="h3" align="center" sx={{ mt: 3, mb: 2, fontFamily: 'Roboto,sans-serif' }} className='animate__animated animate__slideInDown'>
          {t('login')}
        </Typography>
        <Box component="form" className='animate__animated animate__bounceInLeft MuiInputBase-input' onSubmit={formik.handleSubmit} noValidate autoComplete="off" sx={{ mt: 1, width: '100%', fontSize: '35px !important' }}>


          <CssTextField
            fullWidth
            margin="normal"
            id="email"
            name="email"
            size='medium'
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            style={{ paddingTop: '15px' }}
            className='MuiOutlinedInput-root.Mui-focused MuiOutlinedInput-notchedOutline'
          />

          <CssTextField
            fullWidth
            margin="normal"
            type='password'
            id="password"
            name="password"
            label={t('password')}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            className='animate__animated animate__bounceInRight'
            style={{ paddingTop: '15px' }}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: (theme) => theme.orangeButton.button }}>
            {t('login')}
          </Button>
        </Box>
      </div>
    </>
  );
};

export default LogInUser;
