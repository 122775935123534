import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { checkIfNftExist } from '../../common/commonFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { updateQrCodeText, updateQrCodeUrl } from '../../redux/basic/actions';
import LinkHref from '../LinkHref';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { paragraphStyle, formatBlock, image, link, table } from 'suneditor/src/plugins';
import { useTranslation } from 'react-i18next';

// TODO mihajlo - videti koju validaciju dodati
// TODO - treba definisati ko moze da edituje artikal
// kreator dok nije asajnovan na korisnika
// korisnik koji je kupio NFT od kreatora
const validationSchema = yup.object({
    //projectName: yup.string('Unesi ime projekta').max(60, 'Ime može imate maksimalno 60 karaktera').required('Ime projekta je obavezno!')
});
const validationSchemaUrl = yup.object({
    //projectName: yup.string('Unesi ime projekta').max(60, 'Ime može imate maksimalno 60 karaktera').required('Ime projekta je obavezno!')
});

const EditQrCodePageComponent = ({ qrcode, insertedQrCodeData, urlForwardingState, handleChangeUrl, openSuccessDialogValue }) => {
    console.log(' > EditQrCodePageComponent urlForwardingState ', urlForwardingState);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.token);
    const dispatchUpdateQrCode = useCallback(
        (dataForm) => {
            dispatch(updateQrCodeText(dataForm));
        }
    );

    const dispatchUpdateQrCodeUrl = useCallback(
        (dataForm) => {
            dispatch(updateQrCodeUrl(dataForm));
        }
    );

    // useEffect(() => {
    //     console.log('usao u useEff loading ', loading, ' loaded ', loaded, ' buttonClicked ', buttonClicked, ' openDialog ', openDialog);
    //     if (buttonClicked) {
    //         setPeraDialog('fiki');
    //         setOpenDialog(true);
    //         console.log('usao u open dialog', openDialog);
    //         // setButtonClicked(false);
    //     }
    // }, [buttonClicked, peraDialog]);

    const formik = useFormik({
        initialValues: {
            longText: insertedQrCodeData.longText
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                let dataForm = {
                    qrcode,
                    token,
                    longText: values.longText
                };
                dispatchUpdateQrCode(dataForm);
                openSuccessDialogValue(true);
            } catch (error) {
                console.log(error);
                return error;
            }
        }
    });

    let checkNftExist = checkIfNftExist(insertedQrCodeData);


    const formikUrl = useFormik({
        initialValues: {
            nftlocation: (checkNftExist && insertedQrCodeData?._nfts[0]?.nftlocation) ? insertedQrCodeData?._nfts[0]?.nftlocation : ''
        },
        validationSchema: validationSchemaUrl,
        onSubmit: async (values) => {
            try {
                let dataForm = {
                    qrcode,
                    token,
                    nftlocation: values.nftlocation
                };
                dispatchUpdateQrCodeUrl(dataForm);
                openSuccessDialogValue(true);
            } catch (error) {
                return console.log(error);
            }
        }
    });
    return (
        <React.Fragment >
            <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
                <LinkHref sx={{
                    color: (theme) => theme.linkHref.linkWhite
                }} to={`/status/${qrcode}`}>
                    {insertedQrCodeData.productName}
                </LinkHref>
            </Typography>
            <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
                Add marketplace Url
            </Typography>
            <UpdateNftData
                t={t}
                formikUrl={formikUrl}
                qrcode={qrcode}
                handleChangeUrl={handleChangeUrl}
                urlForwardingState={urlForwardingState}
            />

            <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
                Update Text
            </Typography>
            <div className='blueTransparentWrapper' style={{ marginLeft: 0, marginRight: 0 }}>
                <Box sx={{
                    p: 1
                }}>

                    <Box component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off" sx={{ mt: 1, width: '100%' }}>
                        <SunEditor
                            autoFocus={true}
                            width="100%"
                            height="50vh"
                            id="longText"
                            name="longText"
                            label="Long Description"
                            multiline

                            setOptions={{
                                plugins: [
                                    formatBlock,
                                    paragraphStyle,
                                    image,
                                    link,
                                    table
                                ],
                                buttonList: [
                                    // default
                                    ['undo', 'redo'],
                                    ['paragraphStyle', 'formatBlock'],
                                    ['bold', 'underline', 'italic', 'list'],
                                    ['table', 'link', 'image'],
                                    ['fullScreen']
                                ]

                            }}
                            setDefaultStyle="font-family: Roboto; font-size: 16px;"
                            setContents={formik.values.longText}
                            onChange={(e) => {
                                formik.setFieldValue('longText', e);
                            }}
                            error={formik.touched.longText && Boolean(formik.errors.longText)}
                            helperText={formik.touched.longText && formik.errors.longText}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: (theme) => theme.orangeButton.button }}>
                            {t('updateText')}
                        </Button>
                    </Box>
                </Box>
            </div>
        </React.Fragment >
    );
};

const UpdateNftData = ({ t, formikUrl, qrcode, handleChangeUrl, urlForwardingState }) => {
    return (<Card variant="outlined" sx={{
        p: 1,
        backgroundColor: (theme) => theme.palette.background.blueTransparent
    }}>

        <Box component="form" onSubmit={formikUrl.handleSubmit} color='white' noValidate autoComplete="off" sx={{ mt: 1 }}>
            <Typography color='white'>
                {t('addUrlLocation')}
            </Typography>
            <Typography color='white'>
                {t('visibleOnThePage')}
            </Typography>
            <Typography>
                <LinkHref sx={{ color: (theme) => theme.linkHref.link }} to={`/status/${qrcode}`}>
                    {`${process.env.REACT_APP_WEB_SITE}/status/${qrcode}`}
                </LinkHref>
            </Typography>

            <TextField
                fullWidth
                margin="normal"
                id="nftlocation"
                name="nftlocation"
                label="Nft Location"
                value={formikUrl.values.nftlocation}
                onChange={(e) => {
                    formikUrl.handleChange(e);
                }}
                error={formikUrl.touched.nftlocation && Boolean(formikUrl.errors.nftlocation)}
                helperText={formikUrl.touched.nftlocation && formikUrl.errors.nftlocation}
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: (theme) => theme.orangeButton.button }}>
                {t('addNftLocation')}
            </Button>

            <FormGroup sx={{ mt: 4 }}>
                <FormControlLabel control={<Checkbox
                    id="forwardurl"
                    name="forwardurl"
                    onChange={(e) => {
                        handleChangeUrl(e);
                    }}
                    checked={urlForwardingState}
                />} label="Enable URL forwarding" />

                <Typography color='white'>
                    {t('bySelectingUrlForwarding')}
                </Typography>
                <Typography align='center' sx={{ mt: 2 }}>
                    <LinkHref sx={{ color: (theme) => theme.linkHref.link }} to={`/status/${qrcode}`}>
                        {`${process.env.REACT_APP_WEB_SITE}/status/${qrcode}`}
                    </LinkHref>
                </Typography>
                <Typography align='center'>
                    <ArrowForwardIcon color="secondary" sx={{
                        transform: 'rotate(90deg)'
                    }} />
                </Typography>

                <Typography align='center'>
                    {formikUrl.values.nftlocation}
                </Typography>


                <Typography align='center' sx={{ mt: 2 }}>
                    {t('whichMeansClientWillScan')}
                </Typography>

            </FormGroup>
        </Box>
    </Card>);
};

export default EditQrCodePageComponent;
