import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
//import Box from '@mui/material/Box';
//import LinkHref from '../../components/LinkHref';
import '../../css/general.css';
function BusinessCaseView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <Typography variant="h5" align="center" sx={{ mb: 5 }}>
                 Business Case
            </Typography>
            <Typography variant="h6" align="left" sx={{ }}>
                Opis - Radovi u toku
            </Typography>

            {/* <Typography variant="h6" align="left" sx={{ }}>
                Project Name
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2, mt: 1 }}>
                Blokaria - place for proving and verifying uniqueness of the products
            </Typography>

            <Typography variant="h6" align="left" sx={{ }}>
                Project Sponsor
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2, mt: 1 }}>
               -
            </Typography>


            <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                Executive Summary
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
               -
            </Typography>

            <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                Benefits
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
               -
            </Typography>

            <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                Costs
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
               -
            </Typography>

            <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                Risks
            </Typography>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
               -
            </Typography>

         
            <Box className="turquoiseBox" sx={{ mb: 2, p: 2 }}>
                <Typography variant="h6" align="left" sx={{}}>
                    Ciljne grupe : Proizvođači jednistvenih proizvoda, kompanije i udruzenja gradjana
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    Proizvođač jedinstvenih proizvoda - preduzetnik/kreator : Kreator proizvoda potvrđuje autentičnost
                    proizvoda QR kodom koji &quot;vezuje&quot; za proizvod.
                    Primer možete pogledati na ovom
                    <LinkHref sx={{ color: 'yellow' }} to="/doc/use-case-product">
                        {' '} linku
                    </LinkHref>
                </Typography>
            </Box>
            <Typography variant="body1" align="left" sx={{ mb: 2 }}>
               -
            </Typography>


            <Box align="left" sx={{ mb: 2 }}>
             -
            </Box> */}

        </Container>
    );
}

export default BusinessCaseView;