import React, { useCallback, useEffect } from 'react';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { clearStateUser } from '../../redux/user/actions';
import {
    listAllProject, addQrCodeToProject,
    changeStatusOfQrCode,
    getListQrCodesByUserPrivate
} from '../../redux/tree/actions';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// import PublicIcon from '@mui/icons-material/Public';
// import VpnLockIcon from '@mui/icons-material/VpnLock';
import { Link as RouterLink } from 'react-router-dom';
import ShowLoader from '../../view/ShowLoader';
import ShowError from '../../view/ShowError';
import ShowSuccess from '../../view/ShowSuccess';
import Tooltip from '@mui/material/Tooltip';
import LinkHref from '../LinkHref';
import DialogView from '../../view/DialogView';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { checkIfNftExist, checkIfNftValueIsTrue } from '../../common/commonFunctions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SelectProjectView from '../../view/SelectProjectView';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
const _ = require('lodash');
import { useTranslation } from 'react-i18next';


function UserListOwnedQrCodesComponent() {

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    //const [getWalletQrId, setWalletQrId] = React.useState('');
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.token);
    const { transactionUserData } = useSelector((state) => state.user);
    const { listUserProjects, listOfQrCodeOwnedByUser, errorMessage, internalErrorCode, loading, loaded } = useSelector((state) => state.tree);

    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);


    // const handleDeleteQrCode = (value) => {
    //     setOpen(true);
    //     setWalletQrId(value);
    // };

    // const dispatchToDeleteQrCode = useCallback((value) => {
    //     stableDispatch(deleteQrCode(value, token));
    // }, [stableDispatch]);

    const handleClose = () => {
        setOpen(false);
        //(value) ? dispatchToDeleteQrCode(getWalletQrId) : '';
    };

    const handleEditQrCode = (value) => {
        navigate(`/editqrcode/${value}`);
    };

    const getListOfProjects = useCallback((token) => {
        dispatch(listAllProject(token));
    });

    useEffect(() => {
        getListOfProjects(token);
    }, []);


    const dispatchToChangeStatusQrCode = useCallback((qrcode, status, generated, token) => {
        dispatch(changeStatusOfQrCode(qrcode, status, generated, token));
    });

    const handleChangePublicQrCode = (qrcode) => (e) => {
        let status = (e.target.value === true) ? true : false;
        let generated = false;
        dispatchToChangeStatusQrCode(qrcode, status, generated, token);
    };

    let menuItems = [];
    if (listUserProjects && listUserProjects.length > 0) {
        menuItems = listUserProjects.map((item) => {
            return (<MenuItem key={item._id} value={item._id}>{item.projectName}</MenuItem>);
        });
    }

    let selectedComponent = (projectId, walletId) => {
        return (<SelectProjectView
            handleChangeProject={handleChangeProject}
            projectId={projectId}
            walletId={walletId}
            listUserProjects={listUserProjects}
            menuItems={menuItems}
        />);
    };

    // on Load
    const dispatchToClearRedux = useCallback((userEmail, token) => {
        let generated = false;
        (userEmail && token) ? stableDispatch(getListQrCodesByUserPrivate(token, generated)) : stableDispatch(clearStateUser());
    }, [stableDispatch]);

    let userEmail = transactionUserData?.userEmail;
    useEffect(() => {
        dispatchToClearRedux(userEmail, token);
    }, [dispatchToClearRedux]);

    const dispatchToAddQrCodeToProject = useCallback((itemId, projectId, token) => {
        dispatch(addQrCodeToProject(itemId, projectId, token));
    });



    const handleChangeProject = (itemId, e) => {
        console.log('ItemId : ', itemId);
        console.log('Target Id : ', e.target.value);
        let projectId = e.target.value;
        dispatchToAddQrCodeToProject(itemId, projectId, token);
    };

    let showData = '';
    if (loading) {
        showData = <ShowLoader />;
    } else if (errorMessage && internalErrorCode) {
        showData = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
        // } else if (loaded && _.isEmpty(listUserProjects) && listUserProjects.length < 1) {
        //     showData = <ShowError errorMessage={t('createAtLeastOneProject')} internalErrorCode="NoProjects" />;
    } else if (loaded && _.isEmpty(listOfQrCodeOwnedByUser)) {
        showData = <ShowSuccess message="No data" />;
    } else if (loaded && _.isArray(listOfQrCodeOwnedByUser) && _.isArray(listUserProjects)) {

        showData = <TableContainerWrapper
            t={t}
            listOfQrCodeOwnedByUser={listOfQrCodeOwnedByUser}
            listUserProjects={listUserProjects}
            handleChangeProject={handleChangeProject}
            handleEditQrCode={handleEditQrCode}
            handleChangePublicQrCode={handleChangePublicQrCode}
            selectedComponent={selectedComponent}

        />;

    } else {
        showData = <ShowLoader />;
    }

    return (
        <Container className="generateQrCodeWellcome" maxWidth="lg" sx={{ pt: 0, pb: 2 }}>
            <DialogView handleClose={handleClose} open={open} />
            {showData}
        </Container>
    );
}

const TableContainerWrapper = ({ t, selectedComponent, listOfQrCodeOwnedByUser,
    listUserProjects, handleEditQrCode,
    handleChangePublicQrCode }) => {

    let showTables = [];

    let showTablesOwnedUserQr = (listOfQrCodeOwnedByUser.length > 0) ? <TableContainerHeadUser
        t={t}
        title={t('ownedQrCodesList')}
        key="2"
        listOfQrCodeOwnedByUser={listOfQrCodeOwnedByUser}
        listUserProjects={listUserProjects}
        handleEditQrCode={handleEditQrCode}
        handleChangePublicQrCode={handleChangePublicQrCode}
        selectedComponent={selectedComponent}

    /> : '<div>...</div>';

    showTables.push(showTablesOwnedUserQr);
    return (showTables);
};

// ok
const TableContainerHeadUser = ({ t, listOfQrCodeOwnedByUser, title, handleEditQrCode,
    handleChangePublicQrCode, selectedComponent }) => {

    return (<>
        <Typography variant="h4" align="center" sx={{ my: 4, pt: 4 }}>
            {title}
        </Typography><TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('qrCodeName')}</TableCell>
                        <TableCell align="left">{t('status')}</TableCell>
                        <TableCell align="left">{t('nftStatus')}</TableCell>
                        <TableCell align="left">{t('forward')}</TableCell>
                        {/* {languages[lang].blockchainTxId} */}
                        <TableCell align="left">{t('access')}</TableCell>
                        <TableCell align="left">{t('projectName')}</TableCell>
                        <TableCell align="left">{t('hasStory')}</TableCell>
                        <TableCell align="left">{t('action')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBodyDataUser
                    t={t}
                    listOfQrCodeOwnedByUser={listOfQrCodeOwnedByUser}
                    handleEditQrCode={handleEditQrCode}
                    handleChangePublicQrCode={handleChangePublicQrCode}
                    selectedComponent={selectedComponent}
                />
            </Table>
        </TableContainer></>);
};

// ok
const TableBodyDataUser = ({ t, listOfQrCodeOwnedByUser, handleEditQrCode,
    handleChangePublicQrCode, selectedComponent }) => {

    if (listOfQrCodeOwnedByUser.length > 0) {
        return <TableBody>
            {listOfQrCodeOwnedByUser.map((item) => {

                let statusRedeemd = (item.qrCodeRedeemStatus) ?
                    <Tooltip title={t('productIsPurchased')} ><LockIcon color="warning" /></Tooltip> :
                    <Tooltip title={t('productIsAvalableForPurchase')}><LockOpenIcon color='success' /></Tooltip>;
                let nftRedeemStatus = (item.nftRedeemStatus) ?
                    <Tooltip title={t('nftAssettIsTransferred')}><LockIcon color="warning" /></Tooltip> :
                    <Tooltip title={t('nftAssettIsNotTransferred')}><LockOpenIcon color='success' /></Tooltip>;

                // let stateAccessible = (item.publicQrCode) ?
                //     <Tooltip title="Proizvod je javno dostupan za kupovinu"><PublicIcon color="success" /></Tooltip> :
                //     <Tooltip title="Proizvod je rezervisan za određene korisnike"><VpnLockIcon color="warning" /></Tooltip>;
                let forwardUrl = (checkIfNftValueIsTrue(item, 'urlforwarding')) ?
                    <Tooltip title={`${t('clientWillBeTransferredTo')} ${checkIfNftValueIsTrue(item, 'nftlocation')}`}><SwapHorizIcon color="success" /></Tooltip> :
                    '';

                let stateSelected = (item.qrCodeRedeemStatus) ? true : false;
                //let linkToCardanoExplorer = (item.transactionId) ? `${process.env.REACT_APP_TESTNET}${item.transactionId}` : '';
                //let linkToCardanoExplorerName = (item.transactionId) ? 'BlockChain Link' : '';

                return <TableRow
                    key={item._id}
                    selected={stateSelected}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                    }}
                >
                    <TableCell component="th" scope="row">
                        <Link
                            component={RouterLink}
                            to={`/status/${item.walletQrId}`}
                            //underline="none"
                            color="info.light"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            {item.productName}
                        </Link>
                    </TableCell>
                    <TableCell align="left">
                        <LinkHref to={`/generate-contract/${item.walletQrId}`}>
                            {statusRedeemd}
                        </LinkHref>
                    </TableCell>
                    <TableCell align="left">
                        {nftRedeemStatus}
                    </TableCell>
                    {/* <TableCell align="left">
                        <LinkHref to={`/generate-contract/${item.walletQrId}`}>
                            {stateAccessible}
                        </LinkHref>
                    </TableCell> */}
                    <TableCell align="left">
                        <Link variant="span" underline="none" color="secondary" target="_blank" rel="noreferrer noopener" href={checkIfNftValueIsTrue(item, 'nftlocation')}>
                            {forwardUrl}
                        </Link>
                    </TableCell>
                    <TableCell align="left">
                        <Select size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.publicQrCode}
                            label="Status"
                            sx={{ background: '#0277bd' }}
                            onChange={handleChangePublicQrCode(item.walletQrId)}
                        >
                            <MenuItem value={true}>{t('public')}</MenuItem>
                            <MenuItem value={false}>{t('private')}</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell align="left">
                        {selectedComponent(item._project, item._id)}
                    </TableCell>
                    <TableCell align="left">
                        {(item.hasstory && checkIfNftExist(item)) ? (<>
                            <Box>
                                <LinkHref to={`/story/${item._id}`}>
                                    {t('story')}
                                </LinkHref>
                            </Box>
                            <Box>
                                <LinkHref color={'orange'} to={`/editqrcodestory/${item.walletQrId}`}>
                                    {t('edit')}
                                </LinkHref>
                            </Box>
                        </>) : ''}
                    </TableCell>
                    <TableCell align="left">
                        <Button size="small" variant="outlined" sx={{ mt: 1, backgroundColor: (theme) => theme.orangeButton.button }} fullWidth onClick={() => handleEditQrCode(item.walletQrId)}>
                            {t('edit')}
                        </Button>
                    </TableCell>
                </TableRow>;
            })
            }
        </TableBody >;
    }
};

export default UserListOwnedQrCodesComponent;