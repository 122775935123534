import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import StatusWelcome from '../../view/StatusWelcome';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import StatusQrCodeComponent from '../../components/StatusQrCodeComponent';
import GoBackButton from '../../view/GoBackButton';
import { Container } from '@mui/system';

function StatusQrCode() {
  return (
    <>
      <WrapperPage>
        <WrapperContainer >
          <Container>
            <GoBackButton />
            <StatusWelcome />
            <StatusQrCodeComponent />
          </Container>
        </WrapperContainer>
      </WrapperPage>
    </>
  );
}

export default StatusQrCode;
