// ----------------------------- 0 ---------------------------
export const CLEAR_STATE_USER = 'user/CLEAR_STATE_USER';
export const clearStateUser = () => ({
  type: CLEAR_STATE_USER
});

export const CLEAR_STATE_USER_SUCCESS = 'user/CLEAR_STATE_USER_SUCCESS_STATE_USER';
export const clearStateUserSuccess = () => ({
  type: CLEAR_STATE_USER_SUCCESS
});
export const CLEAR_STATE_USER_ERROR = 'user/CLEAR_STATE_USER_ERROR';
export const clearStateUserError = () => ({
  type: CLEAR_STATE_USER_ERROR
});


export const CLEAR_STATE_NO_USER_DATA = 'user/CLEAR_STATE_NO_USER_DATA';
export const CLEAR_STATE_NO_USER_DATA_SUCCESS = 'user/CLEAR_STATE_NO_USER_DATA_SUCCESS';
export const CLEAR_STATE_NO_USER_DATA_ERROR = 'user/CLEAR_STATE_NO_USER_DATA_ERROR';
export const clearStateNoUserData = () => ({
  type: CLEAR_STATE_NO_USER_DATA
});
export const clearStateNoUserDataSuccess = () => {
  return {
    type: CLEAR_STATE_NO_USER_DATA_SUCCESS
  };
};

export const clearStateNoUserDataError = () => {
  return {
    type: CLEAR_STATE_NO_USER_DATA_ERROR
  };
};
// ----------------------------- 1 -------------------------------
export const REGISTER_USER = 'user/REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'user/REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'user/REGISTER_USER_ERROR';

export const registerUser = (dataForm) => {
  return {
    type: REGISTER_USER,
    payload: dataForm
  };
};

export const registerUserSuccess = (data) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: data
  };
};

export const registerUserError = (error) => {
  return {
    type: REGISTER_USER_ERROR,
    payload: error
  };
};

// -------------------- 2 ---------------------------

export const LOGIN_USER = 'user/LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'user/LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'user/LOGIN_USER_ERROR';

export const logInUser = (dataForm) => {
  return {
    type: LOGIN_USER,
    payload: dataForm
  };
};

export const logInUserSuccess = (data) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: data
  };
};

export const logInUserError = (error) => {
  return {
    type: LOGIN_USER_ERROR,
    payload: error
  };
};
// -------------------- 3 RESET PASSWORD ---------------------------

export const RESET_PASSWORD = 'user/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'user/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'user/RESET_PASSWORD_ERROR';

export const resetPassword = (dataForm) => {
  return {
    type: RESET_PASSWORD,
    payload: dataForm
  };
};

export const resetPasswordSuccess = (data) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data
  };
};

export const resetPasswordError = (error) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: error
  };
};
// -------------------- 4 RESET PASSWORD CODE ---------------------------

export const RESET_PASSWORD_CODE = 'user/RESET_PASSWORD_CODE';
export const RESET_PASSWORD_CODE_SUCCESS = 'user/RESET_PASSWORD_CODE_SUCCESS';
export const RESET_PASSWORD_CODE_ERROR = 'user/RESET_PASSWORD_CODE_ERROR';

export const resetPasswordCode = (dataForm) => {
  return {
    type: RESET_PASSWORD_CODE,
    payload: dataForm
  };
};

export const resetPasswordCodeSuccess = (data) => {
  return {
    type: RESET_PASSWORD_CODE_SUCCESS,
    payload: data
  };
};

export const resetPasswordCodeError = (error) => {
  return {
    type: RESET_PASSWORD_CODE_ERROR,
    payload: error
  };
};

// -------------------- 5 GET USER DATA ---------------------------

export const GET_USER_DATA = 'user/GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'user/GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_ERROR = 'user/GET_USER_DATA_ERROR';

export const getUserData = (userEmail, token) => {
  return {
    type: GET_USER_DATA,
    payload: {
      userEmail,
      token
    }
  };
};

export const getUserDataSuccess = (data) => {
  return {
    type: GET_USER_DATA_SUCCESS,
    payload: data
  };
};

export const getUserDataError = (error) => {
  return {
    type: GET_USER_DATA_ERROR,
    payload: error
  };
};

// -------------------- 6 SEND_APPROVAL_TO_CLIENT ---------------------------

export const SEND_APPROVAL_TO_CLIENT = 'user/SEND_APPROVAL_TO_CLIENT';
export const SEND_APPROVAL_TO_CLIENT_SUCCESS = 'user/SEND_APPROVAL_TO_CLIENT_SUCCESS';
export const SEND_APPROVAL_TO_CLIENT_ERROR = 'user/SEND_APPROVAL_TO_CLIENT_ERROR';

export const sendApprovalToClient = (qrcode, clientEmail, clientName) => {
  return {
    type: SEND_APPROVAL_TO_CLIENT,
    payload: {
      qrcode,
      clientEmail,
      clientName
    }
  };
};

export const sendApprovalToClientSuccess = (data) => {
  return {
    type: SEND_APPROVAL_TO_CLIENT_SUCCESS,
    payload: data
  };
};

export const sendApprovalToClientError = (error) => {
  return {
    type: SEND_APPROVAL_TO_CLIENT_ERROR,
    payload: error
  };
};


