import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import QrCoderPerProjectComponent from '../../components/QrCoderPerProjectComponent';

const QrCodesInProjectPage = () => {
    return (
        <WrapperPage>
          <WrapperContainer>
            <QrCoderPerProjectComponent />
          </WrapperContainer>
        </WrapperPage>
      );
};


export default QrCodesInProjectPage;