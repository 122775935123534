import React, { useCallback, useEffect, useState } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getQrCodeDataNoRedeem, updateNftStory } from '../../redux/basic/actions';
import { Box, Container, Typography } from '@mui/material';
import { checkIfNftExist, getDataFieldFromNftObj } from '../../common/commonFunctions';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { languages } from '../../languages';
import GoBackButton from '../../view/GoBackButton';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { paragraphStyle, formatBlock, image, link, table } from 'suneditor/src/plugins';
import SnackBarView from '../../view/SnackBarView';
// https://stackblitz.com/edit/react-sun-editor-sample?file=index.tsx


function EditQrCodeStoryPage() {

    const { qrcode } = useParams();
    const [updateFormik, setUpdateFormik] = useState(false);
    const { token } = useSelector((state) => state.token);
    const { lang } = useSelector((state) => state.user);
    const { insertedQrCodeData, loading, loaded } = useSelector((state) => state.basic); // , errorMessage, internalErrorCode, loading, loaded 
    const dispatch = useDispatch();
    const [content, setContent] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);

    //const navigate = useNavigate();

    // const navigateListQrCodes = () => {
    //     // 👇️ navigate to /
    //     navigate('/list-qr-codes');
    // };
    const dispatchInsertNftStory = useCallback(
        (dataForm) => {
            dispatch(updateNftStory(dataForm));
        }
    );

    const dispatchCheckExistingQrCode = useCallback(
        (qrcode) => {
            //dispatch(clearState());
            dispatch(getQrCodeDataNoRedeem(qrcode, token));
        }
    );

    useEffect(() => {
        if (!loading && loaded && buttonClicked) {
            setOpenDialog(true);
            setButtonClicked(false);
        }
    }, [loading, loaded, buttonClicked]);

    useEffect(() => {
        dispatchCheckExistingQrCode(qrcode);
    }, [qrcode]);

    useEffect(() => {
        if (qrcode === insertedQrCodeData.walletQrId && checkIfNftExist(insertedQrCodeData) && !updateFormik) {
            setUpdateFormik(true);
            setContent(getDataFieldFromNftObj(insertedQrCodeData, 'nftStory'));
        }
    }, [qrcode, insertedQrCodeData]);

    const handleSubmitRegular = (e) => {
        e.preventDefault();
        let dataForm = {
            qrcode,
            token,
            nftStory: content
        };
        dispatchInsertNftStory(dataForm);
        setButtonClicked(true);
        //navigateListQrCodes();
    };

    return (
        <WrapperPage>
            <WrapperContainer>
                <GoBackButton />
                <Container maxWidth="lg">
                    <Typography variant="h5" align="left">
                        {insertedQrCodeData.productName}
                    </Typography>
                </Container>
                <SnackBarView
                    handleClose={(event, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setOpenDialog(false);
                    }}
                    errorMessage={'Uspešno sačuvano!'}
                    openSnackStatus={openDialog}
                    type={'success'} />
                {(qrcode === insertedQrCodeData.walletQrId) ? (<LoadForm
                    languages={languages}
                    lang={lang}
                    content={content}
                    setContent={setContent}
                    handleSubmitRegular={handleSubmitRegular}
                />) : ''}

            </WrapperContainer>
        </WrapperPage>
    );
}

const LoadForm = ({ languages, lang, handleSubmitRegular, content, setContent }) => {
    return (<Container maxWidth="lg">
        <Card variant="outlined" sx={{
            my: 2,
            p: 1,
            backgroundColor: (theme) => theme.palette.background.paperGray
        }}>

            <Box component="form" onSubmit={handleSubmitRegular} noValidate autoComplete="off" sx={{ mt: 1, width: '100%' }}>

                <SunEditor
                    autoFocus={true}
                    width="100%"
                    height="50vh"
                    setOptions={{
                        plugins: [
                            formatBlock,
                            paragraphStyle,
                            image,
                            link,
                            table
                        ],
                        buttonList: [
                            // default
                            ['undo', 'redo'],
                            ['paragraphStyle', 'formatBlock'],
                            ['bold', 'underline', 'italic', 'list'],
                            ['table', 'link', 'image'],
                            ['fullScreen']
                        ]

                    }}
                    setDefaultStyle="font-family: Roboto; font-size: 16px;"
                    setContents={content}
                    onChange={setContent}
                />

                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    {languages[lang].updateText}
                </Button>
            </Box>
        </Card>
    </Container>);
};

export default EditQrCodeStoryPage;
