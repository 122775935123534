import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';

function GoBackButton() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ my: 3, pt: 2 }}>
                <Button startIcon={<ArrowBackIosIcon />} variant='contained' sx={{ backgroundColor: (theme) => theme.orangeButton.button }} onClick={() => { navigate(-1); }}>{t('goBackBtn')}</Button>
            </Box>
        </Container>);

}

export default GoBackButton;
