import React from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

function AboutUS() {
  const { t } = useTranslation();

  return (
    <>
      <WrapperPage>
        <WrapperContainer>
          <div className='aboutUsDiv'>
            <Container className='aboutUsChildDiv' component="main" maxWidth="sm" sx={{ mt: 5 }}>
              <CssBaseline />
              <Typography variant="h4" color="info" align="center" sx={{ mt: 3, mb: 2 }}>
                {t('about-us')}
              </Typography>
              <Typography variant="body1" color="info" align="justify" sx={{ mt: 1, mb: 2 }}>
                {t('about-us-page.location')}
              </Typography>
              <Typography variant="body1" color="info" align="left" sx={{ mt: 1, mb: 2 }}>
                {t('about-us-page.join')}
                {' '}
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.linkedin.com/in/nemanja-milivojevic/"
                  underline="none"
                  color="#FF8D00"
                  sx={{ my: 1 }}
                >
                  nemanjamil@gmail.com
                </Link>

              </Typography>
              <Typography variant="body1" color="info" align="justify" sx={{ mt: 4, mb: 2 }}>
                {t('about-us-page.whoAreWe')}
              </Typography>
              <Typography variant="body1" color="info" align="justify" sx={{ mt: 1, mb: 2 }}>
                {t('about-us-page.whoAreWeAnswer')}
              </Typography>
              <Typography variant="body1" color="info" align="justify" sx={{ mt: 1, mb: 2 }}>
                {t('about-us-page.whoAreWeAnswer_1')}
              </Typography>
              <Typography variant="body1" color="info" align="justify" sx={{ mt: 1, mb: 2 }}>
                {t('about-us-page.whoAreWeAnswer_2')}
              </Typography>
              <Typography variant="body1" color="info" align="justify" sx={{ mt: 1, mb: 2 }}>
                {t('about-us-page.whoAreWeAnswer_3')}
              </Typography>
              <Typography variant="body1" color="info" align="justify" sx={{ mt: 3, mb: 2 }}>
                {t('about-us-page.whoAreWeAnswer_4')}
              </Typography>
              <Box noValidate sx={{ mt: 1, mb: 1, pb: 1, width: '100%', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
              </Box>
            </Container>
          </div>
        </WrapperContainer>
      </WrapperPage>
    </>
  );
}

export default AboutUS;
