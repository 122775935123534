import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinkHref from '../LinkHref';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createNewProject } from '../../redux/tree/actions';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object({
    projectName: yup.string('Unesi ime projekta').max(60, 'Ime može imate maksimalno 60 karaktera').required('Ime projekta je obavezno!'),
    projectMetaData: yup.object().optional()
});

const AddProjectComponent = ({ dispatchDeleteProjectHandler, handleUpdateProject }) => {
    const { t } = useTranslation();
    const { token } = useSelector((state) => state.token);
    const { listUserProjects } = useSelector((state) => state.tree);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const dispatchCreateNewProject = useCallback(
        (dataForm) => {
            dispatch(createNewProject({ dataForm, token }));
        }
    );

    const formik = useFormik({
        initialValues: {
            projectName: '',
            projectDesc: '',
            projectMetaData: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                let dataForm = {
                    projectName: values.projectName,
                    projectDesc: values.projectDesc,
                    projectMetaData: (values.projectMetaData) ? JSON.parse(values.projectMetaData) : null
                };
                console.log('dataForm', dataForm);

                dispatchCreateNewProject(dataForm);
                resetForm();
            } catch (error) {
                return console.log(error);
            }
        }
    });

    const handleDeleteProject = (projectId) => {
        dispatchDeleteProjectHandler(projectId);
    };

    const handleViewProject = (id) => {
        navigate(`/projects/${id}`);
    };

    return (
        <>
            <div>
                <React.Fragment>
                    <CssBaseline />
                    <Box>
                        <Grid container direction="column" spacing={4} justifyContent="space-around" alignItems="top"
                            sx={{ my: 3, border: '0px solid' }}>
                            <Grid item xs={12} md={12} className='tableGridAddProject'>
                                <ProjectTable
                                    t={t}
                                    listUserProjects={listUserProjects}
                                    handleDeleteProject={handleDeleteProject}
                                    handleViewProject={handleViewProject}
                                    handleUpdateProject={handleUpdateProject}
                                //handleEditProject={handleEditProject}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                            </Grid>
                        </Grid>
                    </Box>

                    <CssBaseline />
                    <Container maxWidth="sm">

                        <Box variant="outlined" sx={{
                            p: 1,
                            backgroundColor: (theme) => theme.palette.background.blueTransparent,
                            borderRadius: '15px'
                        }}>
                            <Grid container
                                direction="column" spacing={1} justifyContent="space-around" alignItems="top"
                                component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off"
                                sx={{ border: '0px solid' }}>


                                <Grid item xs={12} md={3}>
                                    <Typography variant="h5" align="center" sx={{ my: 2 }}>
                                        Add new project
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="projectName"
                                        name="projectName"
                                        label={t('projectName')}
                                        value={formik.values.projectName}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        error={formik.touched.projectName && Boolean(formik.errors.projectName)}
                                        helperText={formik.touched.projectName && formik.errors.projectName}
                                    />

                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="projectDesc"
                                        name="projectDesc"
                                        multiline
                                        rows={4}
                                        label={t('projectDesc')}
                                        value={formik.values.projectDesc}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        error={formik.touched.projectDesc && Boolean(formik.errors.projectDesc)}
                                        helperText={formik.touched.projectDesc && formik.errors.projectDesc}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="projectMetaData"
                                        name="projectMetaData"
                                        placeholder='Example: { "Color": "Blue", "Shape": "Circle"}'
                                        multiline
                                        rows={4}
                                        label="Project MetaData : Json Object"
                                        value={formik.values.projectMetaData}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        error={formik.touched.projectMetaData && Boolean(formik.errors.projectMetaData)}
                                        helperText={formik.touched.projectMetaData && formik.errors.projectMetaData}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className="centeredContent">
                                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: (theme) => theme.orangeButton.button }}>
                                        {t('addNewProjectBtn')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </React.Fragment>
            </div>
        </>
    );
};

const ProjectTable = ({ listUserProjects, handleDeleteProject, handleViewProject, handleUpdateProject, t }) => {

    return (<>
        <Typography variant="h4" align="center" sx={{ my: 2 }}>
            {t('projectList')}
        </Typography>
        <TableContainer>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            {t('projectName')}
                        </TableCell>
                        <TableCell>
                            {t('projectDesc')}
                        </TableCell>
                        <TableCell>
                            {t('projectMetadata')}
                        </TableCell>
                        <TableCell align="right">{t('deleteEdit')}</TableCell>
                    </TableRow>
                </TableHead>

                <CheckTableBody
                    t={t}
                    listUserProjects={listUserProjects}
                    handleDeleteProject={handleDeleteProject}
                    handleViewProject={handleViewProject}
                    handleUpdateProject={handleUpdateProject}
                />
            </Table>
        </TableContainer>
    </>
    );
};

const CheckTableBody = ({ listUserProjects, handleDeleteProject, handleViewProject, handleUpdateProject, t }) => {
    if (listUserProjects && listUserProjects.length > 0) {
        return (<TableBody>
            {listUserProjects.map((item) => (
                <TableProjectBodyData
                    t={t}
                    key={item._id}
                    item={item}
                    handleDeleteProject={handleDeleteProject}
                    handleUpdateProject={handleUpdateProject}
                    handleViewProject={handleViewProject}
                />
            ))}
        </TableBody>);
    }
    return <TableBody>
        <TableRow>
            <TableCell colSpan={4}>
                <Box sx={{ margin: 1 }}>
                    -
                </Box>
            </TableCell>
        </TableRow>
    </TableBody>;

};

const TableProjectBodyData = ({ t, item, handleDeleteProject, handleViewProject, handleUpdateProject }) => {
    const [open, setOpen] = useState(false);

    let getAllQrCodesFromProject = (id) => {
        console.log(id);
        setOpen(!open);
    };

    return <React.Fragment key={item._id}>
        <TableRow>

            <TableCell>
                <IconButton
                    variant="contained"
                    color="primary"
                    aria-label="expand row"
                    size="small"
                    onClick={() => getAllQrCodesFromProject(item._id)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>

            <TableCell>
                <LinkHref color="orange" to={`/projects/${item._id}`}>
                    {item.projectName}
                </LinkHref>
            </TableCell>

            <TableCell>
                <Typography variant="caption" dangerouslySetInnerHTML={{ __html: item.projectDesc }}>
                </Typography>
            </TableCell>
            <TableCell>
                <Typography component="pre" variant="caption">
                    {(item.projectMetaData) ? JSON.stringify(item.projectMetaData, null, 2) : item.projectMetaData}
                </Typography>

            </TableCell>

            <TableCell align="right">

                <Grid container direction="column" alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                        <Button sx={{ mx: 2,backgroundColor: (theme) => theme.grayButton.button }} variant="contained" color="warning" size="small" onClick={() => handleViewProject(item._id)}>
                            {t('viewData')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" sx={{ fontWeight: 600,backgroundColor: (theme) => theme.redButton.button }} size="small" color="error" onClick={() => handleDeleteProject(item._id)}>
                            {t('delete')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" size="small" sx={{ fontWeight: 600,backgroundColor: (theme) => theme.orangeButton.button }} color="error"
                            onClick={() => handleUpdateProject([item._id, item.projectName, item.projectDesc, item.projectMetaData])}>
                            {t('edit')}
                        </Button>
                    </Grid>
                </Grid>

                {/* <Button variant="contained" color="secondary" size="small" onClick={() => handleEditProject(item._id)}>
                                Edit
                            </Button> */}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                sx={{ borderBottom: 'unset' }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <QrCodesTable t={t} qrCodesList={item._wallets} />
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>

    </React.Fragment>;
};

const QrCodesTable = ({ qrCodesList, t }) => {
    return (<>
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('qrCodeName')}</TableCell>
                        <TableCell align="left">{t('status')}</TableCell>
                        <TableCell align="left">{t('access')}</TableCell>
                        <TableCell align="left">{t('blockchainTxId')}</TableCell>
                        <TableCell align="left">{t('linkForClient')}</TableCell>
                        <TableCell align="left">{t('nftTransaction')}</TableCell>
                        {/* <TableCell align="left">Qr Code Id</TableCell> */}
                        <TableCell align="left">NFT CID</TableCell>
                    </TableRow>
                </TableHead>
                <QrCodesTableBody t={t} qrCodesList={qrCodesList} />
            </Table>
        </TableContainer></>);
};

const QrCodesTableBody = ({ qrCodesList, t }) => {

    if (qrCodesList.length > 0) {
        return <TableBody>
            {qrCodesList.map((item) => {


                let statusRedeemd = (item.qrCodeRedeemStatus) ?
                    <Tooltip title={t('productIsPurchased')} ><LockIcon color="warning" /></Tooltip> :
                    <Tooltip title={t('productIsAvalableForPurchase')}><LockOpenIcon color='success' /></Tooltip>;
                let stateAccessible = (item.publicQrCode) ?
                    <Tooltip title={t('productIsPubliclyAvailable')}><PublicIcon color="success" /></Tooltip> :
                    <Tooltip title={t('productIsReserved')}><VpnLockIcon color="warning" /></Tooltip>;

                let stateSelected = (item.qrCodeRedeemStatus) ? true : false;
                let linkToCardanoExplorer = (item.transactionId) ? `${process.env.REACT_APP_TESTNET}${item.transactionId}` : '';
                let linkToCardanoExplorerName = (item.transactionId) ? 'BlockChain Link' : '';

                let linkToAccessCode = (!item.publicQrCode) ?
                    `${process.env.REACT_APP_WEB_SITE}/generate-contract/${item.walletQrId}/${item.accessCode}` :
                    '';

                return <TableRow
                    key={item._id}
                    selected={stateSelected}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    <TableCell component="th" scope="row">
                        <Link
                            component={RouterLink}
                            to={`/status/${item.walletQrId}`}
                            //underline="none"
                            color="info.light"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            {item.productName}
                        </Link>
                    </TableCell>
                    <TableCell align="left">
                        <LinkHref to={`/generate-contract/${item.walletQrId}`}>
                            {statusRedeemd}
                        </LinkHref>

                    </TableCell>
                    <TableCell align="left">
                        <LinkHref to={`/generate-contract/${item.walletQrId}`}>
                            {stateAccessible}
                        </LinkHref>

                    </TableCell>
                    <TableCell align="left">
                        <Box>
                            <Link variant="span" underline="none" color="secondary" target="_blank" rel="noreferrer noopener" href={linkToCardanoExplorer}>
                                {linkToCardanoExplorerName}
                            </Link>
                        </Box>
                        <Box sx={{ fontSize: '10px' }}>
                            {item.walletQrId}
                        </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: '12px' }}>
                        <Tooltip title={t('copyThisLinkForClient')}>
                            <Link
                                target="_blank"
                                rel="noreferrer noopener"
                                //component={RouterLink} ovo ide na to=""
                                href={linkToAccessCode}
                                underline="none"
                                color="info.light"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                {(linkToAccessCode) ? 'link ' : ''}
                            </Link>
                        </Tooltip>

                    </TableCell>
                    <TableCell
                        sx={{ fontSize: '12px' }} align="left">
                        {(('_nfts' in item) && item?._nfts.length > 0 && item?._nfts[0].transactionId) ?
                            <Link variant="span" sx={{ color: 'yellow' }} target="_blank" rel="noreferrer noopener"
                                href={`${process.env.REACT_APP_TESTNET}${item?._nfts[0].transactionId}`}>
                                Link
                            </Link> : ''}

                    </TableCell>
                    {/* <TableCell sx={{ fontSize: '12px' }} align="left">
                        {item.walletQrId}
                    </TableCell> */}
                    <TableCell sx={{ fontSize: '12px', width: '15%' }} align="left">
                        {
                            (('_nfts' in item) && item?._nfts.length > 0 && item?._nfts[0].cid) ? (
                                <Link
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    //component={RouterLink} ovo ide na to=""
                                    href={`https://ipfs.io/ipfs/${item._nfts[0].cid}`}
                                    underline="none"
                                    color="info.light"
                                    sx={{ my: 1 }}
                                >
                                    <img src={`https://ipfs.io/ipfs/${item._nfts[0].cid}`} width="50%" />
                                </Link>
                            ) : ''
                        }
                    </TableCell>
                </TableRow>;
            })}
        </TableBody>;
    }

    return <TableBody>
        <TableRow>
            <TableCell colSpan={7}>

            </TableCell>
        </TableRow>
    </TableBody>;

};

export default AddProjectComponent;