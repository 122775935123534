import React from 'react'; // , { useCallback } 
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



function ShowError({ errorMessage, internalErrorCode }) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { qrcode } = useParams();

  // const { token } = useSelector((state) => state.token);
  // const dispatch = useDispatch();

  //const stableDispatch = useCallback(dispatch, [dispatch]);

  // const clearUserToken = useCallback(() => {
  //   stableDispatch(clearStateUser());
  //   stableDispatch(clearSetToken());
  // }, [stableDispatch]);

  let showData = (
    <Button
      variant="contained"
      onClick={() => {
        navigate('/');
      }}
      sx={{ my: 1, mx: 1.5, backgroundColor: (theme) => theme.orangeButton.button }}
    >
      {t('refresh-page')}
    </Button>
  );

  if (internalErrorCode === 'token10') {
    showData = (<Button
      component={RouterLink}
      to="/log-out-user"
      //color="info.light"
      variant="contained"
      sx={{ mt: 3, mb: 2, backgroundColor: (theme) => theme.orangeButton.button }}
    >
      {t('refresh-login')}
    </Button>);
  }

  if (internalErrorCode === 'walletredeem10') {
    showData = (
      <Button
        variant="contained"
        onClick={() => {
          navigate(`/status/${qrcode}`);
        }}
        sx={{ my: 1, mx: 1.5, backgroundColor: (theme) => theme.orangeButton.button }}
      >
        {t('check-status')}
      </Button>
    );
  }

  if (internalErrorCode === 'tokenmissing') {
    showData = (
      <Button
        variant="contained"
        onClick={() => {
          navigate('/reg');
        }}
        sx={{ my: 1, mx: 1.5, backgroundColor: (theme) => theme.orangeButton.button }}
      >
        {t('login')}
      </Button>
    );
  }

  return (
    <Container maxWidth="xs">
      <Box justifyContent="center" sx={{ mt: 1, mb: 1, pb: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography color={'#ff8d00'} variant="h5" align="center" sx={{ mt: 3, mb: 2 }}>
          {errorMessage}
        </Typography>
        {showData}
      </Box>
    </Container>
  );
}

export default ShowError;
