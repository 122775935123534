import React from 'react';
import GenerateContractFormData from '../GenerateContractFormData';
import BreadcrumbsView from '../BreadcrumbsView';
import GoBackButton from '../GoBackButton';
import ShowError from '../ShowError';
import { useTranslation } from 'react-i18next';


function GenerateContractViewForm({ qrcode, accessCode, transactionUserData, insertedQrCodeData, formik }) {
  const { t } = useTranslation();

  let availabilityToContinue = (transactionUserData.numberOfCoupons > insertedQrCodeData.costOfProduct) ? true : false;

  let showFormOrResponse;
  switch (true) {
    case (!insertedQrCodeData.publicQrCode && accessCode !== insertedQrCodeData.accessCode):
      showFormOrResponse = <ShowError errorMessage={t('accessCodesDoNotMatch')} internalErrorCode="accessCodesDoNotMatch" />;
      break;
    case (Object.keys(insertedQrCodeData).length > 0):
      showFormOrResponse = <GenerateContractFormData
        handleSubmit={formik}
        formik={formik}
        qrcode={qrcode}
        availabilityToContinue={availabilityToContinue}
        insertedQrCodeData={insertedQrCodeData}
        transactionUserData={transactionUserData} />;
      break;
    default:
      showFormOrResponse = '';
      break;
  }

  return (<>
    <React.Fragment>
      <GoBackButton />
      <BreadcrumbsView />
      {showFormOrResponse}
    </React.Fragment>
  </>);
}

export default GenerateContractViewForm;
