import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';



function OpenCodeOnGitView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <Typography variant="h5" align="center" sx={{ mb: 5 }}>
                Zasto je ceo kod javno dostupan na GitHub-u?
            </Typography>
            <Typography variant="body1" align="left" sx={{ mt: 2 }}>
                Pobornici smo otvorenog koda. {' '}

                <Link
                    sx={{ color: 'green' }}
                    underline="none"
                    href="https://archiveprogram.github.com"
                    color="primary.black"
                    target="_blank"
                >
                    https://archiveprogram.github.com
                </Link>.
                {' '}
                Sve je free. Ceo back end code mozete videti na git-u. {' '}
                <Link
                    sx={{ color: 'yellow' }}
                    underline="none"
                    href="https://github.com/nemanjamil/moleculer-cardano"
                    color="primary.black"
                    target="_blank"
                >
                    https://github.com/nemanjamil/moleculer-cardano
                </Link>

            </Typography>

            <Typography variant="body1" align="left" sx={{ mt: 2 }}>
            Budite kreator novog koda, a ne samo potrošač!
            </Typography>
            

        </Container>
    );
}

export default OpenCodeOnGitView;