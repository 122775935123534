import React, { useEffect, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQrCodeDataNoRedeem, clearState } from '../../redux/basic/actions'; // getCallCidValue
import StatusQrCodeComponentSuccessful from '../../view/StatusQrCodeComponentSuccessful';
import StatusQrCodeComponentError from '../../view/StatusQrCodeComponentError';
import { objHasValues } from '../../common/commonFunctions';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


const StatusQrCodeComponent = () => {
  const { t } = useTranslation();
  const [getStopCounting, setStopCounting] = useState(false);
  const [getCountTime, setCountTime] = useState(10);
  const { qrcode } = useParams();
  const { insertedQrCodeData, walletQrId, errorMessage, internalErrorCode } = useSelector((state) => state.basic);
  const { token } = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  let linkToCardanoExplorer = `${process.env.REACT_APP_TESTNET}${insertedQrCodeData.transactionId}`;
  let fullWebQR = `${process.env.REACT_APP_WEB_SITE}/status/${walletQrId}`;
  let transactionId = insertedQrCodeData.transactionId;

  let forwarindState = (objHasValues(insertedQrCodeData) &&
    insertedQrCodeData?._nfts &&
    insertedQrCodeData?._nfts.length > 0 &&
    insertedQrCodeData?._nfts[0]?.nftlocation &&
    insertedQrCodeData?._nfts[0]?.urlforwarding) ? true : false;

  console.log('\n\n');
  console.log(' 1 . getCountTime', getCountTime);
  console.log(' 1 . getStopCounting', getStopCounting);
  console.log(' 1 . forwarindState', forwarindState);

  useEffect(() => {
    if (
      forwarindState
      && !getStopCounting
    ) {

      console.log('ENTER getCountTime', getCountTime);

      if (getCountTime < 1 && !getStopCounting) {
        window.location.replace(`${insertedQrCodeData?._nfts[0]?.nftlocation}`);
      } else {
        console.log('USAO U u create COUNTING : ', getCountTime);
        getCountTime > 0 && setTimeout(() => setCountTime(getCountTime - 1), 1000);
      }
    }
  }, [getCountTime, getStopCounting, forwarindState]);

  const handleStopCounting = () => {
    setStopCounting(true);
  };

  const dispatchCheckExistingQrCode = useCallback(
    (qrcode) => {
      stableDispatch(clearState());
      stableDispatch(getQrCodeDataNoRedeem(qrcode, token));
    },
    [stableDispatch]
  );

  useEffect(() => {
    dispatchCheckExistingQrCode(qrcode);
  }, [dispatchCheckExistingQrCode, qrcode]);

  let stateStatus = '';

  if (typeof insertedQrCodeData === 'object' &&
    !Array.isArray(insertedQrCodeData) &&
    insertedQrCodeData !== null) {

    stateStatus = <StatusQrCodeComponentSuccessful data={{ linkToCardanoExplorer, fullWebQR, qrcode, insertedQrCodeData, transactionId }} />;

  } else if (errorMessage && internalErrorCode) {
    stateStatus = <StatusQrCodeComponentError dataError={{ errorMessage, internalErrorCode }} />;
  } else {
    stateStatus = '';
  }
  return (<>

    {(forwarindState) ? (<Box
      sx={{
        marginTop: 3,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-around'
      }}
    >
      <Typography variant="h5" display="flex" align="center" justifyContent="center" sx={{ color: 'white !important' }}>
        {t('transferedToAnotherPage')}
        <Typography sx={{ color: '#ff8d03 !important', fontSize: '1.5rem', fontWeight:'600', marginLeft: '10px' }}>
          {getCountTime} sec
        </Typography>
      </Typography>
      <Typography variant="h5" align="center" sx={{ color: 'purple.main' }}>
        <Button sx={{ mx: 2, backgroundColor: (theme) => theme.orangeButton.button }} variant="outlined" size="small" onClick={() => handleStopCounting()}>
          {t('stopCounting')}
        </Button>
      </Typography>

    </Box>) : ''}

    <Box
      sx={{
        marginTop: 3,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-around'
      }}
    >
      {stateStatus}
    </Box>
  </>
  );
};

export default StatusQrCodeComponent;
