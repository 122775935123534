import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQrCodeData, clearState } from '../../redux/basic/actions';
import GenerateContractViewForm from '../../view/GenerateContractViewForm';
import { generateContract, generateContractApproval } from '../../redux/basic/actions';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';

import { useTranslation } from 'react-i18next';


const validationSchema = yup.object().shape({
  clientName: yup.string('Unesite ime').max(40, 'Ime može imati do 40 karaktera').required('Ime je obavezno!'),
  clientEmail: yup.string('Unesite email').email('Unesite validan email').required('Email je obavezan!'),
  clientMessage: yup.string('Unesite poruku').max(63, 'Poruka može imati do 63 karaktera').required('Poruka je obavezna!'),
  cbnftimage: yup.boolean(),
  // nftimage: yup
  //   .string()
  //   .when('cbnftimage', {
  //     is: true,
  //     //then: yup.string().required('Must enter email address')
  //     then: (validationSchema) => validationSchema.max(63, 'Message can be max 63 characters').required('IPFS CID is requred')
  //   }),
  nftsendaddress: yup.string().when('cbnftimage', {
    is: true,
    //then: yup.string().required('Must enter email address')
    then: (validationSchema) =>
      validationSchema
        .max(200, 'Adresa može imati do 200 karaktera')
        .required('Adresa je obavezna!')
        .test('Check prefix', function () {
          let nftsendaddress = this.parent['nftsendaddress'];
          let startsWith = 'addr';
          if (nftsendaddress && startsWith) {
            return nftsendaddress.startsWith(startsWith) ? true : this.createError({ message: 'Adresa nije validna!' });
          }
        })
  })
});

const GenerateContractComponent = () => {
  const { qrcode, accessCode } = useParams();
  const { t, i18n } = useTranslation();

  const { token } = useSelector((state) => state.token);
  const { loadedContract, insertedQrCodeData, loading, loaded, errorMessage, internalErrorCode, walletQrId, contractApproval } = useSelector(
    (state) => state.basic
  );
  const { transactionUserData } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  // Initial Render
  const dispatchCheckExistingQrCode = useCallback(
    (qrcode, token, accessCode) => {
      stableDispatch(clearState());
      stableDispatch(getQrCodeData(qrcode, token, accessCode));
    },
    [stableDispatch]
  );

  useEffect(() => {
    dispatchCheckExistingQrCode(qrcode, token, accessCode);
  }, [dispatchCheckExistingQrCode]);

  // Navigate after submit
  let navigateLink = accessCode ? `/verify-contract/${qrcode}/${accessCode}` : `/verify-contract/${qrcode}`;
  useEffect(() => {
    if (loadedContract) {
      navigate(navigateLink);
    }
  });

  // Request Deal Approval

  const dispatchGenerateTransactionEmail = useCallback(
    (contractData, token) => {
      if (!token) {
        alert('No Token');
      }
      stableDispatch(generateContractApproval(contractData, token));
    },
    [stableDispatch]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let contractData = {
      clientName: transactionUserData.userFullName,
      clientEmail: transactionUserData.userEmail,
      qrcode: walletQrId,
      accessCode: accessCode,
      cbnftimage: transactionUserData.cbnftimage,
      nftimage: transactionUserData.nftimage,
      userLang: i18n.resolvedLanguage
    };

    dispatchGenerateTransactionEmail(contractData, token);
  };

  // FORMIK ON SUBMIT
  const dispatchGenerateTransaction = useCallback(
    (contractData, token) => {
      if (!token) {
        alert('No Token');
      }
      stableDispatch(generateContract(contractData, token));
    },
    [stableDispatch]
  );

  const formik = useFormik({
    initialValues: {
      clientName: transactionUserData.userFullName,
      clientEmail: transactionUserData.userEmail,
      clientMessage: '',
      cbnftimage: '',
      nftimage: '',
      ownernamecb: true,
      clientemailcb: true,
      nftsendaddress: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let contractData = {
        clientName: values.clientName,
        clientEmail: values.clientEmail,
        clientMessage: values.clientMessage,
        qrcode: walletQrId,
        accessCode: accessCode,
        cbnftimage: values.cbnftimage,
        nftimage: values.nftimage,
        ownernamecb: values.ownernamecb,
        clientemailcb: values.clientemailcb,
        nftsendaddress: values.nftsendaddress
      };
      dispatchGenerateTransaction(contractData, token);
    }
  });

  useEffect(() => {
    if (loaded) {
      let cbFieldValue = insertedQrCodeData && '_nfts' in insertedQrCodeData && insertedQrCodeData?._nfts.length > 0 ? true : false;
      formik.setFieldValue('cbnftimage', cbFieldValue);
    }
  }, [loaded]);

  let qrCodeView;

  switch (true) {
    case loading:
      qrCodeView = <ShowLoader />;
      break;
    case token === '':
      qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode="noToken" />;
      break;
    case errorMessage !== '' && !loaded:
      qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
      break;
    case !loadedContract && transactionUserData.userEmail === insertedQrCodeData.userEmail && loaded && Object.keys(insertedQrCodeData).length > 0:
    case accessCode && !loadedContract && loaded && Object.keys(insertedQrCodeData).length > 0:
      qrCodeView = (
        <>
          <GenerateContractViewForm
            transactionUserData={transactionUserData}
            insertedQrCodeData={insertedQrCodeData}
            formik={formik}
            qrcode={qrcode}
            accessCode={accessCode}
          />
        </>

      );
      break;
    case !accessCode && transactionUserData.userEmail !== insertedQrCodeData.userEmail:
      qrCodeView = <SendDataInitiationProcess t={t} loaded={loaded} handleSubmit={handleSubmit} contractApproval={contractApproval} />;
      break;
    default:
      //qrCodeView = <ShowError errorMessage="Error may happened" internalErrorCode="randError_GenerateContractPage" />;
      qrCodeView = <ShowLoader color="secondary" />;
      break;
  }

  return <React.Fragment>{qrCodeView}</React.Fragment>;
};

const SendDataInitiationProcess = ({ handleSubmit, contractApproval, loaded, t }) => {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5" align="center">
          {t('createDigitalContract')}
        </Typography>
      </Box>

      {contractApproval && loaded ? <SuccessApprovalRes t={t} /> : <ButtonDataApproval t={t} handleSubmit={handleSubmit} />}
    </Container>
  );
};

const SuccessApprovalRes = ({ t }) => {
  return (
    <Box
      sx={{
        marginTop: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography variant="h5" align="center">
        {t('excellentYourRequstHasBeenSent')}
      </Typography>
    </Box>
  );
};

const ButtonDataApproval = ({ handleSubmit, t }) => {
  return (
    <>
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* <Typography variant="body1" align="center">
        In this section we will combine two messages. One is from the Mechant (Manufacturer) and second is from the Client (Consumer). Both of the messages
        will be stored in blockchain forewer.
      </Typography> */}
        <Typography variant="body1" align="center">
          {t('sendRequestToTheQrCodeCreator')}
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSubmit}>
        <Button type="submit" color="primary" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {t('requestForApproval')}
        </Button>
      </Box>
    </>
  );
};
export default GenerateContractComponent;
