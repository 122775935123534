import React, { useEffect, useCallback } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQrCodeFromId, clearState } from '../../redux/basic/actions';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';
import { objHasValues, checkIfNftExist } from '../../common/commonFunctions';
import NftStoryComponent from '../../components/NftStoryComponent';
import LinkHref from '../../components/LinkHref';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { listAllProjectNrApi } from '../../redux/tree/actions';
const _ = require('lodash');
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Link as RouterLink } from 'react-router-dom';
import { checkIfNftValueIsTrue } from '../../common/commonFunctions';
import '../../css/general.css';

function NftStoryPage() {

    const { idcode } = useParams();
    const dispatch = useDispatch();
    const { insertedQrCodeData, errorMessage, internalErrorCode, loading, loaded } = useSelector((state) => state.basic);
    const { listUserProjects } = useSelector((state) => state.tree);

    const getListOfProjectsNrApi = useCallback((idcode) => {
        dispatch(listAllProjectNrApi(idcode));
    });

    useEffect(() => {
        if (loaded && objHasValues(insertedQrCodeData._project)) {
            getListOfProjectsNrApi(insertedQrCodeData._project._id);
        }
    }, [idcode, loaded, insertedQrCodeData]);

    const dispatchCheckExistingQrCode = useCallback(
        (idcode) => {
            dispatch(clearState());
            dispatch(getQrCodeFromId(idcode));
        }
    );


    useEffect(() => {
        dispatchCheckExistingQrCode(idcode);
    }, [idcode]);

    let projectName = (insertedQrCodeData && insertedQrCodeData?._project && objHasValues(insertedQrCodeData._project) && insertedQrCodeData._project.projectName) ? insertedQrCodeData._project.projectName : '';
    let projectDesc = (insertedQrCodeData && insertedQrCodeData?._project && objHasValues(insertedQrCodeData._project) && insertedQrCodeData._project.projectDesc) ? insertedQrCodeData._project.projectDesc : '';
    let projectId = (insertedQrCodeData && insertedQrCodeData?._project && objHasValues(insertedQrCodeData._project) && insertedQrCodeData._project._id) ? insertedQrCodeData._project._id : '';

    let qrCodeView;

    switch (true) {
        case loading:
            qrCodeView = <ShowLoader />;
            break;
        case errorMessage !== '' && !loaded:
            qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
            break;
        case (loaded && insertedQrCodeData && objHasValues(insertedQrCodeData) && checkIfNftExist(insertedQrCodeData)):
            qrCodeView = <NftStoryComponent insertedQrCodeData={insertedQrCodeData} />;
            break;
        default:
            qrCodeView = <ShowLoader color="secondary" />;
            break;
    }

    let listData;
    if (listUserProjects && listUserProjects.length > 0) {
        listData = listUserProjects.map((item, i) => {
            return (
                <ListProjectAndWallets key={i} item={item} sx={{ mt: 1 }} />
            );
        });
    }

    let nftlocation = checkIfNftValueIsTrue(insertedQrCodeData, 'nftlocation');

    return (
        <WrapperPage>
            <WrapperContainer>
                <Container maxWidth="lg">

                    <Grid container direction="row">
                        <Grid item xs={12} md={4}>

                            <Typography variant="body1" align='left' sx={{ mt: 5, mb: 1 }}>
                                QR code details: &nbsp;
                                <LinkHref color="orange" to={`/status/${insertedQrCodeData.walletQrId}`}>
                                    {insertedQrCodeData.productName}
                                </LinkHref>
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={4}>

                            <Typography variant="body1" align='left' sx={{ mt: 5, mb: 1 }} display={projectName ? '' : 'none'}>
                                Belongs to Epic: &nbsp;
                                <LinkHref color="orange" to={`/projects/${projectId}`}>
                                    {projectName}
                                </LinkHref>
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={4}>

                            <Typography variant="body1" align='left' sx={{ mt: 5, mb: 1 }} display={nftlocation ? '' : 'none'}>
                                NFT location: &nbsp;
                                <Link variant="span" underline="none" color="orange"
                                    target="_blank" rel="noreferrer noopener" href={nftlocation}>
                                    {nftlocation}
                                </Link>


                            </Typography>

                        </Grid>
                    </Grid>

                    <Box className="darkBlueBox" noValidate sx={{ my: 3, py: 1, justifyContent: 'center', display: 'flex' }}>
                        {insertedQrCodeData.productName}
                    </Box>
                    {qrCodeView}

                    <Grid container direction="row" spacing={1}>

                        {(projectDesc) ? <Grid item xs={12} md={6}><BoxElement title={'Epic Description'} projectDesc={projectDesc} /></Grid> : ''}

                        {(insertedQrCodeData.longText) ? <Grid item xs={12} md={6}><BoxElement title={'Action that Backed this NFT'} projectDesc={insertedQrCodeData.longText} /></Grid> : ''}

                        <Grid item xs={12} md={12}>
                            <Box className="cianBox" noValidate sx={{ my: 3, py: 1, justifyContent: 'center', display: 'flex' }}>
                                Related Epics and NFT&apos;s
                            </Box>
                            <Box noValidate sx={{
                                mt: 1, mb: 1, pb: 2
                            }}>
                                {listData}
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </WrapperContainer>
        </WrapperPage>
    );
}

const ListProjectAndWallets = ({ item }) => {

    return (<Grid container direction="row">

        <Grid item xs={12} md={12}>

            <Typography variant="body1" align='left' sx={{ mt: 5, mb: 2 }}>
                Belongs to Epic : &nbsp;
                <LinkHref color="orange" to={`/projects/${item._id}`}>
                    {item.projectName}
                </LinkHref>
            </Typography>

            {

                (item?._wallets && _.isArray(item._wallets) && !_.isEmpty(item._wallets)) ?
                    (<Grid container direction="row" spacing={1}>
                        {item._wallets.map((item, i) => {
                            return (
                                <CardMicro item={item} key={i} />
                            );
                        })}
                    </Grid>)

                    : ''
            }

        </Grid>
    </Grid>);
};

const CardMicro = ({ item }) => {

    let picLoc = item._nfts.length ?
        `https://ipfs.io/ipfs/${item._nfts[0].cid}`
        : '/static/blokariaQR.png';

    return (<Grid item xs={4} md={2}><Card
        sx={{
            border: (theme) => `1px solid ${theme.palette.devider.color}`,
            backgroundColor: (theme) => theme.palette.background.defaultPrimary
        }}
    >
        <CardHeader
            //title={item.productName}
            subheader={item.productName}
            titleTypographyProps={{ align: 'center' }}
            subheaderTypographyProps={{
                align: 'center'
            }}
            sx={{
                backgroundColor: (theme) => theme.palette.background.defaultPrimary
                //fontSize: '10px'
            }}
        />


        <CardActionArea component={RouterLink} to={`/status/${item.walletQrId}`}>
            <CardContent sx={{ p: 1 }}>
                <CardMedia
                    component="img"
                    sx={{
                        height: '170px',
                        //maxHeight: '200px',
                        width: 'auto',
                        //maxWidth: '200px',
                        objectFit: 'fill'
                        // objectFit: 'fill',
                        // alignItems: 'center',
                        // display: 'block',
                        // marginLeft: 'auto',
                        // marginRight: 'auto'
                    }}
                    image={picLoc}
                    alt={item.productName}
                />
            </CardContent>
        </CardActionArea>

    </Card></Grid>);
};
const BoxElement = ({ projectDesc, title }) => {
    return (<>
        <Box className="darkBlueBox" noValidate sx={{ my: 3, py: 1, justifyContent: 'center', display: 'flex' }}>
            {title}
        </Box>

        <Typography variant="body1" className="changeAhrefColor" align='justify' sx={{ mt: 1, mb: 1 }} dangerouslySetInnerHTML={{ __html: projectDesc }}>
        </Typography>
    </>);
};

export default NftStoryPage;

