import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// GENERAL
import Home from '../pages/Home';
import GenerateContract from '../pages/GenerateContract';
import TransactionCreated from '../pages/TransactionCreated';
import StatusQrCode from '../pages/StatusQrCode';
import RedirectStatusPage from '../pages/RedirectStatusPage';
import GenerateQrCode from '../pages/GenerateQrCode';
import PricelistPage from '../pages/PricelistPage';
import GeneratedQrcode from '../pages/GeneratedQrcode';
import AboutUs from '../pages/AboutUs';
import EditQrCodePage from '../pages/EditQrCodePage';
// BA
import DocumentPage from '../pages/DocumentPage';
import UseCaseProduct from '../components/UseCaseProduct';
import UseCaseGeneral from '../view/UseCaseGeneral';
import PlantingTreeComponent from '../components/PlantingTreeComponent';
import RoadmapView from '../view/RoadmapView';
import SequenceDiagramsView from '../view/SequenceDiagramsView';
import BusinessCaseView from '../view/BusinessCaseView';
import RequirementsView from '../view/RequirementsView';
import LabelDesingRequirementsView from '../view/LabelDesingRequirementsView';
import TechnicalArchitectureView from '../view/TechnicalArchitectureView';
import OpenCodeOnGitView from '../view/OpenCodeOnGitView';
import FaqView from '../view/FaqView';
import NftCharacterView from '../view/NftCharacterView';
import TshirtPresentation from '../view/TshirtPresentation';
import HowToCreateQrCodeView from '../view/HowToCreateQrCodeView';
import UserInterfaceFlowView from '../view/Business/UserInterfaceFlowView';
import ThreeminPitchView from '../view/Business/ThreeminPitchView';
import WBSView from '../view/Business/WBSView';
import GoToMarketStrategyView from '../view/Business/GoToMarketStrategyView';
import CreatorApprovalView from '../view/CreatorApprovalView';

import GenerateNftPage from '../pages/GenerateNftPage';

// LOG IN
import LogInPage from '../pages/LogInPage';
import LogOutPage from '../pages/LogOutPage';
import RegisterPage from '../pages/RegisterPage';
import LostInPasswordPage from '../pages/LostInPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';

// USER
import QrCodesListPage from '../pages/QrCodesListPage';
import VerifyContract from '../pages/VerifyContract';
import UserPage from '../pages/UserPage';
import ListQRCodesPage from '../pages/ListQRCodesPage';
import ListOwnedQRCodesPage from '../pages/ListOwnedQRCodesPage';
import UserProfile from '../pages/UserProfile';
import TreeListPage from '../pages/TreeListPage';
import TreeListPerUserPage from '../pages/TreeListPerUserPage';

// PROJECT
import QrCodesInProjectPage from '../pages/QrCodesInProjectPage';
import MyProjectsPage from '../pages/MyProjectsPage';

// STORY
import NftStoryPage from '../pages/NftStoryPage';
import EditQrCodeStoryPage from '../pages/EditQrCodeStoryPage';


const Router = () => (
  <Routes>
    <Route exact path="/" element={<Home />}></Route>
    <Route exact path="/generate-qr-code" element={<GenerateQrCode />}></Route>
    <Route exact path="/generated-qrcode/:qrcode" element={<GeneratedQrcode />} />
    <Route exact path="/generate-contract/:qrcode/:accessCode" element={<GenerateContract />} />
    <Route exact path="/generate-contract/:qrcode" element={<GenerateContract />} />
    <Route exact path="/verify-contract/:qrcode/:accessCode" element={<VerifyContract />} />
    <Route exact path="/verify-contract/:qrcode" element={<VerifyContract />} />
    <Route exact path="/transaction-created/:qrcode" element={<TransactionCreated />} />
    <Route exact path="/status/:qrcode" element={<StatusQrCode />} />
    <Route exact path="/s/:idcode" element={<RedirectStatusPage />} />

    <Route exact path="/list-qr-codes" element={<ListQRCodesPage />} />
    <Route exact path="/my-projects" element={<MyProjectsPage />} />
    <Route exact path="/list-owned-qr-codes" element={<ListOwnedQRCodesPage />} />
    <Route exact path="/about-us" element={<AboutUs />} />
    <Route exact path="/creator-approval" element={<CreatorApprovalView />} />
    <Route exact path="/editqrcode/:qrcode" element={<EditQrCodePage />} />

    <Route exact path="/latest-generated-qr-codes" element={<QrCodesListPage />} />

    <Route exact path="/generate-nft/:qrcode" element={<GenerateNftPage />} />
    <Route exact path="/pricelist" element={<PricelistPage />} />

    <Route exact path="/tree-list" element={<TreeListPage />} />
    <Route exact path="/list-of-qrcodes-per-organization" element={<TreeListPerUserPage />} />

    <Route exact path="/projects/:id" element={<QrCodesInProjectPage />} />


    <Route exact path="/user" element={<UserPage />}>
      <Route index element={<UserProfile />} />
      <Route exact path="profile" element={<UserProfile />} />
      <Route path="*" element={<UserProfile />} />
    </Route>

    <Route exact path="/register-user" element={<RegisterPage />} />
    <Route exact path="/log-in-user" element={<LogInPage />} />
    <Route exact path="/log-out-user" element={<LogOutPage />} />
    <Route exact path="/lost-pass" element={<LostInPasswordPage />} />
    <Route exact path="/reset-password/:email/:clearPassword" element={<ResetPasswordPage />} />


    <Route exact path="/story/:idcode/" element={<NftStoryPage />} />
    <Route exact path="/editqrcodestory/:qrcode/" element={<EditQrCodeStoryPage />} />

    <Route exact path="/doc" element={<DocumentPage />}>
      <Route index element={<UseCaseProduct />} />
      <Route exact path="use-case-product" element={<UseCaseProduct />} />
      <Route exact path="how-to-create-qr-code" element={<HowToCreateQrCodeView />} />
      <Route exact path="use-case-general" element={<UseCaseGeneral />} />
      <Route exact path="use-case-planting-tree" element={<PlantingTreeComponent />} />
      <Route exact path="roadmap" element={<RoadmapView />} />
      <Route exact path="user-interface-flow" element={<UserInterfaceFlowView />} />
      <Route exact path="three-min-pitch" element={<ThreeminPitchView />} />
      <Route exact path="work-brakedown-structure" element={<WBSView />} />
      <Route exact path="go-to-market-strategy" element={<GoToMarketStrategyView />} />
      <Route exact path="sequence-diagrams" element={<SequenceDiagramsView />} />
      <Route exact path="business-case" element={<BusinessCaseView />} />
      <Route exact path="requirements" element={<RequirementsView />} />
      <Route exact path="labels-design-requirements" element={<LabelDesingRequirementsView />} />
      <Route exact path="technical-architecture" element={<TechnicalArchitectureView />} />
      <Route exact path="open-code-on-git" element={<OpenCodeOnGitView />} />
      <Route exact path="faq" element={<FaqView />} />
      <Route exact path="nft-character" element={<NftCharacterView />} />
      <Route exact path="t-shirt-presentation" element={<TshirtPresentation />} />


    </Route>
    <Route path="*" element={<Navigate to="/" />}></Route>
  </Routes>
);

export default Router;
