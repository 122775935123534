import React, { useEffect, useCallback } from 'react';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { getOneProject } from '../../redux/tree/actions';
import ListGridQrCodesView from '../../view/ListGridQrCodesView';
import ShowLoader from '../../view/ShowLoader';
import GoBackButton from '../../view/GoBackButton';
import { useTranslation } from 'react-i18next';

function QrCoderPerProjectComponent() {

    const { t } = useTranslation();
    let { id: projectId } = useParams();
    let navigate = useNavigate();
    const { transactionData, loading, loaded } = useSelector((state) => state.tree);
    const { token } = useSelector((state) => state.token);
    const dispatch = useDispatch();

    const dispatchCheckExistingQrCode = useCallback(
        (projectId) => {
            dispatch(getOneProject({ projectId, token }));
        }
    );

    useEffect(() => {
        dispatchCheckExistingQrCode(projectId);
    }, []);


    let showData = '';
    switch (true) {
        case (loading):
            showData = <ShowLoader />;
            break;
        case (loaded && transactionData?._wallets && transactionData?._wallets.length > 0):
            showData = <ListGridQrCodesView navigate={navigate} transactionData={transactionData._wallets} />;
            break;
        default:
            break;
    }

    return (
        <>
            <GoBackButton />
            <BreadCrumbsComponent t={t} navigate={navigate} />
            <Container name="WrapperContainer" maxWidth="xl" sx={{ pt: 0, pb: 4, minHeight: '100vh' }}>
                <TreeListTitle t={t} transactionData={transactionData} />
                <ProjectDesc transactionData={transactionData} />
                {showData}
            </Container>
        </>
    );
}



const BreadCrumbsComponent = ({ navigate, t }) => {

    return (
        <Container name="WrapperContainer" maxWidth="xl" sx={{ mt: 4, px: 5 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="#"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />{t('home')}
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={() => {
                        navigate('/my-projects');
                    }}
                >
                    {t('organisationListQrCodesPerProject')}
                </Link>
                <Typography color="text.primary" aria-current="page">{t('list')}</Typography>
            </Breadcrumbs>
        </Container>
    );
};

const TreeListTitle = ({ transactionData, t }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                my: 4
            }}
        >
            <Typography variant="h4" color="info">
                {transactionData.projectName}
            </Typography>
            <Typography variant="body1" sx={{ my: 2 }} color="info">
                {t('lisOfQrCodesPerProject')}
            </Typography>

        </Box>
    );
};
const ProjectDesc = ({ transactionData }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                my: 4
            }}
        >
            <Typography sx={{ my: 2 }} variant="body1" color="info" dangerouslySetInnerHTML={{ __html: transactionData.projectDesc }}>

            </Typography>
        </Box>
    );
};
export default QrCoderPerProjectComponent;