import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


function TechnicalArchitectureView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>

            <Box sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    General Architecture
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    NodeJs + React + Cardano Node + Cardano Wallet + GitHib Ci/Cd
                </Typography>
            </Box>


            <Box sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    Front End
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    We are using React for the Frontend. MUI V5 is used for components.
                </Typography>
            </Box>

            <Box sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    Back End - Moleculer
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    From the architectural point-of-view the online store can be seen as a
                    composition of 2 independent services: the products service and the gateway service.
                    The first one is responsible for storage and management
                    of the products while the second simply receives user´s requests and conveys them to the products service.
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    <Link
                        target="_blank"
                        rel="noreferrer noopener"
                        href="/static/localpic/service-broker.jpg"
                        underline="none"
                        color="info.light"
                        sx={{ my: 1 }}
                    >
                        <img src="/static/localpic/service-broker.jpg" width="100%" />
                    </Link>

                </Typography>
            </Box>

            <Box sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    Cardano BlockChain
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    https://cardano.org/
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    Cardano is a blockchain platform for changemakers, innovators, and visionaries,
                    with the tools and technologies required to
                    create possibility for the many, as well as the few, and bring about positive global change.
                </Typography>
                {/* <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    https://input-output-hk.github.io/cardano-wallet/api/edge/#tag/Wallets
                </Typography>
                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    https://developers.cardano.org/docs/integrate-cardano/creating-wallet-faucet/
                </Typography> */}
            </Box>

            <Box sx={{ maxWidth: '100%', p: 2 }}>
                <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    //component={RouterLink} ovo ide na to=""
                    href="/static/localpic/architecture.jpg"
                    underline="none"
                    color="info.light"
                    sx={{ my: 1 }}
                >
                    .
                    {/* <img src="/static/localpic/architecture.jpg" width="100%" /> */}
                </Link>

                <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                   Schema update in progress
                </Typography>
            </Box>
        </Container>
    );
}

export default TechnicalArchitectureView;