import React, { useEffect, useCallback } from 'react';
import WrapperPage from '../../components/WrapperPage';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListGridQrCodesView from '../../view/ListGridQrCodesView';
import { getAllQrCodesByUser } from '../../redux/tree/actions';
import { useTranslation } from 'react-i18next';

function TreeListPerUserPage() {
    const { t } = useTranslation();
    const { state } = useLocation();
    let navigate = useNavigate();
    const { transactionData } = useSelector((state) => state.tree);
    let userEmail = (state) ? state.userEmail : '';

    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);

    const dispatchCheckExistingQrCode = useCallback(
        () => {
            //stableDispatch(clearState());
            if (userEmail) stableDispatch(getAllQrCodesByUser(userEmail));
        },
        [stableDispatch]
    );

    useEffect(() => {
        dispatchCheckExistingQrCode();
    }, [dispatchCheckExistingQrCode]);


    let showData = '';
    if (transactionData.length > 0) {
        showData = <ListGridQrCodesView navigate={navigate} transactionData={transactionData} />;
    } else {
        showData = '';
    }

    return (
        <>
            <WrapperPage>
                <BreadCrumbsComponent navigate={navigate} t={t} userEmail={userEmail} />
                <Container name="WrapperContainer" maxWidth="xl" sx={{ pt: 0, pb: 4, minHeight: '100vh' }}>
                    <TreeListTitle t={t} />
                    {showData}
                </Container>
            </WrapperPage>
        </>
    );
}

const BreadCrumbsComponent = ({ navigate, t, userEmail }) => {
    return (
        <Container name="WrapperContainer" maxWidth="xl" sx={{ mt: 4, px: 5 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="#"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> {t('home')}
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={() => {
                        navigate('/tree-list');
                    }}
                >
                    {t('organizationList')}
                </Link>
                <Typography color="text.primary" aria-current="page"> {t('list')}</Typography>
                <Typography color="text.primary" aria-current="page"> {userEmail}</Typography>
            </Breadcrumbs>
        </Container>
    );
};

const TreeListTitle = ({ t }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                my: 4
            }}
        >
            <Typography variant="h4" color="info">
                {t('qrCodesByUser')}
            </Typography>
            <Typography variant="body1" color="info">

            </Typography>
        </Box>
    );
};
export default TreeListPerUserPage;