import React, { useCallback, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useParams, useNavigate } from 'react-router-dom';
import { initiateTransactionToClientWallet } from '../../redux/basic/actions';
import { useDispatch, useSelector } from 'react-redux';
import ShowLoader from '../../view/ShowLoader';
import ShowError from '../../view/ShowError';
import { getQrCodeData, clearState } from '../../redux/basic/actions';
import '../../css/general.css';
import Link from '@mui/material/Link';
import NftData from '../../view/NftData';
import { objHasValues } from '../../common/commonFunctions';
import SnackBarView from '../../view/SnackBarView';
import { useTranslation } from 'react-i18next';

const VerifyContractComponent = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { qrcode, accessCode } = useParams();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [openDialog, setOpenDialog] = React.useState(false);

  const { loading, loaded, walletQrId, insertedQrCodeData, loadedBlockChainTransaction, qrCodeRedeemStatus, errorMessage } = useSelector(
    (state) => state.basic
  );
  const { token } = useSelector((state) => state.token);

  let mintedNft = (objHasValues(insertedQrCodeData) &&
    insertedQrCodeData?._nfts &&
    insertedQrCodeData?._nfts.length > 0) ? true : false;

  // On Click
  const dispatchExecuteTransaction = useCallback(
    (walletQrId, accessCode) => {
      let userLang = i18n.resolvedLanguage;
      stableDispatch(initiateTransactionToClientWallet(walletQrId, token, accessCode, userLang));
    },
    [stableDispatch]
  );
  const setExecuteTransaction = () => {
    dispatchExecuteTransaction(walletQrId, accessCode);
  };

  // On Initial Page Roll
  const dispatchCheckExistingQrCode = useCallback(
    (qrcode, token, accessCode) => {
      stableDispatch(clearState());
      stableDispatch(getQrCodeData(qrcode, token, accessCode));
    },
    [stableDispatch]
  );

  useEffect(() => {
    dispatchCheckExistingQrCode(qrcode, token, accessCode);
  }, [dispatchCheckExistingQrCode]);

  // Foward To Page
  useEffect(() => {
    if (loadedBlockChainTransaction) navigate(`/transaction-created/${walletQrId}`);
  });


  useEffect(() => {
    setOpenDialog(true);
  }, [errorMessage]);

  let whatToShow = '';
  switch (true) {
    case loading:
      whatToShow = <ShowLoader />;
      break;
    case token === '':
      whatToShow = <ShowError errorMessage={t('missingTokenPleaseLogin')} internalErrorCode="noToken" />;
      break;
    case qrCodeRedeemStatus:
      whatToShow = <ShowError errorMessage="QR kod je već iskorišćen" internalErrorCode="qrcode10" />;
      break;
    // case errorMessage !== '' && !loaded:
    //   whatToShow = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
    //   break;
    case errorMessage !== '' && !loaded:
      whatToShow = <SubmitDataToBlockChainForm
        t={t}
        mintedNft={mintedNft}
        insertedQrCodeData={insertedQrCodeData} setExecuteTransaction={setExecuteTransaction} />;
      break;

    case !loadedBlockChainTransaction && loaded:
      whatToShow = <SubmitDataToBlockChainForm
        t={t}
        mintedNft={mintedNft}
        insertedQrCodeData={insertedQrCodeData} setExecuteTransaction={setExecuteTransaction} />;
      break;
    default:
      console.log('Entered default VerifyContractComponent');
      break;
  }

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'space-around'
        }}
      >
        {whatToShow}
        <SnackBarView
          handleClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpenDialog(false);
          }}
          errorMessage={errorMessage}
          openSnackStatus={openDialog}
          type={'error'} />
      </Box>
    </Container>
  );
};

// const productPictureRes = (productPicture) => {
//   return (
//     <Grid container direction="row" item xs={12}>
//       <Grid item xs={3}>
//         Product Picture :
//       </Grid>
//       <Grid item xs={9}>
//         {productPicture}
//       </Grid>
//     </Grid>
//   );
// };

const productVideoRes = (productVideo) => {
  return (
    <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
      <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
        Video proizvoda:
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        {productVideo}
      </Grid>
    </Grid>
  );
};

const SubmitDataToBlockChainForm = ({ insertedQrCodeData, setExecuteTransaction, mintedNft, t }) => {

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Box className="darkBlueBox" noValidate sx={{ my: 1, py: 1, width: '100%', justifyContent: 'center', display: 'flex' }}>
          {t('producer')}
        </Box>

        <Grid container direction="row" item xs={12} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            {t('qrCodeId')} :
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            {insertedQrCodeData.walletQrId}
          </Grid>
        </Grid>

        <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            {t('productName')} :
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            {insertedQrCodeData.productName}
          </Grid>
        </Grid>

        <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            {t('creatorName')}:
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            {insertedQrCodeData.userFullname}
          </Grid>
        </Grid>

        <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            {t('creatorEmail')}:
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            {insertedQrCodeData.userEmail}
          </Grid>
        </Grid>
        <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
          <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
            {t('creatorMsg')}:
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            {insertedQrCodeData.userDesc}
          </Grid>
        </Grid>

        {/* {insertedQrCodeData.costOfProduct ? costOfProduct(insertedQrCodeData.costOfProduct) : ''} */}

        {/* {insertedQrCodeData.productPicture ? productPictureRes(insertedQrCodeData.productPicture) : ''} */}
        {insertedQrCodeData.productVideo ? productVideoRes(insertedQrCodeData.productVideo) : ''}

        {insertedQrCodeData.contributorData ? <Contributor t={t} insertedQrCodeData={insertedQrCodeData} /> : ''}

        {insertedQrCodeData.clientName && insertedQrCodeData.clientEmail && insertedQrCodeData.clientMessage ? (
          <ClientData insertedQrCodeData={insertedQrCodeData} t={t} />
        ) : (
          <NoClientData t={t} />
        )}

        {mintedNft ? <NftData
          insertedQrCodeData={insertedQrCodeData}
          showOnlyNftCreationDetails={true}
        /> : ''}
      </Grid>
      {insertedQrCodeData.clientName && insertedQrCodeData.clientEmail && insertedQrCodeData.clientMessage ? (
        <ButtonAndDetail setExecuteTransaction={setExecuteTransaction} t={t} />
      ) : (
        ''
      )}

      <Container maxWidth="sm">
        <Box sx={{ mt: 1, mb: 3, pb: 2, width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" align="justify" sx={{ m: 3 }}>
            {t('clientTwoOptions')}
          </Typography>
          <Typography variant="body1" align="justify" sx={{ m: 3 }}>
            {t('afterButtonClick')}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

// const costOfProduct = (costOfProduct) => {
//   return (
//     <Grid container direction="row" item xs={12}>
//           <Grid item xs={3}>
//             Cost Of Product :
//           </Grid>
//           <Grid item xs={9}>
//             {costOfProduct}
//           </Grid>
//         </Grid>
//   );
// };


const ButtonAndDetail = ({ setExecuteTransaction, t }) => {

  return (
    <Container maxWidth="sm" sx={{ mt: 3 }}>
      <Box noValidate sx={{ mt: 3, mb: 1 }}>
        <Typography variant="h5" sx={{ mt: 3, textAlign: 'center' }}>
          {t('submitToBlockchain')}
        </Typography>
      </Box>

      <Box noValidate sx={{ mt: 3, mb: 3, pb: 2, width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Button sx={{ backgroundColor: (theme) => theme.orangeButton.button }} size="large" variant="contained" onClick={() => setExecuteTransaction()}>
          {t('submitDataToBlockchainBtn')}
        </Button>
      </Box>
    </Container>
  );
};

const NoClientData = ({ t }) => {
  return (
    <Box
      noValidate
      sx={{
        mt: 2,
        mb: 3,
        pb: 2,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        borderBottom: '1px solid'
      }}
    >
      <Typography variant="h6" align="justify" sx={{ m: 3, color: 'yellow' }}>
        {t('noClientData')}
      </Typography>
      <Typography variant="h6" align="justify" sx={{ m: 3, color: 'yellow' }}>
        {t('firstCreateSmartContract')}
      </Typography>
    </Box>
  );
};



const ClientData = ({ insertedQrCodeData, t }) => {
  return (
    <>
      <Box className="darkBlueBox" noValidate sx={{ mt: 4, mb: 1, py: 1, width: '100%', justifyContent: 'center', display: 'flex' }}>
        {t('futureOwner')}
      </Box>
      <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('ownerMsg')}:
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {insertedQrCodeData.clientMessage}
        </Grid>
      </Grid>
      <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('ownerName')}:
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          {insertedQrCodeData.clientName}
        </Grid>
        <Grid item xs={12} sm={5} md={6}>
          {!insertedQrCodeData.ownernamecb ? 'Ime neće biti dodato na Blockchain' : ''}
        </Grid>
      </Grid>
      <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('ownerEmail')}:
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          {insertedQrCodeData.clientEmail}
        </Grid>
        <Grid item xs={12} sm={5} md={6}>
          {!insertedQrCodeData.clientemailcb ? t('email-will-not-be-added-to-bc') : ''}
        </Grid>
      </Grid>
      <NftImage t={t} insertedQrCodeData={insertedQrCodeData} />
      <NftSendAddress t={t} insertedQrCodeData={insertedQrCodeData} />
    </>
  );
};

const Contributor = ({ insertedQrCodeData, t }) => {
  return (
    <>
      <Box className="darkBlueBox" noValidate sx={{ mt: 4, mb: 1, py: 1, width: '100%', justifyContent: 'center', display: 'flex' }}>
        {t('sponsorSupporterContributor')}
      </Box>
      <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('name')}
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {insertedQrCodeData.contributorData}
        </Grid>
      </Grid>
    </>
  );
};

const NftImage = ({ insertedQrCodeData, t }) => {
  let returnData = '';
  if (insertedQrCodeData.cbnftimage) {
    returnData = (
      <Grid container direction="row" item xs={12} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('nftImage')}:
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            //component={RouterLink} ovo ide na to=""
            href={`https://ipfs.io/ipfs/${insertedQrCodeData?._nfts[0].cid}`}
            underline="none"
            color="yellow"
            sx={{ my: 1 }}
          >
            https://ipfs.io/ipfs/{insertedQrCodeData?._nfts[0].cid}
          </Link>
        </Grid>
      </Grid>
    );
  }
  return returnData;
};

const NftSendAddress = ({ insertedQrCodeData, t }) => {
  let returnData = '';
  if (insertedQrCodeData.cbnftimage) {
    returnData = (
      <Grid container direction="row" item xs={12} sx={{ pt: 1 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ fontWeight: 'bold' }}>
          {t('walletAddress')}:
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {insertedQrCodeData.nftsendaddress}
        </Grid>
      </Grid>
    );
  }
  return returnData;
};

export default VerifyContractComponent;
