import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../../css/general.css';

function RequirementsView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>

            <Box className="turquoiseBox" sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                Poslovni zahtevi
                </Typography>

                {/* <Typography variant="body1" align="left" sx={{ mt: 2, mb: 2 }}>
                    https://www.modernanalyst.com/Careers/InterviewQuestions/tabid/128/ID/2033/Explain-how-BABOK-categorizes-requirements.aspx
                </Typography> */}
                {/* <Typography variant="body1" align="left" sx={{ mt: 2, mb: 2 }}>
                    Business Requirements are high-level statements that
                    describe the goals and objectives of the business and the
                    enterprise level.  A few key points are:
                </Typography>
                <Box>
                    <ul>
                        <li>Business Requirements define WHAT the business is trying to achieve</li>
                        <li>In turn, Business Requirements define WHY a project
                            should be undertaken or a solution implemented</li>
                        <li>Business Requirements define the metrics
                            that will be used to measure success</li>
                    </ul>
                </Box> */}

                <Box sx={{ backgroundColor: '#323233', mb: 2, p: 2 }}>

                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        Cilj je da omogucimo proizvodjacima potvrdu
                        autenticnosti  njihovih usluga ili proizvoda,
                        kao i validiranje na blokchain-u.
                    </Typography>
                    <Box variant="body1" align="left" sx={{ mb: 2 }}>
                        Cilj : Blokarija je takodje i #Software As A Service solution jer pruza
                        mogucnost drugim aplikacijama da koriste njene servise kako sto su:
                        <ul>
                            <li>Generisanje QR Koda</li>
                            <li>MetaTag-a na Cardano BlockChain</li>
                            <li>Mintovanje NFT-a na Cardanu i Solani</li>
                            <li>Out of the box resenja koriscenje BlokChain Platformi</li>
                        </ul>
                    </Box>

                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        Why:  Zato sto dajemo mogucnost upisivanje podataka na BlokChain za neku aktivnost,
                        uslugu ili proizvod i time obezbedjujemo da se #dogovor izmedju #ugovornih strana postuje.
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        Metrics: Broj upisivanje QR kodova na sistem i na BlokChain
                    </Typography>
                </Box>
            </Box>




            <Box className="turquoiseBox" sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    Stakeholder zahtevi
                </Typography>
                {/* <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    Describe the needs of stakeholders that must be met in order to achieve the business requirements.
                </Typography>
                <Box>
                    <ul>
                        <li>Stakeholder Requirements define WHAT each
                            specific stakeholder/group needs from a solution</li>
                        <li>Stakeholder Requirements identify the use cases of a solution</li>
                        <li>Solution Requirements describe what characteristics a solution will have to meet the needs of the stakeholders and business.</li>
                    </ul>
                </Box> */}

                <Box sx={{ backgroundColor: '#323233', mb: 2, p: 2 }}>

                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        - Radovi u toku
                    </Typography>
                    {/* <Box variant="body1" align="left" sx={{ mb: 2 }}>
                        -
                        <ul>
                            <li>-</li>

                        </ul>
                    </Box>

                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        -
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                        -
                    </Typography> */}
                </Box>
            </Box>


            <Box className="turquoiseBox" sx={{ mb: 2, p: 2 }}>
                <Typography variant="h5" align="left" sx={{}}>
                    Solution Requirements
                </Typography>
                {/* <Typography variant="body1" align="left" sx={{}}>
                    Describe the capabilities and qualities of a solution
                    that meets the stakeholder requirements. They provide the appropriate
                    level of detail to allow for the development and implementation of the solution.
                </Typography>
                
                <Box>
                    <ul>
                        <li>Solution Requirements describe WHAT
                            characteristics the solution will support.</li>
                        <li>From a stakeholder perspective the Solution Requirements may be
                            viewed as describing HOW the Solution Requirements
                            will meet the stakeholder need, but even from this perspective
                            it would define the HOW
                            logically and at a high level rather than give details of the
                            implementation of the solution</li>
                    </ul>
                </Box> */}

                <Box sx={{ backgroundColor: '#323233', mb: 2, p: 2 }}>

                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    Funkcionalni zahtevi
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    Definisanje mogućnosti koje proizvod mora da pruži svojim korisnicima
                    </Typography>
                    <Box variant="body1" align="left" sx={{ mb: 2 }}>
                        <ul>
                            <li>Sistem mora poslati e-poruku sa potvrdom kad god se QR kod kreira ili ažurira</li>
                            <li>Sistem mora omogućiti korisnicima da čuvaju informacije o proizvodu ili usluzi kao MetaTag na BlockChain-u.</li>
                            <li>Sistem mora omogućiti korisnicima da verifikuju svoje naloge koristeći svoju e-poštu.</li>
                            <li>Stranica korisničkog profila</li>
                            <li>Stranica sa listom QR kodova</li>
                            <li>Stranica na kojoj korisnik i kreator KR koda mogu da potpišu virtualni ugovor</li>
                            <li>Sistem mora da ubaci MetaTag detalje virtualnog ugovora na BlockChain</li>

                            <li>Sistem mora da ....</li>

                        </ul>
                    </Box>

                </Box>
                <Box sx={{ backgroundColor: '#323233', mb: 2, p: 2 }}>

                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    Нефункционални захтеви
                    </Typography>
                    <Typography variant="body1" align="left" sx={{ mb: 2 }}>
                    Opišite uslove pod kojima rešenje mora da ostane efikasno ili kvalitete koje rešenje mora da poseduje.
                    </Typography>
                    <Box variant="body1" align="left" sx={{ mb: 2 }}>
                          <ul>
                            <li>Čitljivost</li>
                            <li>Performanse</li>
                            <li>Operabilnost</li>
                            <li>Sigurnost</li>
                            <li>Kompatibilnost</li>
                            <li>Održavanje</li>
                            <li>Prenosivost</li>
                        </ul>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default RequirementsView;