import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

const LinkHref = (({ children, ...props }) => {
    return (<Link
        underline="none"
        component={RouterLink}
        color={props.color}
        to={`${props.to}`}
        target={props.target}
        rel={props.rel}
        sx={{
            //fontWeight: 'bold',
            //fontSize: 4, // picks up value from `theme.fontSizes[4]`
            //color: 'secondary' // picks up value from `theme.colors.primary`
            ...props.sx
          }}

        
    >
        {children}
    </Link>);
});

export default LinkHref;
