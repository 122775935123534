import * as ActionTypes from './actions';
// BASIC
const initialState = {
  errorMessage: '',
  internalErrorCode: '',
  transactionData: '',

  walletQrId: '',
  qrCodeRedeemStatus: 0,
  startFresh: false,

  loading: false,
  // Generate Transaction
  loaded: false,
  loadedContract: false,
  loadedBlockChainTransaction: false,

  // Generate Contract
  insertedQrCodeData: '',
  successfullTransactionGenerateContract: false,
  contractApproval: false,

  // Generate QR code
  successfullQrCodeGeneration: '',

  // NFT IMAGE
  loadingNft: true,
  loadedNft: false
};

const immutableState = Object.freeze({
  errorMessage: '',
  internalErrorCode: '',
  transactionData: '',

  walletQrId: '',
  qrCodeRedeemStatus: 0,
  startFresh: false,

  loading: false,
  // Generate Transaction
  loaded: false,
  loadedContract: false,
  loadedBlockChainTransaction: false,

  // Generate Contract
  insertedQrCodeData: '',
  successfullTransactionGenerateContract: false,
  contractApproval: false,

  // Generate QR code
  successfullQrCodeGeneration: '',

  // NFT IMAGE
  loadingNft: false,
  loadedNft: false
});

const basicReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_STATE:
      return {
        ...state,
        ...immutableState
      };

    // ----------------------- 2---------------------------

    case ActionTypes.GET_QR_CODE_DATA_NO_REDEEM:
      return {
        ...state,
        loading: true,
        loaded: false,
        insertedQrCodeData: '',
        qrCodeRedeemStatus: 0
      };

    case ActionTypes.GET_QR_CODE_DATA:
      return {
        ...state,

        loading: true,
        loaded: false,
        insertedQrCodeData: '',
        qrCodeRedeemStatus: 0
      };
    case ActionTypes.GET_QR_CODE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        walletQrId: action.payload[0].walletQrId,
        insertedQrCodeData: action.payload[0],
        qrCodeRedeemStatus: action.payload[0].qrCodeRedeemStatus
      };
    case ActionTypes.GET_QR_CODE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        qrCodeRedeemStatus: 0
      };

    // ----------------------- 1 - 1---------------------------

    // case ActionTypes.GET_CALL_CID_VALUE:
    //   return {
    //     ...state,
    //     loadingNft: true,
    //     loadedNft: false,
    //     insertedQrCodeData: {
    //       ...state.insertedQrCodeData,
    //       nftCidImage: ''
    //     }
    //   };
    // case ActionTypes.GET_CALL_CID_VALUE_SUCCESS:
    //   return {
    //     ...state,
    //     loadingNft: false,
    //     loadedNft: true,
    //     insertedQrCodeData: {
    //       ...state.insertedQrCodeData,
    //       nftCidImage: action.payload
    //     }
    //   };
    // case ActionTypes.GET_CALL_CID_VALUE_ERROR:
    //   return {
    //     ...state,
    //     loadingNft: false,
    //     loadedNft: false
    //   };

    // ------------------------- 2--------------------------------

    case ActionTypes.GENERATE_CONTRACT:
      return {
        ...state,
        loading: true,
        loaded: false,
        loadedContract: false,
        errorMessage: '',
        internalErrorCode: ''
      };
    case ActionTypes.GENERATE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loadedContract: true,
        insertedQrCodeData: action.payload
      };
    case ActionTypes.GENERATE_CONTRACT_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };


    // ------------------------- 2.1 --------------------------------

    case ActionTypes.GENERATE_CONTRACT_APPROVAL:
      return {
        ...state,
        loading: true,
        loaded: false,
        errorMessage: '',
        contractApproval: false,
        internalErrorCode: ''
      };
    case ActionTypes.GENERATE_CONTRACT_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contractApproval: true,
        insertedQrCodeData: action.payload
      };
    case ActionTypes.GENERATE_CONTRACT_APPROVAL_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode
      };

    // ------------------  2 ----------------------------
    case ActionTypes.INITIATE_TRANSACTION_TO_CLIENT_WALLET:
      return {
        ...state,
        errorMessage: '',
        transactionData: '',
        loading: true,
        loaded: false,
        loadedBlockChainTransaction: false
      };
    case ActionTypes.INITIATE_TRANSACTION_TO_CLIENT_WALLET_SUCCESS:
      return {
        ...state,
        transactionData: action.payload,
        loading: false,
        loaded: true,
        loadedBlockChainTransaction: true
      };
    case ActionTypes.INITIATE_TRANSACTION_TO_CLIENT_WALLET_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        loading: false
      };

    // ------------------  3 ----------------------------
    case ActionTypes.GENERATE_QR_CODE_IN_SYSTEM:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        insertedQrCodeData: '',
        walletQrId: '',
        loading: true,
        loaded: false
      };
    case ActionTypes.GENERATE_QR_CODE_IN_SYSTEM_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        insertedQrCodeData: action.payload,
        walletQrId: action.payload.walletQrId,
        loading: false,
        loaded: true
      };
    case ActionTypes.GENERATE_QR_CODE_IN_SYSTEM_ERROR:
      return {
        ...state,
        errorMessage: action.payload.data.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        insertedQrCodeData: '',
        walletQrId: '',
        loading: false,
        loaded: false
      };

    case ActionTypes.UPDATE_QR_CODE_TEXT_SUCCESS:
      return {
        ...state,
        insertedQrCodeData: action.payload[0],
        walletQrId: action.payload.walletQrId,
        loading: false,
        loaded: true
      };

    case ActionTypes.UPDATE_QR_CODE_TEXT:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case ActionTypes.UPDATE_QR_CODE_URL:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case ActionTypes.UPDATE_QR_CODE_TEXT_ERROR:

      return {
        ...state,
        errorMessage: (action.payload.data.length > 0) ? action.payload.data[0].message : action.payload.data.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        insertedQrCodeData: '',
        walletQrId: '',
        loading: false,
        loaded: false
      };

    // ------------------  7 GENERATE NFT FROM EXISTING QR CODE ----------------------------
    case ActionTypes.GENERATE_NFT_FROM_EXITING_QR_CODE:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        insertedQrCodeData: '',
        walletQrId: '',
        loadingNft: true,
        loadedNft: false
      };
    case ActionTypes.GENERATE_NFT_FROM_EXITING_QR_CODE_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        insertedQrCodeData: action.payload,
        walletQrId: action.payload.walletQrId,
        loadingNft: false,
        loadedNft: true
      };
    case ActionTypes.GENERATE_NFT_FROM_EXITING_QR_CODE_ERROR:
      return {
        ...state,
        errorMessage: action.payload.data.message,
        internalErrorCode: action.payload.data.internalErrorCode,
        insertedQrCodeData: '',
        walletQrId: '',
        loadingNft: false,
        loadedNft: false
      };


    // ------------------  7 GENERATE NFT FROM EXISTING QR CODE ----------------------------
    case ActionTypes.UPDATE_QR_CODE_FORWARD:
      return {
        ...state,
        errorMessage: '',
        internalErrorCode: '',
        loading: true,
        loaded: false
      };



    default:
      return { ...state };
  }
};

export default basicReducer;
