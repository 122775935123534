import React, { useCallback } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../redux/user/actions';
import ShowLoader from '../../view/ShowLoader';
import ShowError from '../../view/ShowError';
import ShowSuccess from '../../view/ShowSuccess';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required')
});


function LostPass() {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { loading, loaded, errorMessage, internalErrorCode, resetPasswordData } = useSelector((state) => state.user);

  // startFresh, transactionUserData, errorMessage, internalErrorCode,
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const formResetPassDipatch = useCallback(
    (dataForm) => {
      stableDispatch(resetPassword(dataForm));
    },
    [stableDispatch]
  );


  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let dataForm = {
        userEmail: values.email,
        userLang: i18n.resolvedLanguage
      };
      formResetPassDipatch(dataForm);
    }
  });


  let showData = '';
  if (loading) {
    showData = <ShowLoader />;
  } else if (errorMessage && internalErrorCode) {
    showData = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
  } else if (loaded && resetPasswordData) {
    showData = <ShowSuccess message={t('restetPasswordSuccessfullySent')} internalMessage={resetPasswordData} />;
  } else {
    showData = <FormBox t={t} formik={formik} />;
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 5 }}>
      <CssBaseline />
      {showData}
    </Container>
  );
}


const FormBox = ({ formik, t }) => {
  return (
    <>
      <Typography variant="h4" align="center" sx={{ pt: 3, mb: 2 }}>
        {t('resetPassword')}
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off" sx={{ mt: 1, width: '100%' }}>
        <TextField
          fullWidth
          margin="normal"
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: (theme) => theme.orangeButton.button }}>
          {t('sendResetPassMailBtn')}
        </Button>
      </Box>
    </>
  );
};

export default LostPass;
