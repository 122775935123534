import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import LinkHref from '../../components/LinkHref';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

// import OneGridElementForPrez from '../../view/OneGridElementForPrez';

// const gridData = [
//   {
//     text: 'Merchant Generate QR Code',
//     image: [
//       { link: 'static/cases/rakija_1.jpg' }
//     ]
//   },
//   {
//     text: 'Merchant send generated QR to the Client',
//     image: [
//       { link: 'static/cases/rakija_2.jpg' }
//     ]
//   },
//   {
//     text: 'Client receive link and add his details to create virtual contract',
//     image: [
//       { link: 'static/cases/rakija_3.jpg' }
//     ]
//   },
//   {
//     text: 'Create Virtual contract',
//     image: [
//       { link: 'static/cases/rakija_4.jpg' }
//     ]
//   },
//   {
//     text: 'Store All data on BlockChain',
//     image: [
//       { link: 'static/cases/rakija_5.jpg' }
//     ]
//   },
//   {
//     text: 'Verify on BlockChain',
//     image: [
//       { link: 'static/cases/rakija_6.jpg' }
//     ]
//   }
// ];

function HowToCreateQrCodeView() {
  return (
    <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
      <CssBaseline />
      <Typography variant="h5" align="left" sx={{ mb: 2 }}>
        How To : Dodaj jedinstvenost na svoju aktivnost ili proizvod
      </Typography>
      <CssBaseline />

      <Typography variant="body1" align="left" sx={{ mt: 3, mb: 2 }}>
        Korak 1. {' '}
        <LinkHref sx={{ color: 'yellow' }} to="/register-user">
          Registruj   {' '}
        </LinkHref> se na sajtu
      </Typography>

      <Typography variant="body1" align="left" sx={{ mt: 3, mb: 2 }}>
        Korak 2. {' '} Generisi QR Code na
        <LinkHref sx={{ color: 'yellow' }} to="/generate-qr-code">
          {' '} ovom linku
        </LinkHref>
      </Typography>


      <Box align="left" sx={{ mt: 3, mb: 2 }}>
        Korak 3. {' '} Dobicete instrukcije na email kako da odstampate QR code.
        <ul>
          <li>  Na  {' '}
            <Link
              sx={{ color: 'green' }}
              underline="none"
              href="https://www.qrcode-monkey.com/#url"
              color="primary.black"
              target="_blank"
            >
              ovom linku
            </Link>
            {' '} treba da se kreira PDF verzija dobijenog QR coda u visokoj rezoluciji.</li>

          <li>U polju &quot;Your URL&quot; dodate link od generisanog QR koda. Link je u ovom obliku :  {process.env.REACT_APP_WEB_SITE}/status/XXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX</li>
          <li> Link od vaseg artikla mozete pronaci u emailu sto ste dobili pri generisanju QR coda, ili mozete pronaci na nasem sajtu
            tako sto kliknete
            <LinkHref sx={{ color: 'yellow' }} to="/list-qr-codes">
              {' '} ovde
            </LinkHref> u kopirate vrednost za artikal u koloni 	&quot;Qr Code link&quot;
          </li>
          <li>Ako ste sve dobro uradili trebalo bi da imate PDF file slican kako u  {' '}
            <Link
              sx={{ color: 'orange' }}
              underline="none"
              href={`${process.env.REACT_APP_WEB_SITE}/static/qr-codes/qr-code-pdf-example.pdf`}
              color="primary.black"
              target="_blank"
            >
              nasem primeru
            </Link>
          </li>
        </ul>

      </Box>
      <Box align="left" sx={{ mt: 3, mb: 2 }}>
        Korak 4. {' '} Kako da odstampam QR code. Imate neogranicen broj opcija, ali mi cemo da nabrojimo samo par njih.
        <ul>
          <li>Samolepljivi papir - Ako cete za zaleptie QR kod za prozivod</li>
          <li>Lasersko graviranje na metalnoj plocici - Ako ce QR code biti  na otvorenom</li>
          <li>Tag na odeci</li>
          <li> 
             <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href="/static/cases/general_1.jpg"
                    underline="none"
                    sx={{ color: 'orange' }}
                >
                    Pogledajte sliku 
                </Link>
          </li>
        </ul>
      </Box>
      <Typography variant="body1" align="left" sx={{ mt: 3, mb: 2 }}>
        Video prezentaciju celog postupka mozete pogledati ovde
        
      </Typography>


      {/* <OneGridElementForPrez gridData={gridData} /> */}

    </Container>
  );
}

export default HowToCreateQrCodeView;
