import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getListQrCodesOwners } from '../../redux/tree/actions';

import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useTranslation } from 'react-i18next';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';

// function createData(who, userEmail, when, where, why, what, number, status, action) {
//   return { who, userEmail, when, where, why, what, number, status, action };
// }

/* const rows = [
  //createData('JKP Zelenilo Beograd', 'zelenilo@blokaria.com', '4/5/2022', 'Lisovic', 'Afforestation of animals path between forest', 'beech, oak, walnut', 2000, 0, { status: 0, buttonName: 'Own your tree' }),
  createData('JKP Zelenilo Beograd', 'zelenilo@blokaria.com', '4/8/2022', 'Blokovi/Beograd', 'Embankment by the river Sava', 'linden, pine, fir', 200, 1, { status: 1, buttonName: 'Own tree' }),
  createData('Association of Citizens 1', 'association_one@blokaria.com', '5/5/2022', 'Local Garden Blokovi/Beograd', 'Garden for common good', 'purchase vegetables, trees', 120, 0, { status: 1, buttonName: 'Support' }),
  createData('Association of Citizens 2', 'association_two@blokaria.com', '5/5/2022', 'Local Museum', 'Support Local Museum', 'whitewashing walls, chairs', 55, 0, { status: 1, buttonName: 'Support' }),
  createData('Vinery 123', 'nemanjamil@gmail.com', '9/5/2022', 'Kosmaj', 'Vine produces', '', 10, 1, { status: 1, buttonName: 'Purchase' })
]; */


function TreeListPage() {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { qrCodeOwnersList, errorMessage, internalErrorCode, loaded, loading } = useSelector((state) => state.tree);

  const callListOfQrCodeOwners = useCallback(() => {
    dispatch(getListQrCodesOwners());
  }, [dispatch]);

  useEffect(() => {
    callListOfQrCodeOwners();
  }, []);

  let showOwnersOfQrCodes = '';
  switch (true) {
    case (loading):
      showOwnersOfQrCodes = <ShowLoader />;
      break;
    case (errorMessage !== '' && !loaded):
      showOwnersOfQrCodes = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
      break;
    case (loaded && qrCodeOwnersList.length > 0):
      showOwnersOfQrCodes = <SystemProducers t={t} navigate={navigate} qrCodeOwnersList={qrCodeOwnersList} />;
      break;
    default:
      showOwnersOfQrCodes = 'No Data';
      break;
  }

  return (
    <>
      <WrapperPage>
        <WrapperContainer>
          {showOwnersOfQrCodes}
        </WrapperContainer>
      </WrapperPage>
    </>
  );
}

const SystemProducers = ({ navigate, qrCodeOwnersList, t }) => {
  // this need to be joined 
  return <Container className='blokariaUsersDiv' name="WrapperContainer" maxWidth="xl" sx={{ pt: 0, pb: 4 }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        my: 4
      }}
    >
      <Typography variant="h4" color="info" sx={{ my: 2 }}>
        {t('blokariaUsers')}
      </Typography>
      <Typography variant="body1" color="info">
        {t('we-all-have-favorits')}
      </Typography>
    </Box>
    <TreeTableSystem t={t} navigate={navigate} qrCodeOwnersList={qrCodeOwnersList} />
  </Container>;
};

const TreeTableSystem = ({ navigate, qrCodeOwnersList, t }) => {

  return (<TableContainer>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: 'secondary.main' }}>
        <TableRow>
          <TableCell>{t('actionOwner')}</TableCell>
          <TableCell align="left">Email</TableCell>
          <TableCell align="left">{t('numOfProducts')}</TableCell>
          <TableCell align="left">{t('status')}</TableCell>
          <TableCell align="left">{t('action')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {qrCodeOwnersList.map((row, i) => (
          <TableRow
            key={i}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.userFullName[0]}
            </TableCell>
            <TableCell align="left">{row.userEmail[0]}</TableCell>
            <TableCell align="left">{row.count}</TableCell>
            <TableCell align="left">{
              (row.userEmail[0]) ? <LockOpenIcon color='success' /> : <LockIcon color="warning" />
            }</TableCell>
            <TableCell align="left">
              <Button variant="contained" size="small"
                onClick={() => {
                  navigate('/list-of-qrcodes-per-organization', {
                    state: {
                      userEmail: row.userEmail[0]
                    }
                  });
                }}
                sx={{backgroundColor: (theme) => theme.orangeButton.button}}>
                {t('listOfProducts')}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>);
};

export default TreeListPage;