import React, { useEffect, useCallback } from 'react';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQrCodeData, clearState } from '../../redux/basic/actions';
import QrCodeGeneratedSuccessfully from '../../view/QrCodeGeneratedSuccessfully';
import StatusQrCodeComponentError from '../../view/StatusQrCodeComponentError';


const GeneratedQrcodeComponent = () => {
  const { qrcode } = useParams();
  const { insertedQrCodeData, errorMessage, internalErrorCode } = useSelector((state) => state.basic);
  const { token } = useSelector((state) => state.token);

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const dispatchCheckExistingQrCode = useCallback(
    (qrcode) => {
      stableDispatch(clearState());
      (token && qrcode) ? stableDispatch(getQrCodeData(qrcode, token)) : '';
    },
    [stableDispatch]
  );

  useEffect(() => {
    dispatchCheckExistingQrCode(qrcode);
  }, [dispatchCheckExistingQrCode, qrcode]);

  let stateStatus = '';

  if (insertedQrCodeData) {
    stateStatus = <QrCodeGeneratedSuccessfully data={{ qrcode, insertedQrCodeData }} />;
  } else if (errorMessage && internalErrorCode) {
    stateStatus = <StatusQrCodeComponentError dataError={{ errorMessage, internalErrorCode }} />;
  } else {
    stateStatus = '';
  }
  return (<>
    <Container maxWidth="md">
      {stateStatus}
    </Container></>);
};

export default GeneratedQrcodeComponent;
