import { makePostRequest, makeGetRequest } from '../request';

export const getAllQrCodesByUser = (userEmail) => makePostRequest('nrapi/wallet/getListQrCodesByUser', null, { userEmail });

export const getListQrCodesGeneral = (data) => makePostRequest('nrapi/wallet/getListQrCodesGeneral', null, data);

export const getListQrCodesOwners = (data) => makePostRequest('nrapi/wallet/getListQrCodesOwners', null, data);

export const deleteQrCode = ({ walletQrId, token }) => makePostRequest('api/wallet/deleteQrCode', token, { walletQrId });

export const createNewProject = ({ data, token }) => makePostRequest('api/project/addNewProject', token, data);

export const listAllProject = (token) => makeGetRequest('api/user/listProjectByUser', token);

export const addQrCodeToProject = ({ token, itemId, projectId }) => makePostRequest('api/project/addQrCodeToProject', token, { itemId, projectId });

export const deleteProject = ({ token, projectId }) => makePostRequest('api/project/deleteProject', token, { projectId });

export const updateProject = ({ projectData, projectId, token }) => makePostRequest('api/project/updateProject', token, { projectData, projectId });

export const getOneProject = ({ projectId, token }) => makeGetRequest(`nrapi/project/getOneProject/${projectId}`, token, null);

export const changeStatusOfQrCode = ({ qrcode, status, token }) => makePostRequest('api/wallet/changeStatusOfQrCode', token, { qrcode, status });

export const listAllProjectNrApi = ({ idcode }) => makePostRequest('nrapi/project/listAllProjectNrApi', null, { idcode });

export const getListQrCodesByUserPrivate = ({ token, generated }) => makePostRequest('api/wallet/getListQrCodesByUserPrivate', token, { generated });

export const getListQrCodesByUser = ({ token }) => makePostRequest('api/wallet/getListQrCodesByUser', token, null);

export const updateListDataInDb = ({ token, qrCode, state }) => makePostRequest('api/wallet/updateStory', token, { qrCode, state });
