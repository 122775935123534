import { makePostRequest } from '../request';

export const registerUser = (data) => makePostRequest('nrapi/user/registerUser', null, data);

export const logInUser = (data) => makePostRequest('nrapi/v1/auth/authenticate', null, data);

export const getUserData = ({userEmail, token}) => makePostRequest('api/user/userGet', token, {userEmail});

export const resetPassword = (userEmail) => makePostRequest('nrapi/user/resetPassword', null, userEmail);

export const resetPasswordCode = (data) => makePostRequest('nrapi/user/resetPasswordCode', null, data);

export const sendApprovalToClient = (data) => makePostRequest('nrapi/wallet/sendApprovalToClient', null, data);

