import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



function UserInterfaceFlowView() {
    return (
        <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
            <CssBaseline />
            <Typography variant="h5" align="left" sx={{ mb: 2 }}>
                UserInterfaceFlowView
            </Typography>
            <Box align="left" sx={{ mb: 2 }}>
            Dijagram toka korisničkog interfejsa prikazuje određene stranice ili ekrane unutar funkcionalnog dizajna i prikazuje kako da se krećete kroz
ekrane prema različitim mogućnostima.
Kutije na ovom dijagramu predstavljaju ekrane u korisničkom interfejsu. Pune linije pokazuju dozvoljene tokove između
ekrana a isprekidane linije predstavljaju povratni tok (navigaciju) između ekrana. Na primer, korisnik može da pređe sa
Početnog ekrana na ekran za prijavljivanje da bi se se prijavio u aplikaciju kao Vlasnik, ili on/ona može direktno da pređe sa početnog ekrana na
ekran kamere kao Gost.

            </Box>
            <Box align="left" sx={{ mb: 2 }}>
            Postojaće dva različita tipa korisnika aplikacije:
Gost – Korisnik koji može da preuzme, instalira i koristi aplikaciju bez kreiranja naloga. Korišćenje aplikacije će biti ograničeno samo za preuzimanje već postavljenog sadržaja dostupnom ekranu. Na primer, gost može da skenira QR kodove i vidi priloženi sadržaj za određeni objekat;
Vlasnik – Korisnik koji ima registrovan nalog i mogućnost kreiranja, dodavanja, uređivanja i brisanja sadržaja objekata na određenom ekranu. Pored funkcionalnosti za upravljanje sadržajem, Vlasnik ima i sve funkcije kao gost

            </Box>
        </Container>
    );
}



export default UserInterfaceFlowView;
