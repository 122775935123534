import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const GenerateQrCodeWelcome = () => {
  const { t } = useTranslation();

  return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="h4" align="center" sx={{mt:4}}>
            {t('generateQrTag')}
          </Typography>
        </Box>
      </Container>
  );
};

export default GenerateQrCodeWelcome;
