import React, { useCallback, useEffect } from 'react';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { clearStateUser } from '../../redux/user/actions';
import {
    deleteQrCode, addQrCodeToProject,
    changeStatusOfQrCode, getListQrCodesByUserPrivate,
    updateListQrCodesOnlyRedux, updateOneElementInDb
} from '../../redux/tree/actions';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ImageIcon from '@mui/icons-material/Image';
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { Link as RouterLink } from 'react-router-dom';
import ShowLoader from '../../view/ShowLoader';
import ShowError from '../../view/ShowError';
import ShowSuccess from '../../view/ShowSuccess';
import Tooltip from '@mui/material/Tooltip';
import LinkHref from '../LinkHref';
import DialogView from '../../view/DialogView';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { checkIfNftExist } from '../../common/commonFunctions';
import { listAllProject } from '../../redux/tree/actions';
import SelectProjectView from '../../view/SelectProjectView';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');


function UserListQrCodes() {

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    //const [checked, setChecked] = React.useState([]);
    const [getWalletQrId, setWalletQrId] = React.useState('');

    const { token } = useSelector((state) => state.token);
    const { transactionUserData } = useSelector((state) => state.user);
    const { listUserProjects, listOfQrCodeOwnedByUser, errorMessage, internalErrorCode, loading, loaded } = useSelector((state) => state.tree);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);


    const getListOfProjects = useCallback((token) => {
        dispatch(listAllProject(token));
    });

    useEffect(() => {
        getListOfProjects(token);
    }, []);

    const handleDeleteQrCode = (value) => {
        setOpen(true);
        setWalletQrId(value);
    };
    const handleEditQrCode = (value) => {
        navigate(`/editqrcode/${value}`);
    };

    // On Click
    const dispatchToDeleteQrCode = useCallback((value) => {
        stableDispatch(deleteQrCode(value, token));
    }, [stableDispatch]);

    const handleClose = (value) => {
        setOpen(false);
        (value) ? dispatchToDeleteQrCode(getWalletQrId) : '';
    };

    // on Load
    const dispatchToClearRedux = useCallback((userEmail, token) => {
        let generated = true;
        (userEmail && token) ? stableDispatch(getListQrCodesByUserPrivate(token, generated)) : stableDispatch(clearStateUser());
    }, [stableDispatch]);

    let userEmail = transactionUserData?.userEmail;
    useEffect(() => {
        dispatchToClearRedux(userEmail, token);
    }, [dispatchToClearRedux]);

    const dispatchToAddQrCodeToProject = useCallback((itemId, projectId, token) => {
        dispatch(addQrCodeToProject(itemId, projectId, token, true));
    });

    let menuItems = [];
    if (listUserProjects && listUserProjects.length > 0) {
        menuItems = listUserProjects.map((item) => {
            return (<MenuItem key={item._id} value={item._id}>{item.projectName}</MenuItem>);
        });
    }

    let selectedComponent = (projectId, walletId) => {
        return (<SelectProjectView
            handleChangeProject={handleChangeProject}
            projectId={projectId}
            walletId={walletId}
            listUserProjects={listUserProjects}
            menuItems={menuItems}
        />);
    };

    const handleChangeProject = (itemId, e) => {
        let projectId = e.target.value;
        dispatchToAddQrCodeToProject(itemId, projectId, token);
    };

    const dispatchToChangeStatusQrCode = useCallback((qrcode, status, generated, token) => {
        dispatch(changeStatusOfQrCode(qrcode, status, generated, token));
    });

    const handleChangePublicQrCode = (qrcode) => (e) => {
        let status = (e.target.value === true) ? true : false;
        let generated = true;
        dispatchToChangeStatusQrCode(qrcode, status, generated, token);
    };

    const handleEnableStory = (value, walletQrId) => {
        let newListOfQrCodes = listOfQrCodeOwnedByUser.map(obj => {
            if (obj.walletQrId === walletQrId) {
                console.log('Usao', obj);
                return { ...obj, hasstory: value };
            }
            // 👇️ otherwise return object as is
            return obj;
        });
        dispatch(updateOneElementInDb(token, walletQrId, value));
        dispatch(updateListQrCodesOnlyRedux(newListOfQrCodes));
    };


    let showData = '';
    if (loading) {
        showData = <ShowLoader />;
    } else if (errorMessage && internalErrorCode) {
        showData = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
    } else if (!loading && loaded && _.isEmpty(listOfQrCodeOwnedByUser)) { //&& listOfQrCodeOwnedByUser.length === 0
        showData = <ShowSuccess message="No data" />;
    }
    else if (loaded && listOfQrCodeOwnedByUser.length > 0) {
        showData = <TableContainerWrapper
            t={t}
            handleDeleteQrCode={handleDeleteQrCode}
            listOfQrCodeOwnedByUser={listOfQrCodeOwnedByUser}
            listUserProjects={listUserProjects}
            handleChangeProject={handleChangeProject}
            selectedComponent={selectedComponent}
            handleChangePublicQrCode={handleChangePublicQrCode}
            handleEditQrCode={handleEditQrCode}
            checkIfNftExist={checkIfNftExist}
            handleEnableStory={handleEnableStory}
        />;
    } else {
        showData = <ShowSuccess message="No data" />;
    }

    return (
        <Container maxWidth="xl" sx={{ pt: 0, pb: 2 }}>
            <DialogView handleClose={handleClose} open={open} />
            {showData}
        </Container>
    );
}

const TableContainerWrapper = ({ t, listOfQrCodeOwnedByUser, handleDeleteQrCode,
    handleChangeProject, listUserProjects, selectedComponent, handleChangePublicQrCode,
    handleEditQrCode, checkIfNftExist, handleEnableStory }) => {

    let showTables = [];

    let showTablesUserQr = (listOfQrCodeOwnedByUser.length > 0) ? <TableContainerHead
        t={t}
        handleDeleteQrCode={handleDeleteQrCode}
        title={t('listOfGeneratedQrCodes')}
        handleChangeProject={handleChangeProject}
        listUserProjects={listUserProjects}
        selectedComponent={selectedComponent}
        key="1"
        handleEditQrCode={handleEditQrCode}
        checkIfNftExist={checkIfNftExist}
        handleChangePublicQrCode={handleChangePublicQrCode}
        handleEnableStory={handleEnableStory}
        listOfQrCodeOwnedByUser={listOfQrCodeOwnedByUser} /> : '';

    showTables.push(showTablesUserQr);
    return (showTables);
};

const TableContainerHead = ({ t, listOfQrCodeOwnedByUser, title, handleDeleteQrCode, selectedComponent, handleChangePublicQrCode,
    handleEditQrCode, checkIfNftExist, handleEnableStory }) => {
    return (<>
        <Typography variant="h4" align="center" sx={{ my: 4, pt: 4 }}>
            {title}
        </Typography><TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('qrCodeName')}</TableCell>
                        <TableCell align="left">{t('status')}</TableCell>
                        <TableCell align="left">{t('access')}</TableCell>
                        <TableCell align="left">{t('access')}</TableCell>
                        <TableCell align="left">Enable Story</TableCell>
                        <TableCell align="left">Story Edit</TableCell>
                        <TableCell align="left">{t('nftCrated')}</TableCell>
                        <TableCell align="left">{t('linkForClient')}</TableCell>
                        <TableCell align="left">{t('addToProject')}</TableCell>
                        {/* <TableCell align="left">Qr Code Id</TableCell> */}
                        <TableCell align="left">{t('action')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBodyData
                    t={t}
                    listOfQrCodeOwnedByUser={listOfQrCodeOwnedByUser}
                    handleDeleteQrCode={handleDeleteQrCode}
                    selectedComponent={selectedComponent}
                    handleChangePublicQrCode={handleChangePublicQrCode}
                    handleEditQrCode={handleEditQrCode}
                    checkIfNftExist={checkIfNftExist}
                    handleEnableStory={handleEnableStory}
                />
            </Table>
        </TableContainer></>);
};



const TableBodyData = ({ t, listOfQrCodeOwnedByUser, handleDeleteQrCode, selectedComponent, handleChangePublicQrCode,
    handleEditQrCode, checkIfNftExist, handleEnableStory }) => {
    if (listOfQrCodeOwnedByUser.length > 0) {
        return <TableBody>
            {listOfQrCodeOwnedByUser.map((item) => {

                let statusRedeemd = (item.qrCodeRedeemStatus) ?
                    <Tooltip title={t('productIsPurchased')} ><LockIcon color="warning" /></Tooltip> :
                    <Tooltip title={t('productIsAvalableForPurchase')}><LockOpenIcon color='success' /></Tooltip>;
                let stateAccessible = (item.publicQrCode) ?
                    <Tooltip title={t('productIsPubliclyAvailable')}><PublicIcon color="success" /></Tooltip> :
                    <Tooltip title={t('productIsReserved')}><VpnLockIcon color="warning" /></Tooltip>;

                let nftCreated = (checkIfNftExist(item)) ?
                    <Tooltip title={t('nftIsAlradyCreatedForThisQrCode')}><ImageIcon color="info" /></Tooltip> :
                    <LinkHref color='#ff8d00' to={`/generate-nft/${item.walletQrId}`}>
                        {t('createNft')}
                    </LinkHref>;

                let stateSelected = (item.qrCodeRedeemStatus) ? true : false;

                //let linkToCardanoExplorer = (item.transactionId) ? `${process.env.REACT_APP_TESTNET}${item.transactionId}` : '';
                //let linkToCardanoExplorerName = (item.transactionId) ? 'BlockChain Link' : '';

                let linkToAccessCode = (!item.publicQrCode) ?
                    `${process.env.REACT_APP_WEB_SITE}/generate-contract/${item.walletQrId}/${item.accessCode}` :
                    '';

                return <TableRow
                    key={item._id}
                    selected={stateSelected}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                    }}
                >
                    <TableCell component="th" scope="row">
                        <Link
                            component={RouterLink}
                            to={`/status/${item.walletQrId}`}
                            //underline="none"
                            color="info.light"
                            sx={{ my: 1, mx: 1.5 }}
                        >
                            {item.productName}
                        </Link>
                    </TableCell>
                    <TableCell align="left">
                        <LinkHref to={`/generate-contract/${item.walletQrId}`}>
                            {statusRedeemd}
                        </LinkHref>

                    </TableCell>
                    <TableCell align="left">
                        <LinkHref to={`/generate-contract/${item.walletQrId}`}>
                            {stateAccessible}
                        </LinkHref>
                    </TableCell>

                    <TableCell align="left">
                        <Select size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.publicQrCode}
                            label="Status"
                            onChange={handleChangePublicQrCode(item.walletQrId)}
                            sx={{ background: '#0277bd' }}
                        >
                            <MenuItem value={true}>Public</MenuItem>
                            <MenuItem value={false}>Private</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell align="center">
                        <Grid container direction="column"
                            justifyContent="center"
                            alignItems="top"
                            sx={{ my: 3, border: '0px solid' }}>
                            <Grid item xs={12} md={12}>


                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControlLabel control={<Checkbox
                                    id="forwardurl"
                                    name="forwardurl"
                                    onChange={(e) => {
                                        handleEnableStory(e.target.checked, item.walletQrId);
                                    }}
                                    checked={item.hasstory}
                                />} label={(item.hasstory) ? '' : ''} />

                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell align="left">
                        <Box sx={{}}>
                            {(item.hasstory && checkIfNftExist(item)) ? (<>
                                <Box>
                                    <LinkHref to={`/story/${item._id}`}>
                                        Story
                                    </LinkHref>
                                </Box>
                                <Box>
                                    <LinkHref color={'orange'} to={`/editqrcodestory/${item.walletQrId}`}>
                                        Edit
                                    </LinkHref>
                                </Box>
                            </>) : ''}
                        </Box>
                    </TableCell>

                    <TableCell align="left">
                        <Box sx={{ color: 'white' }}>
                            {nftCreated}
                        </Box>
                    </TableCell>
                    {/* <TableCell align="left">
                        <Box>
                            <Link variant="span" underline="none" color="secondary" target="_blank" rel="noreferrer noopener" href={linkToCardanoExplorer}>
                                {linkToCardanoExplorerName}
                            </Link>
                        </Box>
                        <Box sx={{ fontSize: '10px' }}>
                            {item.walletQrId}
                        </Box>
                    </TableCell> */}
                    <TableCell align="left" sx={{ fontSize: '12px' }}>
                        <Tooltip title="Kopirajte ovaj link i pošaljite klijentu">
                            <Link
                                target="_blank"
                                rel="noreferrer noopener"
                                //component={RouterLink} ovo ide na to=""
                                href={linkToAccessCode}
                                underline="none"
                                color="info.light"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                {(linkToAccessCode) ? 'link ' : ''}
                            </Link>
                        </Tooltip>

                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="left">
                        {selectedComponent(item._project, item._id)}
                    </TableCell>
                    {/* <TableCell sx={{ fontSize: '12px' }} align="left">
                        {item.walletQrId}
                    </TableCell> */}
                    <TableCell align="left">
                        <Button size="small" variant="outlined" color="secondary" fullWidth onClick={() => handleDeleteQrCode(item.walletQrId)} sx={{ backgroundColor: (theme) => theme.orangeButton.button }}>
                            {t('delete')}
                        </Button>

                        <Button size="small" variant="outlined" sx={{ mt: 1, backgroundColor: (theme) => theme.orangeButton.button }} color="secondary" fullWidth onClick={() => handleEditQrCode(item.walletQrId)}>
                            Edit
                        </Button>

                    </TableCell>
                </TableRow>;
            })
            }
        </TableBody >;
    }
};

export default UserListQrCodes;