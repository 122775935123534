import { takeEvery, call, put } from 'redux-saga/effects';
import * as ActionTypes from './actions';
import Api from '../../api/calls';

import * as ActionTypesUser from '../user/actions';

function* getQrCodeData(action) {
  try {
    const result = yield call(Api.exam.getQrCodeData, action.payload);
    yield put(ActionTypes.getQrCodeDataSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getQrCodeDataError(error.response.data));
  }
}

function* getQrCodeDataNoRedeem(action) {

  try {
    const result = yield call(Api.exam.getQrCodeDataNoRedeem, action.payload);
    yield put(ActionTypes.getQrCodeDataSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.getQrCodeDataError(error.response.data));
  }
}

function* generateContract(action) {
  try {
    const result = yield call(Api.exam.generateContract, action.payload);
    yield put(ActionTypes.generateContractSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.generateContractError(error.response.data));
  }
}

function* generateContractApproval(action) {
  try {
    const result = yield call(Api.exam.generateContractApproval, action.payload);
    yield put(ActionTypes.generateContractApprovalSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.generateContractApprovalError(error.response.data));
  }
}

function* initiateTransactionToClientWallet(action) {
  try {
    const result = yield call(Api.exam.initiateTransactionToClientWallet, action.payload);
    yield put(ActionTypes.initiateTransactionToClientWalletSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.initiateTransactionToClientWalletError(error.response.data));
  }
}

function* generateQrCodeInSystem(action) {

  let generatenft = action.payload.dataForm.generatenft;

  try {
    const result = (generatenft) ?
      yield call(Api.exam.generateQrCodeInSystem, action.payload) :
      yield call(Api.exam.generateQrCodeInSystemNoImage, action.payload);

    yield put(ActionTypes.generateQrCodeInSystemSuccess(result.data));

    let userEmail = action.payload.dataForm.userEmail;
    let token = action.payload.token;
    yield put(ActionTypesUser.getUserData(userEmail, token));

  } catch (error) {
    yield put(ActionTypes.generateQrCodeInSystemError(error.response.data));
  }
}

function* updateQrCodeText(action) {
  try {
    const result = yield call(Api.exam.updateQrCodeText, action.payload);
    yield put(ActionTypes.updateQrCodeTextSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.updateQrCodeTextError(error.response.data));
  }
}

function* updateQrCodeUrl(action) {
  try {
    const result = yield call(Api.exam.updateQrCodeUrl, action.payload);
    yield put(ActionTypes.updateQrCodeTextSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.updateQrCodeTextError(error.response.data));
  }
}

function* updateQrCodeUrlForward(action) {
  try {
    const result = yield call(Api.exam.updateQrCodeUrlForward, action.payload);
    yield put(ActionTypes.updateQrCodeTextSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.updateQrCodeTextError(error.response.data));
  }
}
function* updateNftStory(action) {
  try {
    const result = yield call(Api.exam.updateNftStory, action.payload);
    yield put(ActionTypes.updateQrCodeTextSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.updateQrCodeTextError(error.response.data));
  }
}

function* getQrCodeFromId(action) {
  try {
    const result = yield call(Api.exam.getQrCodeFromId, action.payload);
    yield put(ActionTypes.updateQrCodeTextSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.updateQrCodeTextError(error.response.data));
  }
}

function* generateNftFromExistingQrCode(action) {

  console.log('action.payload', action.payload);
  try {
    let result = yield call(Api.exam.generateNftFromExistingQrCode, action.payload);
    yield put(ActionTypes.generateNftFromExistingQrCodeSuccess(result.data));
  } catch (error) {
    yield put(ActionTypes.generateNftFromExistingQrCodeError(error.response.data));
  }
}

// function* getCallCidValue(action) {
//   try {
//     const cidValueImage = yield web3StorageClient.get(action.payload);
//     const responseFiles = yield cidValueImage.files();
//     yield put(ActionTypes.getCallCidValueSuccess(responseFiles));
//   } catch (error) {
//     yield put(ActionTypes.getCallCidValueError(error));
//   }
// }

export default function* basicSaga() {
  yield takeEvery(ActionTypes.GET_QR_CODE_DATA, getQrCodeData);
  yield takeEvery(ActionTypes.GENERATE_CONTRACT, generateContract);
  yield takeEvery(ActionTypes.GENERATE_CONTRACT_APPROVAL, generateContractApproval);
  yield takeEvery(ActionTypes.INITIATE_TRANSACTION_TO_CLIENT_WALLET, initiateTransactionToClientWallet);
  yield takeEvery(ActionTypes.GENERATE_QR_CODE_IN_SYSTEM, generateQrCodeInSystem);
  yield takeEvery(ActionTypes.GET_QR_CODE_DATA_NO_REDEEM, getQrCodeDataNoRedeem);
  yield takeEvery(ActionTypes.UPDATE_QR_CODE_TEXT, updateQrCodeText);
  yield takeEvery(ActionTypes.UPDATE_QR_CODE_URL, updateQrCodeUrl);
  yield takeEvery(ActionTypes.UPDATE_QR_CODE_FORWARD, updateQrCodeUrlForward);
  yield takeEvery(ActionTypes.UPDATE_NFT_STORY, updateNftStory);
  yield takeEvery(ActionTypes.GET_QR_CODE_FROM_ID, getQrCodeFromId);
  yield takeEvery(ActionTypes.GENERATE_NFT_FROM_EXITING_QR_CODE, generateNftFromExistingQrCode);
  //yield takeEvery(ActionTypes.GET_CALL_CID_VALUE, getCallCidValue);
}
