import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
// import videoBackground from '../../../../src/video4.mp4';

function ThreeminPitchView() {
  const { t } = useTranslation();

  return (
    <>
      <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh', borderRadius: '15px', background: '#094164ab' }}>
        <CssBaseline />
        <Typography variant="h4" display="flex" align="left" justifyContent="center" sx={{ mb: 2, pt: 4 }}>
          {t('three-min-pitch')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step1')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step2')}
        </Typography>
        <Box align="left" sx={{ mb: 2 }}>
          <ul>
            <li>{t('three-min-pitch-desc.step3.label1')}</li>
            <li>{t('three-min-pitch-desc.step3.label2')}</li>
          </ul>
        </Box>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step4')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step5')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step6')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step7')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step8')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step9')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step10')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step11')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step12')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step13')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step14')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step15')}
        </Typography>
        <Typography variant="body1" align="left" sx={{ mb: 2 }}>
          {t('three-min-pitch-desc.step16')}
        </Typography>
      </Container>
      {/* <video src={videoBackground} autoPlay muted loop></video> */}
    </>
  );
}

export default ThreeminPitchView;
