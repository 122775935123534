import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

function KeyValueFormView(props) {
    const [keyValueList, setKeyValueList] = useState([{ key: '', value: '' }]);
    const handleAdd = () => {
        setKeyValueList([...keyValueList, { key: '', value: '' }]);
    };
    const handleRemove = (index) => {
        const list = [...keyValueList];
        list.splice(index, 1);
        setKeyValueList(list);
        props.keyValueFormData(list);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...keyValueList];
        list[index][name] = value;
        setKeyValueList(list);
        props.keyValueFormData(keyValueList);
    };

    return (<>
        {keyValueList.map((keyValue, index) => (
            <Grid container spacing={0} sx={{ mt: 1 }} key={index}>
                <Grid>
                    <TextField
                        name="key"
                        type="text"
                        id="key"
                        focused
                        inputProps={{ maxLength: 20 }}
                        label="Key (max 20 characters)" variant="outlined"
                        value={keyValue.key}
                        onChange={(e) => handleChange(e, index)}
                    />

                    <TextField
                        name="value"
                        label="Value (max 50 characters)" variant="outlined"
                        id="value"
                        focused
                        inputProps={{ maxLength: 50 }}
                        value={keyValue.value}
                        onChange={(e) => handleChange(e, index)}
                        sx={{ ml: 1 }}
                    />
                    <Grid sx={{ mt: 1 }}>
                        {keyValueList.length - 1 === index && keyValueList.length < 5 && (
                            <Button
                                type="button"
                                onClick={handleAdd}
                                variant="outlined"
                            >
                                <span>Add Key-Value</span>
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid sx={{ mt: 1, ml: 1 }}>
                    {keyValueList.length !== 1 && (
                        <Button
                            type="button"
                            variant="outlined"
                            onClick={() => handleRemove(index)}
                            color="error"
                        >
                            <span>Remove</span>
                        </Button>
                    )}
                </Grid>
            </Grid>
        ))}
    </>);
}

export default KeyValueFormView;