import React, { useEffect } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { sendApprovalToClient } from '../../redux/user/actions';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';
import ShowSuccess from '../../view/ShowSuccess';
import { useTranslation } from 'react-i18next';

function CreatorApprovalView() {
    const { t, i18n } = useTranslation();
    const location = useLocation().search;
    let paramsUrl = new URLSearchParams(location);
    let qrcode = paramsUrl.get('walletQrId');
    let clientEmail = paramsUrl.get('clientEmail');
    let clientName = paramsUrl.get('clientName');

    const { loading, loaded, errorMessage, internalErrorCode, sendApprovalFromClient } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const dispatchApprovalEmail = (qrcode, clientEmail, clientName) => {
        let userLang = i18n.resolvedLanguage;
        dispatch(sendApprovalToClient(qrcode, clientEmail, clientName, userLang));
    };

    useEffect(() => {
        dispatchApprovalEmail(qrcode, clientEmail, clientName);
    }, []);

    let qrCodeView;

    switch (true) {
        case (loading):
            qrCodeView = <ShowLoader />;
            break;
        case (errorMessage !== '' && !loaded):
            qrCodeView = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
            break;
        case (loaded && sendApprovalFromClient):
            qrCodeView = <ShowSuccess message={t('messageIsSentToUser')} />;
            break;
        default:
            qrCodeView = <ShowLoader color="secondary" />;
            break;
    }

    return (
        <WrapperPage>
            <WrapperContainer>
                <Container component="main" maxWidth="lg" sx={{ minHeight: '70vh' }}>
                    {qrCodeView}
                </Container>
            </WrapperContainer>
        </WrapperPage>


    );
}

export default CreatorApprovalView;