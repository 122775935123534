import React, { useCallback, useEffect } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, clearStateNoUserData } from '../../redux/user/actions';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object({
  email: yup.string('Unesite Vaš email').email('Unesite validan email').required('Email je obavezan!'),
  userFullname: yup.string('Unesite Vaše ime').max(40, 'Ime može biti maksimalno 40 karaktera').required('Ime je obavezno!'),
  password: yup.string('Unesite lozinku').min(8, 'Minimalno 8 karaktera').required('Obavezno polje!'),
  confirmPassword: yup
    .string()
    .required('Obavezno!')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Obe lozinke moraju biti iste!')
    })
});

function RegisterUser() {
  const { t, i18n } = useTranslation();
  const { loaded, loading, errorMessage, internalErrorCode, loadedReg } = useSelector((state) => state.user); // transactionUserData
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const recaptchaRef = React.createRef();

  const formRegisterUserDipatch = useCallback(
    (dataForm) => {
      stableDispatch(registerUser(dataForm));
    },
    [stableDispatch]
  );

  // On Load
  const dispatchToClearRedux = useCallback(() => {
    stableDispatch(clearStateNoUserData());
  }, [stableDispatch]);

  useEffect(() => {
    dispatchToClearRedux();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      userFullname: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      const recaptchaValue = recaptchaRef.current.getValue();

      let dataForm = {
        userEmail: values.email,
        userFullName: values.userFullname,
        userPassword: values.password,
        recaptchaValue,
        userLang: i18n.resolvedLanguage
      };

      formRegisterUserDipatch(dataForm);
    }
  });

  let whatToShow = '';

  if (loading) {
    whatToShow = <ShowLoader />;
  } else if (loaded && loadedReg) {
    whatToShow = <OnSuccessRegisterUser t={t} />;
  } else if (errorMessage && internalErrorCode) {
    whatToShow = <ShowError errorMessage={errorMessage} internalErrorCode={internalErrorCode} />;
  } else {
    whatToShow = <GenerateForm t={t} formik={formik} recaptchaRef={recaptchaRef} />;
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 5 }}>
      <CssBaseline />
      {whatToShow}
    </Container>
  );
}

function OnSuccessRegisterUser({ t }) {
  return (
    <Typography variant="h5" align="center" sx={{ mt: 3, mb: 2 }}>
      {t('excellent-check-your-email')}
    </Typography>
  );
}

function GenerateForm({ formik, recaptchaRef, t }) {
  return (
    <>
      <Typography variant="h4" align="center" sx={{ pt: 3, mb: 2 }}>
        {t('registerUser')}
      </Typography>

      <Box component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off" sx={{ mt: 1, width: '100%' }}>
        <TextField
          fullWidth
          margin="normal"
          id="userFullname"
          name="userFullname"
          label={t('first-and-last-name')}
          value={formik.values.userFullname}
          onChange={formik.handleChange}
          error={formik.touched.userFullname && Boolean(formik.errors.userFullname)}
          helperText={formik.touched.userFullname && formik.errors.userFullname}
        />

        <TextField
          fullWidth
          margin="normal"
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{ paddingTop: '10px' }}
        />

        <TextField
          fullWidth
          margin="normal"
          type="password"
          id="password"
          name="password"
          label={t('password')}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{ paddingTop: '10px' }}
        />

        <TextField
          fullWidth
          type="password"
          margin="normal"
          id="confirmPassword"
          name="confirmPassword"
          label={t('verify-password')}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={`${process.env.REACT_APP_RECAPTCHA_SECRET}`}
          style={{ paddingTop: '1rem' }}
        />

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: (theme) => theme.orangeButton.button }}>
          {t('registerUserBtn')}
        </Button>
      </Box>

      <Box noValidate sx={{ mt: 1, mb: 1, pb: 1, width: '100%', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1" align="justify" sx={{ mt: 3, mb: 2 }}>
          {t('afterSubmitText')}
          {/* https://automizy.com/blog/registration-confirmation-emails/ */}
        </Typography>
      </Box>
    </>
  );
}

export default RegisterUser;
