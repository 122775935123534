import React, { useEffect, useCallback, useState } from 'react';
import WrapperPage from '../../components/WrapperPage';
import WrapperContainer from '../../components/WrapperContainer/WrapperContainer';
import GeneratinQrCodeMainPage from '../../view/GeneratinQrCodeMainPage';
import PlantingTreeMainPage from '../../view/PlantingTreeMainPage';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ShowError from '../../view/ShowError';
import ShowLoader from '../../view/ShowLoader';
import YoutubeEmbed from '../../view/YoutubeEmbed';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateNoUserData } from '../../redux/user/actions';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  const [staniIbarVodo, setStaniIbarVodo] = useState(false);
  const dispatch = useDispatch();
  const { loaded, loading } = useSelector((state) => state.user);

  const stableDispatch = useCallback(dispatch, [dispatch]);

  const dispatchToClearRedux = useCallback(() => {
    stableDispatch(clearStateNoUserData());
    setStaniIbarVodo(true);
  }, [stableDispatch]);

  useEffect(() => {
    dispatchToClearRedux();
  }, []);

  let showData = '';
  switch (true) {
    case loading:
      showData = <ShowLoader />;
      break;
    case !loaded:
      showData = <ShowError />;
      break;
    case loaded:
      showData = <ShowData />;
      break;
    default:
      showData = 'Nesto';
      break;
  }

  return (
    <>
      <Container style={{ padding: 0, margin: 0 }} maxWidth={false}>
        <WrapperPage>
          <WrapperContainer>
            <Container>
              <h1>
                <div className="wordSize" style={{ fontSize: '6rem' }}>
                  Blokaria
                </div>
                <div className="wordSize" style={{ fontSize: '2.5rem' }}>
                  <div>Where we create unique value</div>
                </div>
              </h1>
            </Container>
            {staniIbarVodo ? showData : ''}
            <Container
              maxWidth={false}
              sx={{
                mt: 5,
                mb: 5,
                background: 'rgba(255,255,255, 0.8)',
                color: 'black !important',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <Grid container spacing={4} direction="row" sx={{ my: 3, width: '100% !important' }}>
                <Grid item xs={12} md={true} sx={{ height: '20rem' }}>
                  <Typography variant="h6" align="center" sx={{ mb: 2, mt: -2, fontWeight: '600' }}>
                    {t('how-to-create-qr-code')}
                  </Typography>
                  <YoutubeEmbed embedId="1cz9q9OQ6Kg" />
                  <div className="youtubeLegend"></div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" id="howToPlantTree" align="center" sx={{ mb: 2, mt: -2, fontWeight: '600' }}>
                    {t('howToPlantTree')}
                  </Typography>
                  <YoutubeEmbed embedId="bD7bk6tkciA" />
                  <div className="youtubeLegend"></div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" align="center" sx={{ mb: 2, mt: -2, fontWeight: '600' }}>
                    {t('howToCreateNft')}
                  </Typography>
                  <YoutubeEmbed embedId="aYuXCImLVPQ" />
                  <div className="youtubeLegend"></div>
                </Grid>
              </Grid>
            </Container>
          </WrapperContainer>
        </WrapperPage>
      </Container>
    </>
  );
}
const ShowData = () => {
  return (
    <>
      <Grid container className="bothMainGridsGenerateArtice">
        <Grid className="animate__animated animate__fadeInLeft firstGrid" item xs={12} md={5.5} sx={{ background: 'rgba(255,255,255, 0.8)', height: '43rem' }}>
          <GeneratinQrCodeMainPage />
        </Grid>
        <Grid
          className="animate__animated animate__fadeInRight secondGrid"
          item
          xs={12}
          md={5.5}
          sx={{ background: 'rgba(255,255,255, 0.8)', height: '43rem' }}
        >
          <PlantingTreeMainPage />
        </Grid>
      </Grid>
    </>
  );
};
export default Home;
