import {
  generateContract, generateContractApproval,
  initiateTransactionToClientWallet, generateQrCodeInSystem, generateQrCodeInSystemNoImage,
  getQrCodeDataNoRedeem, getQrCodeData, updateQrCodeText, updateQrCodeUrl,
  updateQrCodeUrlForward, updateNftStory, getQrCodeFromId, generateNftFromExistingQrCode
} from './basic';
import { registerUser, logInUser, getUserData, resetPassword, resetPasswordCode, sendApprovalToClient } from './user';
import {
  getAllQrCodesByUser, getListQrCodesGeneral, getListQrCodesOwners, deleteQrCode,
  createNewProject, listAllProject, addQrCodeToProject, deleteProject, updateProject,
  getOneProject, changeStatusOfQrCode, listAllProjectNrApi, getListQrCodesByUserPrivate,
  getListQrCodesByUser, updateListDataInDb
} from './tree';

const API = {
  exam: {
    getQrCodeData: getQrCodeData,
    generateContract: generateContract,
    generateContractApproval: generateContractApproval,
    initiateTransactionToClientWallet: initiateTransactionToClientWallet,
    generateQrCodeInSystem: generateQrCodeInSystem,
    generateQrCodeInSystemNoImage: generateQrCodeInSystemNoImage,
    getQrCodeDataNoRedeem: getQrCodeDataNoRedeem,
    updateQrCodeText: updateQrCodeText,
    updateQrCodeUrl: updateQrCodeUrl,
    updateQrCodeUrlForward: updateQrCodeUrlForward,
    updateNftStory: updateNftStory,
    getQrCodeFromId: getQrCodeFromId,
    generateNftFromExistingQrCode: generateNftFromExistingQrCode
  },
  user: {
    registerUser: registerUser,
    logInUser: logInUser,
    getUserData: getUserData,
    resetPassword: resetPassword,
    resetPasswordCode: resetPasswordCode,
    sendApprovalToClient: sendApprovalToClient
  },
  tree: {
    getAllQrCodesByUser: getAllQrCodesByUser,
    getListQrCodesGeneral: getListQrCodesGeneral,
    getListQrCodesOwners: getListQrCodesOwners,
    deleteQrCode: deleteQrCode,
    createNewProject: createNewProject,
    listAllProject: listAllProject,
    addQrCodeToProject: addQrCodeToProject,
    deleteProject: deleteProject,
    updateProject: updateProject,
    getOneProject: getOneProject,
    changeStatusOfQrCode: changeStatusOfQrCode,
    listAllProjectNrApi: listAllProjectNrApi,
    getListQrCodesByUserPrivate: getListQrCodesByUserPrivate,
    getListQrCodesByUser: getListQrCodesByUser,
    updateListDataInDb: updateListDataInDb
  }
};

export default API;
